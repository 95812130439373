//Priority
export const priorityList = [
  { priorityId: 1, priorityName: 'High' },
  { priorityId: 2, priorityName: 'Medium' },
  { priorityId: 3, priorityName: 'Low' },
];

//Customer Type aka Type
export const typeList = [
  { typeId: 1, typeName: 'Silver' },
  { typeId: 2, typeName: 'Gold' },
  { typeId: 3, typeName: 'Platinum' },
  { typeId: 4, typeName: 'New' },
  { typeId: 5, typeName: 'Against PI' },
];

//Status
export const statusList = [
  { statusId: 1, statusName: 'In Progress' },
  { statusId: 2, statusName: 'Rejected' },
  { statusId: 3, statusName: 'On Hold' },
  { statusId: 4, statusName: 'Completed' },
];

export const isRevisedList = [
  { revisedId: 0, revisedBoolean: 'No' },
  { revisedId: 1, revisedBoolean: 'Yes' },
];

export const urgentList = [
  { urgentId: 0, urgentName: 'No' },
  { urgentId: 1, urgentName: 'Yes' },
];

//PackingForwarding
export const packingForwordingList = [
  { packingForwardingId: 0, packingForwardingName: 'NIL' },
  { packingForwardingId: 1, packingForwardingName: 'Extra@Actuals' },
];

//TextCharge
export const testChargeList = [
  { testChargeId: 0, testChargeName: 'NIL' },
  { testChargeId: 1, testChargeName: 'Extra@Actuals' },
];

//Delivery
export const deliveryList = [
  { deliveryId: 0, deliveryName: 'Free' },
  { deliveryId: 1, deliveryName: 'Ex Works' },
];

//Page Entries
export const pageEntriesList = ['10', '25', '50', '100'];

export const shapeList = [
  { shapeId: 1, shapeName: 'Circle' },
  { shapeId: 1, shapeName: 'Ring' },
  { shapeId: 1, shapeName: 'Block' },
  { shapeId: 1, shapeName: 'Flat' },
  { shapeId: 1, shapeName: 'Hex' },
  { shapeId: 1, shapeName: 'Half' },
];

export const postStatus = [
  { statusId: 0, statusName: 'InActive' },
  { statusId: 1, statusName: 'Active' },
];

export const inquirySourceStatus = [
  { statusId: 0, statusName: 'InActive' },
  { statusId: 1, statusName: 'Active' },
];

export const qapSheetOption = [
  { qapSheetId: 0, qapSheetValue: 'No' },
  { qapSheetId: 1, qapSheetValue: 'Yes' },
];
