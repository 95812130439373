import {
  createInquiryAPI,
  deleteInquiryAPI,
  getAllInquiryAPI,
  getSingleInquiryAPI,
  transferInquiryAPI,
  updateInquiryAPI,
} from 'services/inquiry';
import { downloadQuotationAPI } from 'services/quotation';
import { CreateInquiryType, EditInquiryType, SingleInquiryType } from 'types/inquiries.types';
import { saveAs } from 'file-saver';
import { FormatDate } from 'helpers/date.helper';

export const getAllInquiryRequestAsync = async (params: URLSearchParams) => {
  const resp = await getAllInquiryAPI(params);
  return resp.data;
};

export const getSingleInquiryRequestAsync = async (id: number): Promise<SingleInquiryType> => {
  const resp = await getSingleInquiryAPI(id);
  return resp.data;
};

export const createInquiryRequestAsync = async (payload: CreateInquiryType) => {
  const resp = await createInquiryAPI(payload);
  return resp;
};

export const updateInquiryRequestAsync = async (payload: EditInquiryType) => {
  const resp = await updateInquiryAPI(payload);
  return resp;
};

export const deleteSingleInquiryRequestAsync = async (id: number) => {
  const resp = await deleteInquiryAPI(id);
  return resp;
};

export const transforInquiryToQuotationRequestAsync = async (id: number) => {
  const resp = await transferInquiryAPI(id);
  return resp;
};

export const downloadInquiryRequestAsync = async ({
  cId,
  inqId,
  isRevised,
  customerName,
}: {
  cId: number;
  inqId: number;
  isRevised: boolean;
  customerName?: string;
}) => {
  const resp = await downloadQuotationAPI(cId, inqId);
  const file = new Blob([resp.data], { type: 'application/pdf' });

  saveAs(
    file,
    `${isRevised === true ? 'Rev -' : ''} Inquiry(CS_INQ-${inqId})_${customerName}_${FormatDate(
      new Date(),
    )}.pdf`,
  );
};
