import axios from 'axios';
import requestConfig from 'config/request';
import { deleteAllCookies, getCookie } from './common';
import { ACCESS_TOKEN_NAME } from 'config/constant';
import { redirect, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'config/eunm';

const getRequestConfig = async (apiConfig) => {
  let config = Object.assign({}, requestConfig);
  if (apiConfig) {
    config = { ...config, ...apiConfig };
  }

  const token = getCookie(ACCESS_TOKEN_NAME);

  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
};

export const get = async (url, params, apiConfig) => {
  const config = await getRequestConfig(apiConfig);

  config.params = params;

  const request = axios.get(url, config);
  return request;
};

export const post = async (url, data, apiConfig) => {
  const config = await getRequestConfig(apiConfig);
  let postData = {};
  if (
    apiConfig &&
    apiConfig.headers &&
    apiConfig.headers['Content-Type'] &&
    apiConfig.headers['Content-Type'] !== 'application/json'
  ) {
    postData = data;
    axios.defaults.headers.post['Content-Type'] = apiConfig.headers['Content-Type'];
  } else {
    postData = JSON.stringify(data);
    axios.defaults.headers.post['Content-Type'] = 'application/json';
  }
  const request = axios.post(url, postData, config);
  return request;
};
export const put = async (url, data) => {
  const config = await getRequestConfig();
  config.headers['Content-Type'] = 'application/json';
  if (!data) {
    config.headers['Content-Length'] = 0;
  }
  const request = axios.put(url, JSON.stringify(data), config);
  return request;
};
export const patch = async (url, data) => {
  const config = await getRequestConfig();
  config.headers['Content-Type'] = 'application/json';
  if (!data) {
    config.headers['Content-Length'] = 0;
  }
  const request = axios.patch(url, JSON.stringify(data), config);
  return request;
};
export const deleteResource = async (url, data) => {
  const config = await getRequestConfig();
  config.headers['Content-Type'] = 'application/json';
  const request = axios.delete(url, { ...config, data: JSON.stringify(data) });
  return request;
};
