import { LaboratoryContactHookFormType, LaboratoryHookFormType } from 'types/laboratory.types';
import * as yup from 'yup';

const LaboratoryValidation: yup.ObjectSchema<LaboratoryHookFormType> = yup.object().shape(
  {
    laboratoryName: yup
      .string()
      .trim(' Remove spaces at the start and end.')
      .strict()
      .required('Required*')
      .min(2, 'Should have atleast 2 character'),
    email: yup
      .string()
      .label('Email')
      .required('Required*')
      .min(5, 'Should have atleast 5 character')
      .email()
      .lowercase(),
    mobile: yup
      .string()
      .required('Required*')
      .min(10, 'Should have atleast 10 character')
      // eslint-disable-next-line no-useless-escape
      .matches(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6-9]\d{9}$/gm, 'Enter a valid number'),
    officeAddress1: yup
      .string()
      .trim(' Remove spaces at the start and end.')
      .strict()
      .optional()
      .when('officeAddress1', {
        is: (value: any) => value?.length,
        then: (rule) => rule.min(2, 'Should have atleast 2 character'),
      }),
    officeAddress2: yup
      .string()
      .trim(' Remove spaces at the start and end.')
      .strict()
      .optional()
      .when('officeAddress2', {
        is: (value: any) => value?.length,
        then: (rule) => rule.min(2, 'Should have atleast 2 character'),
      }),
    officeAddress3: yup
      .string()
      .trim(' Remove spaces at the start and end.')
      .strict()
      .optional()
      .when('officeAddress3', {
        is: (value: any) => value?.length,
        then: (rule) => rule.min(2, 'Should have atleast 2 character'),
      }),
    officeCity: yup
      .object()
      .shape({
        cityId: yup.number().nullable().required('City Id Not available'),
        cityName: yup.string().nullable().required('City is required'),
      })
      .nullable()
      .default(null)
      .required('Required*'),
    officePin: yup
      .string()
      .optional()
      .when('officePin', {
        is: (value: any) => value?.length,
        then: (rule) =>
          rule
            .matches(/^[1-9]{1}\d{2}\s?\d{3}$/gm, 'Should be a valid Pincode')
            .min(6, 'Should have atleast 6 character'),
      }),
    contact: yup
      .array(
        yup.object<Omit<LaboratoryContactHookFormType, 'contactId'>>().shape({
          personName: yup
            .string()
            .matches(/^[A-Za-z ]*$/, 'Numbers and special characters are not allowed.')
            .trim(' Remove spaces at the start and end.')
            .strict()
            .required('Required*')
            .min(2, 'Should have atleast 2 character'),
          birthday: yup.string().optional(),
          email: yup
            .string()
            .label('Email')
            .required('Required*')
            .min(5, 'Should have atleast 5 character')
            .email()
            .lowercase(),
          mobile: yup
            .string()
            .required('Required*')
            .min(10, 'Should have atleast 10 character')
            .matches(
              /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6-9]\d{9}$/gm,
              'Enter a valid number',
            ),
        }),
      )
      .default([])
      .min(1, 'Select at least 1 option')
      .required('Required*'),
  },
  [
    ['officeAddress1', 'officeAddress1'],
    ['officeAddress2', 'officeAddress2'],
    ['officeAddress3', 'officeAddress3'],
    ['officePin', 'officePin'],
    ['officePhone', 'officePhone'],
  ],
);

export default LaboratoryValidation;
