enum APP_ROUTES {
  LOGIN = '/login',
  HOME_PAGE = '/',
  PAGE_NOT_FOUND = '/404',

  ROOT_OPERATIONS = '/operations',
  OPERATIONS_CUTTING_FORGING = 'cft',
  NEW_CUTTING_FORGING = 'new_cft',
  EDIT_CUTTING_FORGING = 'edit_cft',
  VIEW_CUTTING_FORGING = 'view_cft',

  INQUIRY_LEADS = '/inquiries',
  NEW_INQUIRIES = 'new-inquiry',
  VIEW_INQUIRIES = 'view',
  EDIT_INQUIRIES = 'edit',

  QUOTATIONS = '/quotations',
  NEW_QUOTATION = 'new-quotation',
  EDIT_QUOTATION = 'edit',
  VIEW_QUOTATION = 'view',

  ROOT_PURCHASE_ORDER = '/po',
  SUPPLIER_PURCHASE_ORDERS = 'supplier_purchase_order',
  EDIT_SPO = 'edit',
  VIEW_SPO = 'view',
  NEW_SPO = 'new',
  CUSTOMER_PURCHASE_ORDERS = 'customer_purchase_order',
  EDIT_CPO = 'edit',
  VIEW_CPO = 'view',
  NEW_CPO = 'new',

  LAB_REPORT = '/lab_report',
  EDIT_LAB_REPORT = 'edit',
  VIEW_LAB_REPORT = 'view',
  NEW_LAB_REPORT = 'new',

  ROOT_MANAGER_VIEW = '/manager-view',
  MANAGER_VIEW_CFT = 'cft',

  ROOT_CLIENT_MASTER = '/client',
  CM_CUSTOMER_LIST = 'customers',
  CM_NEW_CUSTOMER = 'newCustomer',
  CM_VIEW_CUSTOMER = 'viewCustomer',
  CM_EDIT_CUSTOMER = 'editCustomer',

  CM_BROKER_LIST = 'brokers',
  CM_NEW_BROKER = 'newBroker',
  CM_VIEW_BROKER = 'viewBroker',
  CM_EDIT_BROKER = 'editBroker',

  CM_VENDOR_LIST = 'vendors',
  CM_NEW_VENDOR = 'newVendor',
  CM_VIEW_VENDOR = 'viewVendor',
  CM_EDIT_VENDOR = 'editVendor',

  CM_PURCHASER_LIST = 'purchaser',
  CM_NEW_PURCHASER = 'newPurchaser',
  CM_VIEW_PURCHASER = 'viewPurchaser',
  CM_EDIT_PURCHASER = 'editPurchaser',

  ROOT_MASTER = '/master',
  MASTER_GRADE_LIST = 'grades',
  MASTER_NEW_GRADE = 'newGrade',
  MASTER_VIEW_GRADE = 'viewGrade',
  MASTER_EDIT_GRADE = 'editGrade',

  MASTER_CATEGORY_LIST = 'category',
  MASTER_NEW_CATEGORY = 'newCategory',
  MASTER_VIEW_CATEGORY = 'viewCategory',
  MASTER_EDIT_CATEGORY = 'editCategory',

  MASTER_LOCATION_LIST = 'location',
  MASTER_NEW_LOCATION = 'newLocation',
  MASTER_VIEW_LOCATION = 'viewLocation',
  MASTER_EDIT_LOCATION = 'editLocation',

  MASTER_CITY_LIST = 'city',
  MASTER_NEW_CITY = 'newCity',
  MASTER_VIEW_CITY = 'viewCity',
  MASTER_EDIT_CITY = 'editCity',

  MASTER_STATE_LIST = 'state',
  MASTER_NEW_STATE = 'newState',
  MASTER_VIEW_STATE = 'viewState',
  MASTER_EDIT_STATE = 'editState',

  MASTER_WAREHOUSE_LIST = 'warehouse',
  MASTER_NEW_WAREHOUSE = 'newWarehouse',
  MASTER_VIEW_WAREHOUSE = 'viewWarehouse',
  MASTER_EDIT_WAREHOUSE = 'editWarehouse',

  MASTER_LABORATORY_LIST = 'laboratory',
  MASTER_NEW_LABORATORY = 'newLaboratory',
  MASTER_VIEW_LABORATORY = 'viewLaboratory',
  MASTER_EDIT_LABORATORY = 'editLaboratory',

  MASTER_USER_LIST = 'user',
  MASTER_NEW_USER = 'newUser',
  MASTER_VIEW_USER = 'viewUser',
  MASTER_EDIT_USER = 'editUser',

  ROOT_CONFIG = '/config',
  CONFIG_COUNTRY_LIST = 'country',
  CONFIG_NEW_COUNTRY = 'newCountry',
  CONFIG_VIEW_COUNTRY = 'viewCountry',
  CONFIG_EDIT_COUNTRY = 'editCountry',

  CONFIG_DESIGNATION_LIST = 'desgination',
  CONFIG_NEW_DESIGNATION = 'newDesgination',
  CONFIG_VIEW_DESIGNATION = 'viewDesgination',
  CONFIG_EDIT_DESIGNATION = 'editDesgination',

  CONFIG_STANDARD_LIST = 'standard',
  CONFIG_NEW_STANDARD = 'newStandard',
  CONFIG_VIEW_STANDARD = 'viewStandard',
  CONFIG_EDIT_STANDARD = 'editStandard',

  CONFIG_CONDITION_LIST = 'condition',
  CONFIG_NEW_CONDITION = 'newCondition',
  CONFIG_VIEW_CONDITION = 'viewCondition',
  CONFIG_EDIT_CONDITION = 'editCondition',

  CONFIG_COMPANY_LIST = 'company',
  CONFIG_NEW_COMPANY = 'newCompany',
  CONFIG_VIEW_COMPANY = 'viewCompany',
  CONFIG_EDIT_COMPANY = 'editCompany',

  CONFIG_INQUIRY_SOURCE_LIST = 'source',
  CONFIG_NEW_INQUIRY_SOURCE = 'newSource',
  CONFIG_VIEW_INQUIRY_SOURCE = 'viewSource',
  CONFIG_EDIT_INQUIRY_SOURCE = 'editSource',

  CONFIG_MAKE_LIST = 'make',
  CONFIG_NEW_MAKE = 'newMake',
  CONFIG_VIEW_MAKE = 'viewMake',
  CONFIG_EDIT_MAKE = 'editMake',

  CONFIG_PROCESS_LIST = 'process',
  CONFIG_NEW_PROCESS = 'newProcess',
  CONFIG_VIEW_PROCESS = 'viewProcess',
  CONFIG_EDIT_PROCESS = 'editProcess',

  CONFIG_TESTING_LIST = 'testing',
  CONFIG_NEW_TESTING = 'newTesting',
  CONFIG_VIEW_TESTING = 'viewTesting',
  CONFIG_EDIT_TESTING = 'editTesting',

  ROOT_INVENTORY = '/inventory',
  INVENTORY_CURRENT_STOCK_LIST = 'CurrentStock',
  VIEW_CURRENT_STOCK = 'view',
  EDIT_CURRENT_STOCK = 'edit',
  INVENTORY_IN_STOCK_LIST = 'InStock',
  NEW_IN_STOCK = 'new',
  VIEW_IN_STOCK = 'view',
  EDIT_IN_STOCK = 'edit',
  INVENTORY_OUT_STOCK_LIST = 'OutStock',
  NEW_OUT_STOCK = 'new',
  VIEW_OUT_STOCK = 'view',
  EDIT_OUT_STOCK = 'edit',
}

export { APP_ROUTES };
