import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  cardAnatomy.keys,
);

const headerBgVariant = definePartsStyle({
  container: {
    boxShadow: 'none',
    padding: '4',
  },
});
const contentVariant = definePartsStyle({
  container: {
    boxShadow: 'none',
    padding: '2',
    height: '100%',
  },
});

const dashboardCardVariant = definePartsStyle({
  container: {
    boxShadow: 'none',
    height: '100%',
  },
});

export const cardTheme = defineMultiStyleConfig({
  variants: {
    headerBg: headerBgVariant,
    dashCard: dashboardCardVariant,
    content: contentVariant,
  },
});
