import { APP_ROUTES } from 'config/eunm';
// Old Icon Import
/* import DashboardIcon from 'assets/sidebarSVG/Dashboard.icon';
import ReportIcon from 'assets/sidebarSVG/Report.icon';
import TransactionIcon from 'assets/sidebarSVG/Transaction.icon';
import EnquiryIcon from 'assets/sidebarSVG/Enquiry.icon';*/

import {
  IconLayoutDashboard as DashboardIcon,
  IconReportAnalytics as OperationsIcon,
  IconAdjustmentsCog as CFTIcon,
  IconInfoSquareRounded as InquiriesIcon,
  IconNotes as QuotationsIcon,
  IconTruckDelivery as PurchaseOrderIcon,
  IconHexagonLetterC as CustomerPOIcon,
  IconHexagonLetterS as SupplierPOIcon,
  IconChecklist as InventoryIcon,
  IconUserStar as ManagerReportIcon,
  IconListDetails as MasterIcon,
  IconUserBolt as CustomerListIcon,
  IconUsers as BrokerListIcon,
  IconUsersGroup as VendorListIcon,
  IconStars as GradeListIcon,
  IconCategory as CategoryListIcon,
  IconLocation as LocationIcon,
  IconBuildingSkyscraper as CityListIcon,
  IconMap2 as StateListIcon,
  IconUserCircle as UserList,
  IconListLetters as OthersIcon,
  IconFlag as CountryMasterIcon,
  IconUserSearch as PostIcon,
  IconHammer as MakeIcon,
  IconArrowsSplit2 as ProcessIcon,
  IconBrandDatabricks as ConditionIcon,
  IconBuildingFactory2 as CompanyIcon,
  IconFileImport as InquirySourceIcon,
  IconBuildingWarehouse as WarehouseListIcon,
  IconCertificate as StandardIcon,
  IconPackageExport as OutStockIcon,
  IconPackageImport as InStockIcon,
  IconPackage as CurrentStockIcon,
  IconUserDown as ClientIcon,
  IconUserDollar as PurchaserListIcon,
  IconFlask as LabReportIcon,
  IconMicroscope as LaboratoryIcon,
  IconTestPipe as TestingIcon,
  TablerIconsProps,
} from '@tabler/icons-react';
import {} from '@tabler/icons-react';

export const sideBarConfig = {
  openWidth: '250px',
  closeWidth: '80px',
};

export interface SidebarListType {
  path: string;
  name: string;
  icon: (props: TablerIconsProps) => JSX.Element;
  children?: SidebarListType[];
}

export const SIDEBAR_MENU_ITEMS_LIST: SidebarListType[] = [
  //Dashboard
  {
    path: `${APP_ROUTES.HOME_PAGE}`,
    name: 'Dashboard',
    icon: DashboardIcon,
  },
  //Business Operations
  {
    path: `${APP_ROUTES.ROOT_OPERATIONS}`,
    name: 'Business Operations',
    icon: OperationsIcon,
    children: [
      {
        path: `${APP_ROUTES.OPERATIONS_CUTTING_FORGING}`,
        name: 'Cutting Forging & Turning',
        icon: CFTIcon,
      },
    ],
  },
  // Inquiry
  {
    path: `${APP_ROUTES.INQUIRY_LEADS}`,
    name: 'Inquiry Leads',
    icon: InquiriesIcon,
  },
  // Quotation
  {
    path: `${APP_ROUTES.QUOTATIONS}`,
    name: 'Quotations',
    icon: QuotationsIcon,
  },

  {
    path: `${APP_ROUTES.ROOT_PURCHASE_ORDER}`,
    name: 'Purchase Order',
    icon: PurchaseOrderIcon,
    children: [
      {
        //Supplier Purchase Order
        path: `${APP_ROUTES.SUPPLIER_PURCHASE_ORDERS}`,
        name: 'Supplier PO',
        icon: SupplierPOIcon,
      },
      //Customer Purchase Order
      {
        path: `${APP_ROUTES.CUSTOMER_PURCHASE_ORDERS}`,
        name: 'Customer PO',
        icon: CustomerPOIcon,
      },
    ],
  },

  {
    path: `${APP_ROUTES.LAB_REPORT}`,
    name: 'Lab Report',
    icon: LabReportIcon,
  },
  //Inventory
  {
    path: `${APP_ROUTES.ROOT_INVENTORY}`,
    name: 'Inventory',
    icon: InventoryIcon,
    children: [
      {
        path: `${APP_ROUTES.INVENTORY_CURRENT_STOCK_LIST}`,
        name: 'Current Stock',
        icon: CurrentStockIcon,
      },
      {
        path: `${APP_ROUTES.INVENTORY_IN_STOCK_LIST}`,
        name: 'In Stock',
        icon: InStockIcon,
      },
      {
        path: `${APP_ROUTES.INVENTORY_OUT_STOCK_LIST}`,
        name: 'Out Stock',
        icon: OutStockIcon,
      },
    ],
  },
  //Manager Report
  {
    path: `${APP_ROUTES.ROOT_MANAGER_VIEW}`,
    name: `Manager's View`,
    icon: ManagerReportIcon,
    children: [
      {
        path: `${APP_ROUTES.MANAGER_VIEW_CFT}`,
        name: 'CFT Report',
        icon: CFTIcon,
      },
    ],
  },
  {
    path: `${APP_ROUTES.ROOT_CLIENT_MASTER}`,
    name: 'Client Master',
    icon: ClientIcon,
    children: [
      {
        path: `${APP_ROUTES.CM_CUSTOMER_LIST}`,
        name: 'Customer List',
        icon: CustomerListIcon,
      },
      {
        path: `${APP_ROUTES.CM_BROKER_LIST}`,
        name: 'Broker List',
        icon: BrokerListIcon,
      },
      {
        path: `${APP_ROUTES.CM_VENDOR_LIST}`,
        name: 'Vendor List',
        icon: VendorListIcon,
      },
      {
        path: `${APP_ROUTES.CM_PURCHASER_LIST}`,
        name: 'Purchaser List',
        icon: PurchaserListIcon,
      },
    ],
  },

  //Masters
  {
    path: `${APP_ROUTES.ROOT_MASTER}`,
    name: 'Master',
    icon: MasterIcon,
    children: [
      {
        path: `${APP_ROUTES.MASTER_WAREHOUSE_LIST}`,
        name: 'Warehouse List',
        icon: WarehouseListIcon,
      },
      {
        path: `${APP_ROUTES.MASTER_LABORATORY_LIST}`,
        name: 'Laboratory List',
        icon: LaboratoryIcon,
      },
      {
        path: `${APP_ROUTES.MASTER_GRADE_LIST}`,
        name: 'Grade List',
        icon: GradeListIcon,
      },
      {
        path: `${APP_ROUTES.MASTER_CATEGORY_LIST}`,
        name: 'Category List',
        icon: CategoryListIcon,
      },
      {
        path: `${APP_ROUTES.MASTER_LOCATION_LIST}`,
        name: 'Location List',
        icon: LocationIcon,
      },
      {
        path: `${APP_ROUTES.MASTER_CITY_LIST}`,
        name: 'City List',
        icon: CityListIcon,
      },
      {
        path: `${APP_ROUTES.MASTER_STATE_LIST}`,
        name: 'State List',
        icon: StateListIcon,
      },
      {
        path: `${APP_ROUTES.MASTER_USER_LIST}`,
        name: 'User List',
        icon: UserList,
      },
    ],
  },
  //other configs
  {
    path: `${APP_ROUTES.ROOT_CONFIG}`,
    name: 'Others',
    icon: OthersIcon,
    children: [
      {
        path: `${APP_ROUTES.CONFIG_COUNTRY_LIST}`,
        name: 'Country Master',
        icon: CountryMasterIcon,
      },
      {
        path: `${APP_ROUTES.CONFIG_DESIGNATION_LIST}`,
        name: 'Post / Designation',
        icon: PostIcon,
      },
      {
        path: `${APP_ROUTES.CONFIG_COMPANY_LIST}`,
        name: 'Company',
        icon: CompanyIcon,
      },
      {
        path: `${APP_ROUTES.CONFIG_CONDITION_LIST}`,
        name: 'Condition',
        icon: ConditionIcon,
      },
      {
        path: `${APP_ROUTES.CONFIG_STANDARD_LIST}`,
        name: 'Standard',
        icon: StandardIcon,
      },
      {
        path: `${APP_ROUTES.CONFIG_INQUIRY_SOURCE_LIST}`,
        name: 'Inquiry Source',
        icon: InquirySourceIcon,
      },
      {
        path: `${APP_ROUTES.CONFIG_MAKE_LIST}`,
        name: 'Make',
        icon: MakeIcon,
      },
      {
        path: `${APP_ROUTES.CONFIG_PROCESS_LIST}`,
        name: 'Process',
        icon: ProcessIcon,
      },
      {
        path: `${APP_ROUTES.CONFIG_TESTING_LIST}`,
        name: 'Testing',
        icon: TestingIcon,
      },
    ],
  },
];
