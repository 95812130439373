import config from 'config/appConfig';
import { CreateSPOType, EditSPOType } from 'types/SPO.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllSellerPurchaseOrderAPI = (params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/purchaseorder/spopaginated?${params.toString()}`;
  return API.get(url);
};

export const getSingleSellerPurchaseOrderAPI = (id: number) => {
  const url = `${API_ENDPOINT}/purchaseorder/spo/${id}`;
  return API.get(url);
};

export const createSingleSellerPurchaseOrderAPI = (body: CreateSPOType) => {
  const url = `${API_ENDPOINT}/purchaseorder/spo`;
  return API.post(url, body);
};

export const updateSellerPurchaseOrderAPI = (body: EditSPOType, id: number) => {
  const url = `${API_ENDPOINT}/purchaseorder/spo/${id}`;
  return API.put(url, body);
};

export const deleteSellerPurchaseOrderAPI = (id: number) => {
  const url = `${API_ENDPOINT}/purchaseorder/spo/${id}`;
  return API.deleteResource(url);
};

export const updateSellerPurchaseOrderStatusAPI = (id: number, params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/purchaseorder/spo/${id}/updatestatus?${params.toString()}`;
  return API.put(url);
};

export const setSellerPurchaseOrderRevisedAPI = (id: number) => {
  const url = `${API_ENDPOINT}/purchaseorder/spo/setrevised/${id}`;
  return API.put(url);
};

export const downloadSellerPurchaseOrderAPI = (cId: number, spoId: number) => {
  const url = `${API_ENDPOINT}/purchaseorder/spo/pdf?companyId=${cId}&spoId=${spoId}`;
  const apiConfig = {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    transformResponse: [
      function (data: any) {
        // Do whatever you want to transform the data

        return data;
      },
    ],
  };
  return API.get(url, undefined, apiConfig);
};
