import {
  createConditionAPI,
  deleteConditionAPI,
  getAllConditionAPI,
  getSingleConditionAPI,
  updateConditionAPI,
} from 'services/condition';
import {
  AllConditionType,
  ConditionTableType,
  CreateConditionType,
  EditConditionType,
  GetSingleConditionType,
} from 'types/condition.types';

export const getAllConditionRequestAsync = async (): Promise<ConditionTableType[]> => {
  const resp = await getAllConditionAPI();
  const filteredResp: ConditionTableType[] = resp.data.data.map(
    (condition: AllConditionType): ConditionTableType => ({
      conditionId: condition.conditionId,
      conditionName: condition.conditionName,
    }),
  );
  return filteredResp;
};

export const createConditionRequestAsync = async (payload: CreateConditionType) => {
  const resp = await createConditionAPI(payload);
  return resp;
};

export const getSingleConditionRequestAsync = async (
  id: number,
): Promise<GetSingleConditionType> => {
  const resp = await getSingleConditionAPI(id);
  return resp.data.data;
};

export const updateConditionRequestAsync = async (id: number, payload: EditConditionType) => {
  const resp = await updateConditionAPI(id, payload);
  return resp;
};

export const deleteSingleConditionRequestAsync = async (id: number) => {
  const resp = await deleteConditionAPI(id);
  return resp;
};
