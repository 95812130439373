import { CustomerType_Type, PriorityType } from 'types/common.types';

export const getPriorityColorCode = (type: PriorityType) => {
  // @ts-ignore
  return `severityColor.${type.replaceAll(' ', '').toLowerCase()}` || 'mainColors.primary';
};

export const getCustomerTypeColorCode = (type: CustomerType_Type) => {
  // @ts-ignore
  return `customertypeColor.${type.replaceAll(' ', '').toLowerCase()}`;
};

export const getStatusTypeColorCode = (type: 'Active' | 'InActive') => {
  // @ts-ignore
  return `statusColor.${type.replaceAll(' ', '').toLowerCase()}`;
};
