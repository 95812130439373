import config from 'config/appConfig';
import { CreateCityType, EditCityType } from 'types/city.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllCityAPI = () => {
  const url = `${API_ENDPOINT}/city`;
  return API.get(url);
};

export const createCityAPI = (body: CreateCityType) => {
  const url = `${API_ENDPOINT}/city`;
  return API.post(url, body);
};

export const getSingleCityAPI = (id: number) => {
  const url = `${API_ENDPOINT}/city/${id}`;
  return API.get(url);
};

export const updateCityAPI = (id: number, payload: EditCityType) => {
  const url = `${API_ENDPOINT}/city/${id}`;
  return API.put(url, payload);
};

export const deleteCityAPI = (id: number) => {
  const url = `${API_ENDPOINT}/city/${id}`;
  return API.deleteResource(url);
};
