import {
  createPurchaserAPI,
  deletePurchaserAPI,
  getAllPurchaserAPI,
  getSinglePurchaserAPI,
  updatePurchaserAPI,
} from 'services/purchaser';
import { PaginatedResponseType } from 'types/common.types';
import {
  CreatePurchaserType,
  EditPurchaserType,
  GetSinglePurchaserType,
} from 'types/purchaser.types';

export const getAllPurchaserRequestAsync = async (
  params: URLSearchParams,
): Promise<PaginatedResponseType> => {
  const resp = await getAllPurchaserAPI(params);

  return resp.data;
};

export const getSinglePurchaserRequestAsync = async (
  id: number,
): Promise<GetSinglePurchaserType> => {
  const resp = await getSinglePurchaserAPI(id);
  return resp.data;
};

export const createPurchaserRequestAsync = async (payload: CreatePurchaserType) => {
  const resp = await createPurchaserAPI(payload);
  return resp;
};

export const updatePurchaserRequestAsync = async (id: number, payload: EditPurchaserType) => {
  const resp = await updatePurchaserAPI(id, payload);
  return resp;
};

export const deleteSinglePurchaserRequestAsync = async (id: number) => {
  const resp = await deletePurchaserAPI(id);
  return resp;
};
