import { FormatDate } from 'helpers/date.helper';
import {
  createSingleQuotationAPI,
  deleteQuotationAPI,
  downloadQuotationAPI,
  getAllQuotationAPI,
  getSingleQuotationAPI,
  setQuotationRevisedAPI,
  transferToInquiryAPI,
  updateQuotationAPI,
  updateQuotationStatusAPI,
} from 'services/quotation';
import { PaginatedResponseType } from 'types/common.types';
import { CreateQuotationType, EditQuotationType, SingleQuotationType } from 'types/quotation.types';
import { saveAs } from 'file-saver';

export const getAllQuotaionRequestAsync = async (
  params: URLSearchParams,
): Promise<PaginatedResponseType> => {
  const resp = await getAllQuotationAPI(params);
  return resp.data;
};

export const getSingleQuotationRequestAsync = async (id: number): Promise<SingleQuotationType> => {
  const resp = await getSingleQuotationAPI(id);
  return resp.data.data;
};

export const createQuotationRequestAsync = async (payload: CreateQuotationType) => {
  const resp = await createSingleQuotationAPI(payload);
  return resp;
};

export const updateQuotationRequestAsync = async (payload: EditQuotationType) => {
  const resp = await updateQuotationAPI(payload);
  return resp;
};

export const deleteSingleQuotationRequestAsync = async (id: number) => {
  const resp = await deleteQuotationAPI(id);
  return resp;
};

export const transferQuotationToInquiryRequestAsync = async (id: number) => {
  const resp = await transferToInquiryAPI(id);
  return resp;
};

/* export const transferQuotationToPurchaseOrderRequestAsync = async (id: number) => {
  const resp = await transferToPurchaseOrderAPI(id);
  return resp;
}; */

export const updateQuotationStatusRequestAsync = async (id: number, params: URLSearchParams) => {
  const resp = await updateQuotationStatusAPI(id, params);
  return resp;
};

export const setQuotationRevisedRequestAsync = async (id: number) => {
  const resp = await setQuotationRevisedAPI(id);
  return resp;
};

export const downloadQuotationRequestAsync = async ({
  cId,
  inqId,
  isRevised,
  customerName,
}: {
  cId: number;
  inqId: number;
  isRevised: boolean;
  customerName?: string;
}) => {
  const resp = await downloadQuotationAPI(cId, inqId);
  const file = new Blob([resp.data], { type: 'application/pdf' });
  saveAs(
    file,
    `${isRevised === true ? 'Rev -' : ''} Quotation(CS_INQ-${inqId})_${customerName}_${FormatDate(
      new Date(),
    )}.pdf`,
  );
};
