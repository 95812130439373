import config from 'config/appConfig';
import { CreateCompanyType, EditCompanyType } from 'types/company.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllCompanyAPI = () => {
  const url = `${API_ENDPOINT}/company`;
  return API.get(url);
};

export const deleteCompanyAPI = (id: number) => {
  const url = `${API_ENDPOINT}/company/${id}`;
  return API.deleteResource(url);
};

export const createCompanyAPI = (body: CreateCompanyType) => {
  const url = `${API_ENDPOINT}/company`;
  return API.post(url, body);
};

export const getSingleCompanyAPI = (id: number) => {
  const url = `${API_ENDPOINT}/company/${id}`;
  return API.get(url);
};

export const updateCompanyAPI = (id: number, payload: EditCompanyType) => {
  const url = `${API_ENDPOINT}/company/${id}`;
  return API.put(url, payload);
};
