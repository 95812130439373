import { extendTheme } from '@chakra-ui/react';
import colors from './foundations/custom.colors';
import { buttonTheme } from './components/custom.button';
import { cardTheme } from './components/custom.card';
import { tableTheme } from './components/custom.table';
import { wrapperTheme } from './components/Wrappers/custom.wrapperStyles';
import { accordionTheme } from './components/custom.accordion';
import { inputTheme } from './components/custom.input';

const overrides = {
  colors,
  // Other foundational style overrides go here
  components: {
    Button: buttonTheme,
    Card: cardTheme,
    Table: tableTheme,
    Accordion: accordionTheme,
    Input: inputTheme,
    Wrapper: wrapperTheme,
    // Other components go here
  },
};

export default extendTheme(overrides, {
  // Add any custom theme configurations here
  // ...theme, // pre-defined theming of chakra UI,
  /* ...customTheme, */
});
