import config from 'config/appConfig';
import { URLSearchParams } from 'url';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllCuttingForgingReportAPI = (params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/cuttingforging/paginated?${params.toString()}`;
  return API.get(url);
};

export const deleteSingleCFTReportAPI = (id: number) => {
  const url = `${API_ENDPOINT}/cuttingforging/${id}`;
  return API.deleteResource(url);
};
