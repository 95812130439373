import config from 'config/appConfig';
import { CreateTestingType, EditTestingType } from 'types/testing.types';

import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllLabTestAPI = () => {
  const url = `${API_ENDPOINT}/testingtype`;
  return API.get(url);
};

export const createLabTestAPI = (body: CreateTestingType) => {
  const url = `${API_ENDPOINT}/testingtype`;
  return API.post(url, body);
};

export const getSingleLabTestAPI = (id: number) => {
  const url = `${API_ENDPOINT}/testingtype/${id}`;
  return API.get(url);
};

export const updateLabTestAPI = (id: number, payload: EditTestingType) => {
  const url = `${API_ENDPOINT}/testingtype/${id}`;
  return API.put(url, payload);
};

export const deleteLabTestAPI = (id: number) => {
  const url = `${API_ENDPOINT}/testingtype/${id}`;
  return API.deleteResource(url);
};
