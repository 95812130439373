import useCustomToast from 'hooks/toast';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { Box, Button, Card, Spacer } from '@chakra-ui/react';
import { PageHeading } from 'components/common/Headings';
import BreadCrumbLayout from 'components/common/BreadCrumb';
import { APP_ROUTES } from 'config/eunm';
import MasterTable from 'components/Table/MasterTable';
import { TestingTableType } from 'types/testing.types';
import TestingTableButton from './TestingTableButton';
import { getAllLabTestsRequestAsync } from './controller';
import { UserDetails } from 'App';
import { MenuAccessItem, createAccessCheck, viewAccessCheck } from 'helpers/accessCheck.helper';
import { AxiosError } from 'axios';
import AccessDeniedPage from 'components/AccessDeniedPage';

const MasterTestingListPage = () => {
  const navigate = useNavigate();
  const showToast = useCustomToast();

  const [testingList, setTestingList] = useState<TestingTableType[]>([]);
  const [testingLoader, setTestingLoader] = useState<boolean>(true);
  const [viewAccessDenied, setViewAccessDenied] = useState<boolean>(false);
  const { user, setUser } = useContext(UserDetails);

  useEffect(() => {
    const access = viewAccessCheck('Master', user?.menuAccess as MenuAccessItem[]);
    if (!access) {
      setViewAccessDenied(true);
    }
    if (access) {
      setViewAccessDenied(false);
    }
  }, [user]);

  const fetchAllTesting = async () => {
    try {
      const allTesting: TestingTableType[] = await getAllLabTestsRequestAsync();
      setTestingList(allTesting);
      setTestingLoader(false);
    } catch (error: any) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setViewAccessDenied(true);
      } else
        showToast({
          title: err.message,
          description: 'Something went wrong!!',
          status: 'error',
        });
    }
  };

  useEffect(() => {
    fetchAllTesting();
  }, []);

  const defaultColumns = React.useMemo<ColumnDef<TestingTableType>[]>(
    () => [
      {
        id: 'actionButton',
        cell: (props) => <TestingTableButton testingId={props.row.original.testingId} />,
      },
      {
        header: 'Testing Name',
        accessorKey: 'testingName',
        cell: (info) => info.getValue() || '-',
        sortingFn: 'alphanumeric',
      },
      {
        header: 'UID',
        accessorKey: 'testingUID',
        cell: (info) => info.getValue() || '-',
        sortingFn: 'alphanumeric',
      },
    ],
    [],
  );
  const disabledColumns = {};
  return (
    <Box width={'100%'} display={'flex'} gap={2} flexDirection={'column'}>
      <Box width={'100%'}>
        <Card
          variant={'headerBg'}
          display={'flex'}
          flexDirection={{ md: 'row', base: 'column' }}
          gap={5}
          alignItems={{ md: 'center', base: 'start' }}
        >
          <Box>
            <PageHeading props={{ pb: 2 }}>Testing Master</PageHeading>
            <BreadCrumbLayout
              options={[
                { name: 'Dashboard', link: `${APP_ROUTES.HOME_PAGE}` },
                { name: 'Others' },
                { name: 'Testing Master', isActivePage: true },
              ]}
            />
          </Box>
          <Spacer />
          <Box display="flex" gap={4}>
            <Button
              onClick={() => navigate(APP_ROUTES.CONFIG_NEW_TESTING)}
              variant="primary"
              colorScheme="create"
              isDisabled={!createAccessCheck('Master', user?.menuAccess as MenuAccessItem[])}
            >
              Create New Testing
            </Button>
          </Box>
        </Card>
      </Box>
      {viewAccessDenied ? (
        <AccessDeniedPage />
      ) : (
        <Box width={'100%'}>
          <MasterTable
            actualData={testingList}
            columnDefs={defaultColumns}
            tableLoader={testingLoader}
            disabledColumn={disabledColumns}
            intialSort={{ id: 'testingName', desc: false }}
          />
        </Box>
      )}
    </Box>
  );
};

export default MasterTestingListPage;
