import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { Wrapper } from 'theme/components/Wrappers/custom.Wrapper';
import { SIDEBAR_MENU_ITEMS_LIST } from '../constant';
import { useLocation, useNavigate } from 'react-router-dom';

const SideMenuList = ({ isOpen }: { isOpen: boolean }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleMenuItemClick = (path: string) => {
    path && navigate(path);
  };
  return (
    <Box>
      <Wrapper variant="sideMenuItem">
        <Accordion allowToggle variant={'sideBar'}>
          {SIDEBAR_MENU_ITEMS_LIST.map((item, index) => {
            const hasChild = (item.children || [])?.length;
            const { icon: ItemIcon } = item;
            if (hasChild) {
              const pathSegments = location.pathname.split('/').filter(Boolean);
              const parentRoute =
                pathSegments.length >= 3
                  ? `/${pathSegments[0]}/${pathSegments[1]}`
                  : `/${pathSegments[0]}`;

              const isParentActive = item.path === parentRoute;
              return (
                <React.Fragment key={`${item.name.trim()}-frag`}>
                  <AccordionItem key={`${item.name.trim()}`}>
                    <Tooltip
                      label={item.name}
                      display={isOpen ? 'none' : 'block'}
                      placement="right"
                      hasArrow
                    >
                      <AccordionButton>
                        <Wrapper
                          variant={isParentActive ? 'sidebarTextActive' : 'sidebarText'}
                          gap={4}
                          key={`${item.name.trim()}`}
                        >
                          {isOpen ? (
                            <Flex gap={2} textAlign={'start'} alignItems={'center'}>
                              <ItemIcon stroke={2} size={28} /> {item.name}
                            </Flex>
                          ) : (
                            <ItemIcon stroke={2} size={28} />
                          )}
                        </Wrapper>
                        <AccordionIcon mr={2} />
                      </AccordionButton>
                    </Tooltip>
                    <AccordionPanel>
                      {item.children?.map((subRoute, idx) => {
                        const isActiveItem = `${item.path}/${subRoute.path}` === location.pathname;
                        const { icon: SubItemIcon } = subRoute;
                        return (
                          <Wrapper
                            variant={isActiveItem ? 'sidebarTextActive' : 'sidebarText'}
                            marginBottom={1}
                            key={`${subRoute.name.trim()}-${idx}`}
                            onClick={() => handleMenuItemClick(`${item.path}/${subRoute.path}`)}
                          >
                            {isOpen ? (
                              <Flex gap={2} textAlign={'start'} alignItems={'center'}>
                                <SubItemIcon stroke={2} size={26} />
                                <Text>{subRoute.name}</Text>
                              </Flex>
                            ) : (
                              <Tooltip label={subRoute.name} placement="right" hasArrow gutter={15}>
                                <SubItemIcon stroke={2} size={26} />
                              </Tooltip>
                            )}
                          </Wrapper>
                        );
                      })}
                    </AccordionPanel>
                  </AccordionItem>
                </React.Fragment>
              );
            } else {
              const isActiveItem = item.path === location.pathname;
              return (
                <Wrapper
                  variant={isActiveItem ? 'sidebarTextActive' : 'sidebarText'}
                  gap={4}
                  key={`${item.name.trim()}`}
                  onClick={() => handleMenuItemClick(item.path)}
                >
                  <Tooltip
                    label={item.name}
                    placement="right"
                    hasArrow
                    gutter={18}
                    display={isOpen ? 'none' : 'block'}
                  >
                    <Flex width={'100%'} gap={2}>
                      {isOpen ? (
                        <>
                          <ItemIcon stroke={1.5} size={28} /> {item.name}
                        </>
                      ) : (
                        <ItemIcon stroke={1.5} size={28} />
                      )}
                    </Flex>
                  </Tooltip>
                </Wrapper>
              );
            }
          })}
        </Accordion>
      </Wrapper>
    </Box>
  );
};

export default SideMenuList;
