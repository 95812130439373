import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  SimpleGrid,
  Spacer,
} from '@chakra-ui/react';
import { IconTrash } from '@tabler/icons-react';
import { ReactSelect } from 'components/common/ReactSelect';
import useCustomToast from 'hooks/toast';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import { LabReportHookFormType, LabReportItemHookFormType } from 'types/labReport.types';
import { GradeFilterType, StandardFilterType, TestingFilterType } from 'types/filter.types';
import {
  gradeFilterRequestAsync,
  standardFilterRequestAsync,
  testingFilterRequestAsync,
} from 'controller/filter.controller';

const Specification = () => {
  const showToast = useCustomToast();
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<LabReportHookFormType>();

  const { fields, append, remove } = useFieldArray<LabReportHookFormType>({
    control,
    name: 'items',
  });

  const [standardFilter, setStandardFilter] = useState<StandardFilterType[]>();
  const [gradeFilter, setGradeFilter] = useState<GradeFilterType[]>();
  const [testingFilter, setTestingFilter] = useState<TestingFilterType[]>();

  const fetchFilterData = async () => {
    try {
      const [grade, standard, testing] = await Promise.all([
        gradeFilterRequestAsync(),
        standardFilterRequestAsync(),
        testingFilterRequestAsync(),
      ]);

      setGradeFilter(grade);
      setStandardFilter(standard);
      setTestingFilter(testing);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  useEffect(() => {
    fetchFilterData();
  }, []);

  const defaultValue: Omit<LabReportItemHookFormType, 'itemId'> = {
    standard: null,
    grade: null,
    moc: '',
    size: '',
    idNO: '',
    heatNO: '',
    testingReq: [],
  };

  return (
    <>
      {fields.map((subform, index) => (
        <Flex
          width={'100%'}
          gap={2}
          py={6}
          borderBottom={'4px double'}
          borderBottomColor={'gray.200'}
          key={subform.id}
          direction={{ lg: 'row', base: 'column' }}
          alignItems={{ lg: 'center', base: 'end' }}
        >
          <SimpleGrid
            columns={{ lg: 4, sm: 2, base: 1 }}
            width={'100%'}
            spacingY="30px"
            spacingX="10px"
            rounded={'lg'}
            background={'background.primary'}
            p={4}
          >
            <Flex direction={'column'} width={'100%'}>
              <Controller
                name={`items.${index}.standard`}
                control={control}
                render={({ field }) => (
                  <FormControl isInvalid={!!errors?.items?.[index]?.standard}>
                    <FormLabel fontSize={'14px'}>
                      Standard : <span style={{ color: 'red' }}>*</span>
                    </FormLabel>
                    <ReactSelect
                      isMulti={false}
                      isSearchable={true}
                      isClearable={true}
                      options={standardFilter}
                      getOptionLabel={(option) => option.standardName}
                      getOptionValue={(option) => option.standardName}
                      placeholder={'Standard...'}
                      fields={field}
                      showCrossIcon={true}
                      menuListHeigth="200px"
                    />
                    <FormErrorMessage>
                      {errors.items?.[index]?.standard?.message ||
                        errors.items?.[index]?.standard?.standardId?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </Flex>
            <Flex direction={'column'} minWidth={{ base: '100%', xl: '15%' }}>
              <Controller
                name={`items.${index}.grade`}
                control={control}
                render={({ field }) => (
                  <FormControl isInvalid={!!errors?.items?.[index]?.grade}>
                    <FormLabel fontSize={'14px'}>
                      Grade : <span style={{ color: 'red' }}>*</span>
                    </FormLabel>
                    <ReactSelect
                      isMulti={false}
                      isSearchable={true}
                      isClearable={true}
                      options={gradeFilter}
                      getOptionLabel={(option) => option.gradeName}
                      getOptionValue={(option) => option.gradeName}
                      placeholder={'Grade...'}
                      fields={field}
                      showCrossIcon={true}
                      menuListHeigth="200px"
                    />
                    <FormErrorMessage>
                      {errors.items?.[index]?.grade?.message ||
                        errors.items?.[index]?.grade?.gradeId?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </Flex>
            <Flex direction={'column'} minWidth={{ base: '100%', xl: '8%' }}>
              <FormControl isInvalid={!!errors?.items?.[index]?.moc}>
                <FormLabel fontSize={'14px'}>
                  MOC : <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Input
                  placeholder="MOC..."
                  _placeholder={{ color: 'gray.300', fontSize: 'sm' }}
                  variant={'outline'}
                  width={'100%'}
                  sx={{ paddingX: '8px' }}
                  {...register(`items.${index}.moc`)}
                />
                <FormErrorMessage>{errors.items?.[index]?.moc?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex direction={'column'} minWidth={{ base: '100%', xl: '8%' }}>
              <FormControl isInvalid={!!errors?.items?.[index]?.size}>
                <FormLabel fontSize={'14px'}>
                  Size : <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Input
                  placeholder="Size..."
                  _placeholder={{ color: 'gray.300', fontSize: 'sm' }}
                  variant={'outline'}
                  width={'100%'}
                  sx={{ paddingX: '8px' }}
                  {...register(`items.${index}.size`)}
                />
                <FormErrorMessage>{errors.items?.[index]?.size?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex direction={'column'} minWidth={{ base: '100%', xl: '8%' }}>
              <FormControl isInvalid={!!errors?.items?.[index]?.idNO}>
                <FormLabel fontSize={'14px'}>
                  ID No. : <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Input
                  placeholder="ID No. ..."
                  _placeholder={{ color: 'gray.300', fontSize: 'sm' }}
                  variant={'outline'}
                  width={'100%'}
                  sx={{ paddingX: '8px' }}
                  {...register(`items.${index}.idNO`)}
                />
                <FormErrorMessage>{errors.items?.[index]?.idNO?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex direction={'column'} minWidth={{ base: '100%', xl: '8%' }}>
              <FormControl isInvalid={!!errors?.items?.[index]?.heatNO}>
                <FormLabel fontSize={'14px'}>
                  Heat No : <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Input
                  placeholder="Heat No. s..."
                  _placeholder={{ color: 'gray.300', fontSize: 'sm' }}
                  variant={'outline'}
                  width={'100%'}
                  sx={{ paddingX: '8px' }}
                  {...register(`items.${index}.heatNO`)}
                />
                <FormErrorMessage>{errors.items?.[index]?.heatNO?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex direction={'column'} width={{ sm: `calc(200% + 10px)`, base: '100%' }}>
              <Controller
                name={`items.${index}.testingReq`}
                control={control}
                render={({ field }) => (
                  <FormControl isInvalid={!!errors?.items?.[index]?.testingReq}>
                    <FormLabel fontSize={'14px'}>
                      Testing Required : <span style={{ color: 'red' }}>*</span>
                    </FormLabel>
                    <ReactSelect
                      isMulti={true}
                      isSearchable={true}
                      options={testingFilter}
                      getOptionLabel={(option) => option.testName}
                      getOptionValue={(option) => option.testName}
                      placeholder={'Testing Required...'}
                      fields={field}
                      menuListHeigth="120px"
                    />
                    <FormErrorMessage>
                      {errors.items?.[index]?.testingReq?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </Flex>
          </SimpleGrid>
          <Spacer />
          <Box>
            <IconButton
              aria-label="delete"
              icon={<IconTrash size={16} />}
              isDisabled={fields.length === 1}
              variant={'secondary'}
              colorScheme="secondary"
              size={'xs'}
              onClick={() => remove(index)}
            />
          </Box>
        </Flex>
      ))}
      <Flex my={4} justifyContent={'right'} width={'100%'}>
        <Button
          onClick={() => {
            append(defaultValue);
          }}
          variant={'primary'}
          colorScheme="primary"
        >
          Add Entries
        </Button>
      </Flex>
    </>
  );
};

export default Specification;
