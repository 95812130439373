import React from 'react';
import { Box, Heading, Text, Button, Card } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'config/eunm';

const AccessDeniedPage = () => {
  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate(APP_ROUTES.HOME_PAGE);
  };

  return (
    <Card variant={'content'}>
      <Box textAlign="center" p={6} my={'auto'}>
        <Heading
          display="inline-block"
          as="h2"
          fontSize={'9rem'}
          lineHeight={1}
          color={'mainColors.primary'}
        >
          403
        </Heading>
        <Text fontSize={'3.5rem'} fontWeight={100} mt={3} mb={2}>
          Access Denied
        </Text>
        <Text opacity={'.5'} mb={2}>
          You are unauthorized to access this resource.
        </Text>
        <Text opacity={'.5'} mb={6}>
          Please contact your Office Administrator or IT Department to access this page
        </Text>

        <Button variant="primary" colorScheme={'primary'} onClick={redirectToHome}>
          Go to Home
        </Button>
      </Box>
    </Card>
  );
};

export default AccessDeniedPage;
