import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer,
} from '@chakra-ui/react';
import BreadCrumbLayout from 'components/common/BreadCrumb';
import { PageHeading, SectionHeading } from 'components/common/Headings';
import { useForm, SubmitHandler } from 'react-hook-form';
import useCustomToast from 'hooks/toast';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'config/eunm';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  CreateTestingHookFormType,
  CreateTestingType,
  TestingHookFormType,
} from 'types/testing.types';
import { createLabTestRequestAsync } from '../controller';
import { MenuAccessItem, createAccessCheck } from 'helpers/accessCheck.helper';
import { UserDetails } from 'App';
import AccessDeniedPage from 'components/AccessDeniedPage';
import { CreateTestingValidation } from 'validations/testing.validation';

const TestingForm = () => {
  const showToast = useCustomToast();
  const navigate = useNavigate();
  const [createAccessDenied, setCreateAccessDenied] = useState<boolean>(false);

  const { user, setUser } = useContext(UserDetails);

  useEffect(() => {
    const access = createAccessCheck('Master', user?.menuAccess as MenuAccessItem[]);
    if (!access) {
      setCreateAccessDenied(true);
    }
    if (access) {
      setCreateAccessDenied(false);
    }
  }, [user]);

  const defaultValues: CreateTestingHookFormType = {
    testingName: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
    reset,
  } = useForm<CreateTestingHookFormType>({
    defaultValues: defaultValues,
    resolver: yupResolver(CreateTestingValidation),
    mode: 'onBlur',
  });
  const onSubmit: SubmitHandler<CreateTestingHookFormType> = async (data) => {
    console.log('Testing Data', data);
    const payload: CreateTestingType = {
      testingTypeName: data.testingName,
    };
    try {
      await createLabTestRequestAsync(payload);
      navigate(-1);
      showToast({
        title: 'Success  ',
        description: 'Test Successfully Created',
        status: 'success',
      });
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const handleCancel = () => {
    reset();
    navigate(-1);
  };

  return (
    <Box width={'100%'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction={'column'} gap={5}>
          <Box width={'100%'}>
            <Card
              variant={'headerBg'}
              display={'flex'}
              flexDirection={{ base: 'column', md: 'row' }}
              gap={5}
              alignItems={{ md: 'center', base: 'start' }}
            >
              <PageHeading props={{ pb: 2 }}>Create New Test</PageHeading>
              <Spacer />
              <BreadCrumbLayout
                options={[
                  { name: 'Dashboard', link: `${APP_ROUTES.HOME_PAGE}` },
                  { name: 'Others' },
                  {
                    name: 'Testing',
                    link: `${APP_ROUTES.ROOT_CONFIG}/${APP_ROUTES.CONFIG_TESTING_LIST}`,
                  },
                  { name: 'Create New Test ', isActivePage: true },
                ]}
              />
            </Card>
          </Box>{' '}
          {createAccessDenied ? (
            <AccessDeniedPage />
          ) : (
            <>
              <Box width={'100%'}>
                <Card
                  variant={'content'}
                  borderTop={'4px solid'}
                  borderColor={'componentBorderColor.basic'}
                >
                  <CardHeader mb={4} px={6} py={2} display={'flex'} alignItems={'center'}>
                    <SectionHeading props={{ fontWeight: 'semibold', fontSize: '2xl' }}>
                      Test Details
                    </SectionHeading>
                  </CardHeader>
                  <CardBody as={Flex} direction={'column'} gap={10}>
                    <Flex direction={'column'} width={'100%'} gap={4}>
                      <FormControl isInvalid={!!errors.testingName}>
                        <FormLabel>
                          Test Name: <span style={{ color: 'red' }}>*</span>
                        </FormLabel>
                        <Box w={'100%'} background={'white'} rounded={'lg'}>
                          <Input
                            placeholder="Test Name ..."
                            width={'100%'}
                            {...register('testingName')}
                          />
                        </Box>
                        <FormErrorMessage>
                          {errors.testingName && errors.testingName.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </CardBody>
                </Card>
              </Box>
              <Flex alignItems={{ md: 'end', base: 'start' }} direction={'column'}>
                <Flex gap={3} justify={'space-between'} w={{ base: '100%', md: 'auto' }}>
                  <Button
                    variant={'primary'}
                    colorScheme="create"
                    type="submit"
                    isDisabled={!isDirty}
                  >
                    Create New Testing
                  </Button>
                  <Button variant="secondary" colorScheme="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
      </form>
    </Box>
  );
};

export default TestingForm;
