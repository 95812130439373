import config from 'config/appConfig';
import { CreateWarehouseType, EditWarehouseType } from 'types/warehouse.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllWarehouseAPI = () => {
  const url = `${API_ENDPOINT}/warehouse`;
  return API.get(url);
};

export const createWarehouseAPI = (body: CreateWarehouseType) => {
  const url = `${API_ENDPOINT}/warehouse`;
  return API.post(url, body);
};

export const getSingleWarehouseAPI = (id: number) => {
  const url = `${API_ENDPOINT}/warehouse/${id}`;
  return API.get(url);
};

export const updateWarehouseAPI = (id: number, payload: EditWarehouseType) => {
  const url = `${API_ENDPOINT}/warehouse/${id}`;
  return API.put(url, payload);
};

export const deleteWarehouseAPI = (id: number) => {
  const url = `${API_ENDPOINT}/warehouse/${id}`;
  return API.deleteResource(url);
};
