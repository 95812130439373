import React from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  SimpleGrid,
  Spacer,
} from '@chakra-ui/react';
import { IconTrash } from '@tabler/icons-react';

import { useFormContext, useFieldArray } from 'react-hook-form';
import { LaboratoryContactHookFormType, LaboratoryHookFormType } from 'types/laboratory.types';

const Contact = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<LaboratoryHookFormType>();

  const { fields, append, remove } = useFieldArray<LaboratoryHookFormType>({
    control,
    name: 'contact',
  });

  const defaultValue: LaboratoryContactHookFormType = {
    mobile: '',
    personName: '',
    email: '',
    birthday: '',
  };
  return (
    <>
      {fields.map((subform, index) => (
        <Flex
          width={'100%'}
          gap={2}
          py={6}
          borderBottom={'4px double'}
          borderBottomColor={'gray.200'}
          key={subform.id}
          direction={{ lg: 'row', base: 'column' }}
          alignItems={{ lg: 'center', base: 'end' }}
        >
          <SimpleGrid
            columns={{ lg: 4, sm: 2, base: 1 }}
            width={'100%'}
            spacingY="30px"
            spacingX="20px"
            rounded={'lg'}
            background={'background.primary'}
            p={4}
          >
            <Flex direction={'column'} minWidth={'10%'}>
              <FormControl isInvalid={!!errors?.contact?.[index]?.personName}>
                <FormLabel fontSize={'14px'}>
                  Name : <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Input
                  placeholder="Name..."
                  variant={'outline'}
                  {...register(`contact.${index}.personName`)}
                />
                <FormErrorMessage>{errors.contact?.[index]?.personName?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex direction={'column'} minWidth={'10%'}>
              <FormControl isInvalid={!!errors.contact?.[index]?.birthday}>
                <FormLabel fontSize={'14px'}>Birth Date:</FormLabel>
                <Input type="date" variant={'outline'} {...register(`contact.${index}.birthday`)} />
                <FormErrorMessage>{errors.contact?.[index]?.birthday?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex direction={'column'} minWidth={'10%'}>
              <FormControl isInvalid={!!errors.contact?.[index]?.email}>
                <FormLabel fontSize={'14px'}>
                  Email : <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Input
                  placeholder="Email..."
                  variant={'outline'}
                  {...register(`contact.${index}.email`)}
                />
                <FormErrorMessage>{errors.contact?.[index]?.email?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex direction={'column'} minWidth={'10%'}>
              <FormControl isInvalid={!!errors.contact?.[index]?.mobile}>
                <FormLabel fontSize={'14px'}>
                  Mobile : <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Input
                  placeholder="Mobile..."
                  variant={'outline'}
                  {...register(`contact.${index}.mobile`)}
                />
                <FormErrorMessage>{errors.contact?.[index]?.mobile?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
          </SimpleGrid>
          <Spacer />
          <Box>
            <IconButton
              aria-label="delete"
              icon={<IconTrash size={16} />}
              isDisabled={fields.length === 1}
              variant={'secondary'}
              colorScheme="secondary"
              size={'sm'}
              onClick={() => remove(index)}
            />
          </Box>
        </Flex>
      ))}
      <Flex my={4} justifyContent={'right'} width={'100%'}>
        <Button
          onClick={() => {
            append(defaultValue);
          }}
          variant={'primary'}
          colorScheme="primary"
        >
          Add Entries
        </Button>
      </Flex>
    </>
  );
};

export default Contact;
