import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { APP_ROUTES } from 'config/eunm';
import { deleteSingleCompanyRequestAsync } from 'container/Configuration/Company/controller';
import { deleteSingleConditionRequestAsync } from 'container/Configuration/Condition/controller';
import { deleteSingleCountryRequestAsync } from 'container/Configuration/Country/controller';
import { deleteSinglePostRequestAsync } from 'container/Configuration/Designation/controller';
import { deleteSingleInquirySourceRequestAsync } from 'container/Configuration/InquirySource/controller';
import { deleteSingleMakeRequestAsync } from 'container/Configuration/Make/controller';
import { deleteSingleProcessRequestAsync } from 'container/Configuration/Process/controller';
import { deleteSingleStandardRequestAsync } from 'container/Configuration/Standard/controller';
import { deleteSingleLabTestRequestAsync } from 'container/Configuration/TestingList/controller';
import { deleteSingleInquiryRequestAsync } from 'container/Inquiries/controller';
import {
  deleteSingleCurrentInventoryRequestAsync,
  deleteSingleInInventoryRequestAsync,
  deleteSingleOutInventoryRequestAsync,
} from 'container/Inventory/controller';
import { deleteSingleLabReportRequestAsync } from 'container/LabReport/controller';
import { deleteSingleCFTReportRequestAsync } from 'container/ManagerView/CFT Report/controller';
import { deleteSingleBrokerRequestAsync } from 'container/Master/BrokerList/controller';
import { deleteSingleCategoryRequestAsync } from 'container/Master/CategoryList/controller';
import { deleteSingleCityRequestAsync } from 'container/Master/CityList/controller';
import { deleteSingleCustomerRequestAsync } from 'container/Master/CustomerList/controller';
import { deleteSingleGradeRequestAsync } from 'container/Master/GradeList/controller';
import { deleteSingleLaboratoryRequestAsync } from 'container/Master/LaboratoryList/controller';
import { deleteSingleLocationRequestAsync } from 'container/Master/LocationList/controller';
import { deleteSinglePurchaserRequestAsync } from 'container/Master/PurchaserList/controller';
import { deleteSingleStateRequestAsync } from 'container/Master/StateList/controller';
import { deleteSingleUserRequestAsync } from 'container/Master/UserList/controller';
import { deleteSingleVendorRequestAsync } from 'container/Master/VendorList/controller';
import { deleteSingleWarehouseRequestAsync } from 'container/Master/WarehouseList/controller';
import { deleteSingleCPORequestAsync } from 'container/PurchaseOrder/CPO/controller';
import { deleteSellerPurchaseOrderRequestAsync } from 'container/PurchaseOrder/SPO/controller';
import { deleteSingleQuotationRequestAsync } from 'container/Quotations/controller';
import useCustomToast from 'hooks/toast';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface DeleteDialogProps {
  dialogName: string;
  dialogFor: string;
  dataId: number;
  isOpen: boolean;
  onClose: () => void;
}

const DeleteDialog = ({ isOpen, onClose, dialogName, dialogFor, dataId }: DeleteDialogProps) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const showToast = useCustomToast();
  const navigate = useNavigate();

  const deleteSingleCFTReport = async () => {
    try {
      await deleteSingleCFTReportRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_MANAGER_VIEW}/${APP_ROUTES.MANAGER_VIEW_CFT}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteInquiry = async () => {
    try {
      await deleteSingleInquiryRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(APP_ROUTES.INQUIRY_LEADS);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteQuotation = async () => {
    try {
      await deleteSingleQuotationRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(APP_ROUTES.QUOTATIONS);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteCustomer = async () => {
    try {
      await deleteSingleCustomerRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_CLIENT_MASTER}/${APP_ROUTES.CM_CUSTOMER_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteBroker = async () => {
    try {
      await deleteSingleBrokerRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_CLIENT_MASTER}/${APP_ROUTES.CM_BROKER_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteVendor = async () => {
    try {
      await deleteSingleVendorRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_CLIENT_MASTER}/${APP_ROUTES.CM_VENDOR_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deletePurchaser = async () => {
    try {
      await deleteSinglePurchaserRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_CLIENT_MASTER}/${APP_ROUTES.CM_PURCHASER_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteGrade = async () => {
    try {
      await deleteSingleGradeRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_MASTER}/${APP_ROUTES.MASTER_GRADE_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteCategory = async () => {
    try {
      await deleteSingleCategoryRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_MASTER}/${APP_ROUTES.MASTER_CATEGORY_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteLocation = async () => {
    try {
      await deleteSingleLocationRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_MASTER}/${APP_ROUTES.MASTER_LOCATION_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteCompany = async () => {
    try {
      await deleteSingleCompanyRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_CONFIG}/${APP_ROUTES.CONFIG_COMPANY_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };
  const deleteDesignation = async () => {
    try {
      await deleteSinglePostRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_CONFIG}/${APP_ROUTES.CONFIG_DESIGNATION_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteCondition = async () => {
    try {
      await deleteSingleConditionRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_CONFIG}/${APP_ROUTES.CONFIG_CONDITION_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteInquirySource = async () => {
    try {
      await deleteSingleInquirySourceRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_CONFIG}/${APP_ROUTES.CONFIG_INQUIRY_SOURCE_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteProcess = async () => {
    try {
      await deleteSingleProcessRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_CONFIG}/${APP_ROUTES.CONFIG_PROCESS_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteMake = async () => {
    try {
      await deleteSingleMakeRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_CONFIG}/${APP_ROUTES.CONFIG_MAKE_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteCountry = async () => {
    try {
      await deleteSingleCountryRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_CONFIG}/${APP_ROUTES.CONFIG_COUNTRY_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteStandard = async () => {
    try {
      await deleteSingleStandardRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_CONFIG}/${APP_ROUTES.CONFIG_STANDARD_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteCity = async () => {
    try {
      await deleteSingleCityRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_MASTER}/${APP_ROUTES.MASTER_CITY_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteState = async () => {
    try {
      await deleteSingleStateRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_MASTER}/${APP_ROUTES.MASTER_STATE_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteWarehouse = async () => {
    try {
      await deleteSingleWarehouseRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_MASTER}/${APP_ROUTES.MASTER_WAREHOUSE_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteUser = async () => {
    try {
      await deleteSingleUserRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_MASTER}/${APP_ROUTES.MASTER_USER_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteInInventory = async () => {
    try {
      await deleteSingleInInventoryRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_INVENTORY}/${APP_ROUTES.INVENTORY_IN_STOCK_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteCurrentInventory = async () => {
    try {
      await deleteSingleCurrentInventoryRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_INVENTORY}/${APP_ROUTES.INVENTORY_CURRENT_STOCK_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteOutInventory = async () => {
    try {
      await deleteSingleOutInventoryRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_INVENTORY}/${APP_ROUTES.INVENTORY_OUT_STOCK_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteSPO = async () => {
    try {
      await deleteSellerPurchaseOrderRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_PURCHASE_ORDER}/${APP_ROUTES.SUPPLIER_PURCHASE_ORDERS}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteCPO = async () => {
    try {
      await deleteSingleCPORequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_PURCHASE_ORDER}/${APP_ROUTES.CUSTOMER_PURCHASE_ORDERS}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteLaboratory = async () => {
    try {
      await deleteSingleLaboratoryRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_MASTER}/${APP_ROUTES.MASTER_LABORATORY_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const deleteLabTest = async () => {
    try {
      await deleteSingleLabTestRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.ROOT_CONFIG}/${APP_ROUTES.CONFIG_TESTING_LIST}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };
  const deleteLabReport = async () => {
    try {
      await deleteSingleLabReportRequestAsync(dataId ? dataId : 0);
      showToast({
        title: 'Success',
        description: `${dialogName} successfully Deleted`,
        status: 'success',
      });
      onClose();
      navigate(`${APP_ROUTES.LAB_REPORT}`);
      navigate(0);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const handleDelete = () => {
    switch (dialogFor) {
      case 'cftReport':
        deleteSingleCFTReport();
        break;
      case 'inquiry':
        deleteInquiry();
        break;
      case 'quotation':
        deleteQuotation();
        break;
      case 'customer':
        deleteCustomer();
        break;
      case 'broker':
        deleteBroker();
        break;
      case 'vendor':
        deleteVendor();
        break;
      case 'purchaser':
        deletePurchaser();
        break;
      case 'grade':
        deleteGrade();
        break;
      case 'category':
        deleteCategory();
        break;
      case 'location':
        deleteLocation();
        break;
      case 'company':
        deleteCompany();
        break;
      case 'designation':
        deleteDesignation();
        break;
      case 'condition':
        deleteCondition();
        break;
      case 'inquirySource':
        deleteInquirySource();
        break;
      case 'make':
        deleteMake();
        break;
      case 'process':
        deleteProcess();
        break;
      case 'country':
        deleteCountry();
        break;
      case 'standard':
        deleteStandard();
        break;
      case 'city':
        deleteCity();
        break;
      case 'state':
        deleteState();
        break;
      case 'warehouse':
        deleteWarehouse();
        break;
      case 'user':
        deleteUser();
        break;
      case 'inStock':
        deleteInInventory();
        break;
      case 'currentStock':
        deleteCurrentInventory();
        break;
      case 'outStock':
        deleteOutInventory();
        break;
      case 'spo':
        deleteSPO();
        break;
      case 'cpo':
        deleteCPO();
        break;
      case 'laboratory':
        deleteLaboratory();
        break;
      case 'labTesting':
        deleteLabTest();
        break;
      case 'labReport':
        deleteLabReport();
        break;
      default:
        break;
    }
    console.log('Delete Data', dialogFor, dataId);
  };
  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete {dialogName}
          </AlertDialogHeader>

          <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} variant={'secondary'} colorScheme="primary">
              Cancel
            </Button>
            <Button variant={'primary'} colorScheme="secondary" onClick={handleDelete} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DeleteDialog;
