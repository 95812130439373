import {
  createGradeAPI,
  deleteGradeAPI,
  getAllGradeAPI,
  getSingleGradeAPI,
  updateGradeAPI,
} from 'services/grade';
import {
  AllGradeType,
  CreateGradeType,
  EditGradeType,
  GetSingleGradeType,
  GradeTableType,
} from 'types/grade.types';

export const getAllGradeRequestAsync = async (): Promise<GradeTableType[]> => {
  const resp = await getAllGradeAPI();
  const filteredResp: GradeTableType[] = resp.data.data.map(
    (grade: AllGradeType): GradeTableType => ({
      gradeId: grade.gradeId,
      gradeName: grade.grade_name,
      description: grade.grade_description,
      createdDate: grade.created_date,
    }),
  );
  return filteredResp;
};

export const createGradeRequestAsync = async (payload: CreateGradeType) => {
  const resp = await createGradeAPI(payload);
  return resp;
};

export const getSingleGradeRequestAsync = async (id: number): Promise<GetSingleGradeType> => {
  const resp = await getSingleGradeAPI(id);
  return resp.data.data;
};

export const updateGradeRequestAsync = async (id: number, payload: EditGradeType) => {
  const resp = await updateGradeAPI(id, payload);
  return resp;
};

export const deleteSingleGradeRequestAsync = async (id: number) => {
  const resp = await deleteGradeAPI(id);
  return resp;
};
