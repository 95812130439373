import { Box, Input } from '@chakra-ui/react';
import { Column, Table } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';

const Filter = ({ column, table }: { column: Column<any, any>; table: Table<any> }) => {
  const columnFilterValue: { label: string; value: string } | unknown = column.getFilterValue();
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue((columnFilterValue ?? '') as string);
  }, [columnFilterValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      column.setFilterValue(value);
    }, 500);

    return () => clearTimeout(timeout);
  }, [value]);
  return (
    <Box>
      <Input
        textColor={'text.primary'}
        background={'background.secondary'}
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={`Search... `}
        size={'sm'}
        rounded={'md'}
      />
    </Box>
  );
};

export default Filter;
