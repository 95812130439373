import {
  createCompanyAPI,
  deleteCompanyAPI,
  getAllCompanyAPI,
  getSingleCompanyAPI,
  updateCompanyAPI,
} from 'services/company';
import {
  AllCompanyType,
  CompanyTableType,
  CreateCompanyType,
  EditCompanyType,
  GetSingleCompanyType,
} from 'types/company.types';

export const getAllCompanyRequestAsync = async (): Promise<CompanyTableType[]> => {
  const resp = await getAllCompanyAPI();
  const filteredResp: CompanyTableType[] = resp.data.data.map(
    (company: AllCompanyType): CompanyTableType => ({
      companyCode: company.companyCode,
      companyId: company.companyId,
      companyName: company.companyName,
    }),
  );
  return filteredResp;
};

export const createCompanyRequestAsync = async (payload: CreateCompanyType) => {
  const resp = await createCompanyAPI(payload);
  return resp;
};

export const getSingleCompanyRequestAsync = async (id: number): Promise<GetSingleCompanyType> => {
  const resp = await getSingleCompanyAPI(id);
  return resp.data.data;
};

export const updateCompanyRequestAsync = async (id: number, payload: EditCompanyType) => {
  const resp = await updateCompanyAPI(id, payload);
  return resp;
};

export const deleteSingleCompanyRequestAsync = async (id: number) => {
  const resp = await deleteCompanyAPI(id);
  return resp;
};
