import {
  createLocationAPI,
  deleteLocationAPI,
  getAllLocationAPI,
  getSingleLocationAPI,
  updateLocationAPI,
} from 'services/location';
import {
  AllLocationType,
  CreateLocationType,
  EditLocationType,
  GetSingleLocationType,
  LocationTableType,
} from 'types/location.types';

export const getAllLocationRequestAsync = async (): Promise<LocationTableType[]> => {
  const resp = await getAllLocationAPI();
  const filteredResp: LocationTableType[] = resp.data.data.map(
    (location: AllLocationType): LocationTableType => ({
      locationId: location.locationID,
      locationName: location.locationName,
      locationPrefix: location.locationPrefix,
      address: location.address,
      creationDate: location.creationDate,
    }),
  );
  return filteredResp;
};

export const createLocationRequestAsync = async (payload: CreateLocationType) => {
  const resp = await createLocationAPI(payload);
  return resp;
};

export const getSingleLocationRequestAsync = async (id: number): Promise<GetSingleLocationType> => {
  const resp = await getSingleLocationAPI(id);
  return resp.data;
};

export const updateLocationRequestAsync = async (id: number, payload: EditLocationType) => {
  console.log('payload', payload);
  console.log('id', id);
  const resp = await updateLocationAPI(id, payload);
  return resp;
};

export const deleteSingleLocationRequestAsync = async (id: number) => {
  const resp = await deleteLocationAPI(id);
  return resp;
};
