import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Card, CardBody, CardHeader, Flex, Spacer } from '@chakra-ui/react';
import BreadCrumbLayout from 'components/common/BreadCrumb';
import { PageHeading, SectionHeading } from 'components/common/Headings';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import useCustomToast from 'hooks/toast';
import { useNavigate } from 'react-router-dom';
import { getCookie } from 'utils/common';
import { APP_ROUTES } from 'config/eunm';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getUserProfileRequestAsync } from 'controller/userProfile.controller';
import { USER_ID } from 'config/constant';
import {
  CreateLabReportItemType,
  CreateLabReportType,
  LabReportHookFormType,
} from 'types/labReport.types';
import Specification from './Specification';
import BasicDetails from './BasicDetails';
import { PriorityType } from 'types/common.types';
import { createLabReportRequestAsync } from '../controller';
import { MenuAccessItem, createAccessCheck } from 'helpers/accessCheck.helper';
import { UserDetails } from 'App';
import AccessDeniedPage from 'components/AccessDeniedPage';

const LabReportForm = () => {
  const navigate = useNavigate();
  const showToast = useCustomToast();
  const [createAccessDenied, setCreateAccessDenied] = useState<boolean>(false);

  const { user, setUser } = useContext(UserDetails);

  useEffect(() => {
    const access = createAccessCheck('LabReport', user?.menuAccess as MenuAccessItem[]);
    if (!access) {
      setCreateAccessDenied(true);
    }
    if (access) {
      setCreateAccessDenied(false);
    }
  }, [user]);

  const defaultValues: LabReportHookFormType = {
    laboratory: null,
    customer: null,
    labNO: '',
    labReportDate: new Date().toISOString().substring(0, 10),
    priority: null,
    deliveryDate: '',
    witnessBy: null,
    stampedBy: '',
    contact: [],
    qapSheet: {
      qapSheetId: 1,
      qapSheetValue: 'Yes',
    },
    items: [
      { standard: null, grade: null, moc: '', size: '', idNO: '', heatNO: '', testingReq: [] },
    ],
  };

  const validationSchema: yup.ObjectSchema<LabReportHookFormType> = yup.object().shape(
    {
      laboratory: yup
        .object()
        .shape({
          labId: yup.number().nullable().required('Lab Id is required'),
          labName: yup.string().nullable().required('Lab is required'),
        })
        .nullable()
        .default(null)
        .required('Required'),
      customer: yup
        .object()
        .shape({
          customerId: yup.number().nullable().required('Customer is required'),
          customerName: yup.string().nullable().required('Customer is required'),
        })
        .nullable()
        .default(null)
        .required('Required*'),
      labNO: yup
        .string()
        .optional()
        .when('labNO', {
          is: (value: any) => value?.length,
          then: (rule) => rule.min(2, 'Should have atleast 2 character'),
        }),
      labReportDate: yup.string().required('Required*'),
      priority: yup
        .object()
        .shape({
          priorityId: yup.number().nullable().required('Priority ID is required'),
          priorityName: yup.string<PriorityType>().nullable().required('Priority Name is required'),
        })
        .nullable()
        .default(null)
        .required('Required*'),
      deliveryDate: yup.string().optional(),
      stampedBy: yup.string().required('Required*'),
      witnessBy: yup
        .object()
        .shape({
          userId: yup.number().defined(),
          userName: yup.string().defined(),
        })
        .nullable()
        .default(null)
        .optional(),
      contact: yup.array(
        yup
          .object()
          .shape({
            contactId: yup.number().required('Contact ID is required'),
            contactName: yup.string().required('Contact Name is required'),
          })
          .default([]),
      ),
      qapSheet: yup
        .object()
        .shape({
          qapSheetId: yup.number().defined(),
          qapSheetValue: yup.string<'Yes' | 'No'>().defined(),
        })
        .required('Required*'),
      items: yup
        .array(
          yup.object().shape({
            standard: yup
              .object()
              .shape({
                standardId: yup.number().nullable().required('Standard Id is required'),
                standardName: yup.string().nullable().required('Standard is required'),
              })
              .nullable()
              .default(null)
              .required('Required*'),
            grade: yup
              .object()
              .shape({
                gradeId: yup.number().nullable().required('Grade Id is required'),
                gradeName: yup.string().nullable().required('Grade is required'),
              })
              .nullable()
              .default(null)
              .required('Required*'),
            moc: yup.string().required('Required*'),
            size: yup.string().required('Required*'),
            idNO: yup.string().required('Required*'),
            heatNO: yup.string().required('Required*'),
            testingReq: yup
              .array(
                yup.object().shape({
                  testId: yup.number().required('Test ID is required'),
                  testName: yup.string().required('Test Name is required'),
                }),
              )
              .default([])
              .min(1, 'Select at least 1 option')
              .required('Required*'),
          }),
        )
        .default([])
        .min(1, 'Select at least 1 option')
        .required('Required*'),
    },
    [['labNO', 'labNO']],
  );

  const methods = useForm<LabReportHookFormType>({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  useEffect(() => {
    if (user) {
      methods.setValue('stampedBy', user!.firstName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit: SubmitHandler<LabReportHookFormType> = async (data) => {
    console.log('Lab Report Data', data);
    const itemPayload: CreateLabReportItemType[] = data.items.map((item) => {
      const formatedItem: CreateLabReportItemType = {
        standardId: item.standard!.standardId,
        gradeId: item.grade!.gradeId,
        size: item.size,
        moc: item.moc,
        idNO: item.idNO,
        heatNO: item.heatNO,
        testingId: item.testingReq!.map((details) => details.testId),
      };
      return formatedItem;
    });

    const payload: CreateLabReportType = {
      laboratoryId: data.laboratory!.labId,
      customerId: data.customer!.customerId,
      labNO: data.labNO,
      labReportDate: data.labReportDate,
      priorityId: data.priority!.priorityId,
      deliveryDate: data.deliveryDate === '' ? undefined : data.deliveryDate,
      witnessId: data.witnessBy?.userId || undefined,
      contacts: data.contact?.map((details) => details.contactId) || undefined,
      qapSheet: data.qapSheet.qapSheetValue,
      items: itemPayload,
    };
    console.log('Payload', payload);
    try {
      await createLabReportRequestAsync(payload);
      navigate(-1);
      showToast({
        title: 'Success  ',
        description: 'Lab Report Created Successfully ',
        status: 'success',
      });
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      methods.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState.isSubmitSuccessful]);

  const handleCancel = () => {
    methods.reset();
    navigate(-1);
  };

  return (
    <Box width={'100%'}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Flex direction={'column'} gap={5}>
            <Box width={'100%'}>
              <Card
                variant={'headerBg'}
                display={'flex'}
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems={{ md: 'center', base: 'start' }}
              >
                <PageHeading props={{ pb: 2 }}>Create New Lab Report</PageHeading>
                <Spacer />
                <BreadCrumbLayout
                  options={[
                    { name: 'Dashboard', link: `${APP_ROUTES.HOME_PAGE}` },
                    { name: 'Lab Report', link: `${APP_ROUTES.LAB_REPORT}` },
                    { name: 'Create Lab Report', isActivePage: true },
                  ]}
                />
              </Card>
            </Box>
            {createAccessDenied ? (
              <AccessDeniedPage />
            ) : (
              <>
                <BasicDetails />
                {/* Input Mask*/}
                <Box width={'100%'}>
                  <Card
                    variant={'content'}
                    borderTop={'4px solid'}
                    borderColor={'componentBorderColor.specification'}
                  >
                    <CardHeader mb={1} px={6} py={2} display={'flex'} alignItems={'center'}>
                      <SectionHeading props={{ fontWeight: 'semibold', fontSize: '2xl' }}>
                        Specification
                      </SectionHeading>
                    </CardHeader>
                    <CardBody>
                      <Specification />
                    </CardBody>
                  </Card>
                </Box>

                <Flex alignItems={{ md: 'end', base: 'start' }} direction={'column'}>
                  <Flex gap={3} justify={'space-between'} w={{ base: '100%', md: 'auto' }}>
                    <Button
                      variant={'primary'}
                      colorScheme="create"
                      type="submit"
                      isDisabled={!methods.formState.isDirty}
                    >
                      Create New Lab Report
                    </Button>
                    <Button variant="secondary" colorScheme="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Flex>
                </Flex>
              </>
            )}
          </Flex>
        </form>
      </FormProvider>
    </Box>
  );
};

export default LabReportForm;
