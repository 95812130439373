import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = defineStyle({
  borderRadius: '8px',
  padding: '10px',
  maxWidth: '200px',
  cursor: 'pointer',
});

const primaryVariant = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    backgroundColor: `buttonColor.${c}.main`,
    fontSize: '12px',
    whiteSpace: 'normal',
    borderColor: `buttonColor.${c}.main`,
    color: 'text.secondary',
    _hover: {
      boxShadow: '0px 4px 8px #818181',
      backgroundColor: `buttonColor.${c}.hover`,
      outline: 'none',
      border: 'none',
      _disabled: {
        backgroundColor: `buttonColor.${c}.main`,
        boxShadow: 'none',
      },
    },

    _active: {
      transform: 'scale(1, 1)',
      backgroundColor: `buttonColor.${c}.hover`,
    },
  };
});

const secondaryVariant = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    color: 'text.primary',
    border: '3px solid',
    fontSize: '12px',
    whiteSpace: 'normal',
    borderColor: `buttonColor.${c}.main`,
    _hover: {
      bgColor: 'gray.100',
      color: 'text.secondary',
      /* boxShadow: '0px 2px 8px #818181', */
      backgroundColor: `buttonColor.${c}.hover`,
      _disabled: {
        backgroundColor: `buttonColor.${c}.main`,
        boxShadow: 'none',
      },
    },

    _active: {
      transform: 'scale(1, 1)',
      backgroundColor: `buttonColor.${c}.main`,
    },
  };
});

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants: {
    primary: primaryVariant,
    secondary: secondaryVariant,
  },
  defaultProps: {
    size: 'md', // set the default color scheme to purple
  },
});

/* .button-5 {
  align-items: center;
  background-clip: padding-box;
  background-color: #fa6400;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button-5:hover,
.button-5:focus {
  background-color: #fb8332;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-5:hover {
  transform: translateY(-1px);
}

.button-5:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}
 */
