import { CreateTestingHookFormType, TestingHookFormType } from 'types/testing.types';
import * as yup from 'yup';

export const CreateTestingValidation: yup.ObjectSchema<CreateTestingHookFormType> = yup.object({
  testingName: yup.string().required('Required *').min(2, 'Should have atleast 2 character'),
});

export const EditTestingValidation: yup.ObjectSchema<TestingHookFormType> = yup.object({
  testingName: yup.string().required('Required *').min(2, 'Should have atleast 2 character'),
  testingUID: yup.string().required('Required *'),
});
