import { getSingleBrokerAPI } from 'services/broker';
import { getSingleCustomerAPI } from 'services/customer';
import { getSingleLaboratoryAPI } from 'services/laboratory';
import { getSinglePurchaserAPI } from 'services/purchaser';
import { BrokerBasicDetailsType, GetSingleBrokerType } from 'types/broker.types';
import { CustomerBasicDetailsType, GetSingleCustomerType } from 'types/customer.types';
import { GetSingleLaboratoryType, LaboratoryBasicDetailsType } from 'types/laboratory.types';
import { GetSinglePurchaserType, PurchaserBasicDetailsType } from 'types/purchaser.types';

export const getCustomerDetailsRequestAsync = async (
  id: number,
): Promise<CustomerBasicDetailsType> => {
  const resp = await getSingleCustomerAPI(id);
  const customerData: GetSingleCustomerType = resp.data;
  const formatedData: CustomerBasicDetailsType = {
    customerId: customerData.customerId,
    customerName: customerData.customerName,
    customerNamewithCity: customerData.customerNamewithCity,
    customerType: {
      typeId: customerData.customerType.customerTypeId,
      typeName: customerData.customerType.customerTypeName,
    },
    mobile: customerData.mobile,
  };
  return formatedData;
};
export const getBrokerDetailsRequestAsync = async (id: number): Promise<BrokerBasicDetailsType> => {
  const resp = await getSingleBrokerAPI(id);

  const brokerData: GetSingleBrokerType = resp.data.data;
  const formatedData: BrokerBasicDetailsType = {
    brokerId: brokerData.broker_id,
    brokerName: brokerData.broker_name,
    brokerType: {
      typeId: brokerData.broker_type_id,
      typeName: brokerData.broker_type,
    },
    OfficeCity: brokerData.office_city,
    mobile: brokerData.broker_phone,
  };
  return formatedData;
};

export const getPurchaserDetailsRequestAsync = async (
  id: number,
): Promise<PurchaserBasicDetailsType> => {
  const resp = await getSinglePurchaserAPI(id);
  const purchaserData: GetSinglePurchaserType = resp.data;
  const formatedData: PurchaserBasicDetailsType = {
    purchaserId: purchaserData.purchaserId,
    purchaserName: purchaserData.purchaserName,
    customerNamewithCity: purchaserData.pNamewithCity as string,
    purchaserType: {
      typeId: purchaserData.purchaserType.customerTypeId,
      typeName: purchaserData.purchaserType.customerTypeName,
    },
    mobile: purchaserData.pAddress.officePhone ? purchaserData.pAddress.officePhone : 'N.A',
  };
  return formatedData;
};

export const getLaboratoryDetailsRequestAsync = async (
  id: number,
): Promise<LaboratoryBasicDetailsType> => {
  const resp = await getSingleLaboratoryAPI(id);
  const labData: GetSingleLaboratoryType = resp.data;
  const formatedData: LaboratoryBasicDetailsType = {
    laboratoryId: labData.laboratoryId,
    laboratoryName: labData.laboratoryName,
    laboratoryNamewithCity: labData.laboratoryNamewithCity,
    mobile: labData.mobile,
    email: labData.email,
  };
  return formatedData;
};
