const config = {
  isProd:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_BUILD_ENV === 'production'
        ? true
        : false
      : false,
  production: {
    api_endpoint: process.env.REACT_APP_PRODUCTION_API_ENDPOINT,
  },
  development: {
    api_endpoint: process.env.REACT_APP_DEVELOPMENT_API_ENDPOINT,
  },
};
export default config;

// http://31.220.55.98:8182/A2ZSteel_Web/A2ZSteelRest/login
