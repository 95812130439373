import {
  createVendorAPI,
  deleteVendorAPI,
  getAllVendorAPI,
  getSingleVendorAPI,
  updateVendorAPI,
} from 'services/vendor';
import {
  AllVendorType,
  CreateVendorType,
  EditVendorType,
  GetSingleVendorType,
  VendorTableType,
} from 'types/vendor.types';

export const getAllVendorRequestAsync = async (): Promise<VendorTableType[]> => {
  const resp = await getAllVendorAPI();
  const filteredResp: VendorTableType[] = resp.data.data.map(
    (vendor: AllVendorType): VendorTableType => ({
      vendorId: vendor.vendorId,
      vendorName: vendor.vendorName,
      mobile: vendor.mobile,
      email: vendor.email,
      landmark: vendor.landmark,
      officeCity: vendor.officeCity,
    }),
  );
  return filteredResp;
};

export const createVendorRequestAsync = async (payload: CreateVendorType) => {
  const resp = await createVendorAPI(payload);
  return resp;
};

export const getSingleVendorRequestAsync = async (id: number): Promise<GetSingleVendorType> => {
  const resp = await getSingleVendorAPI(id);
  return resp.data.data;
};

export const updateVendorRequestAsync = async (id: number, payload: EditVendorType) => {
  console.log('payload', payload);
  console.log('id', id);
  const resp = await updateVendorAPI(id, payload);
  return resp;
};

export const deleteSingleVendorRequestAsync = async (id: number) => {
  const resp = await deleteVendorAPI(id);
  return resp;
};
