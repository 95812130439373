import config from 'config/appConfig';
import { CreateStateType, EditStateType } from 'types/state.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllStateAPI = () => {
  const url = `${API_ENDPOINT}/state`;
  return API.get(url);
};

export const createStateAPI = (body: CreateStateType) => {
  const url = `${API_ENDPOINT}/state`;
  return API.post(url, body);
};

export const getSingleStateAPI = (id: number) => {
  const url = `${API_ENDPOINT}/state/${id}`;
  return API.get(url);
};

export const updateStateAPI = (id: number, payload: EditStateType) => {
  const url = `${API_ENDPOINT}/state/${id}`;
  return API.put(url, payload);
};

export const deleteStateAPI = (id: number) => {
  const url = `${API_ENDPOINT}/state/${id}`;
  return API.deleteResource(url);
};
