import * as API from 'utils/api-helper';
import config from 'config/appConfig';
import { CreateLabReportType, EditLabReportType } from 'types/labReport.types';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllLabReportAPI = (params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/labreport/paginated?${params.toString()}`;
  return API.get(url);
};

export const getSingleLabReportAPI = (id: number) => {
  const url = `${API_ENDPOINT}/labreport/${id}`;
  return API.get(url);
};

export const createSingleLabReportAPI = (body: CreateLabReportType) => {
  const url = `${API_ENDPOINT}/labreport`;
  return API.post(url, body);
};

export const updateLabReportAPI = (id: number, body: EditLabReportType) => {
  const url = `${API_ENDPOINT}/labreport/${id}`;
  return API.put(url, body);
};

export const deleteLabReportAPI = (id: number) => {
  const url = `${API_ENDPOINT}/labreport/${id}`;
  return API.deleteResource(url);
};

export const downloadLabReportAPI = (cId: number, labRepId: number) => {
  const url = `${API_ENDPOINT}/labreport/pdf?reportid=${labRepId}&companyId=${cId}`;
  const apiConfig = {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    transformResponse: [
      function (data: any) {
        // Do whatever you want to transform the data

        return data;
      },
    ],
  };
  return API.get(url, undefined, apiConfig);
};

export const updateLabReportStatusAPI = (id: number, params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/labreport/${id}/updatestatus?${params.toString()}`;
  return API.put(url);
};
