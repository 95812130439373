import {
  createWarehouseAPI,
  deleteWarehouseAPI,
  getAllWarehouseAPI,
  getSingleWarehouseAPI,
  updateWarehouseAPI,
} from 'services/warehouse';
import {
  AllWarehouseType,
  CreateWarehouseType,
  EditWarehouseType,
  GetSingleWarehouseType,
  WarehouseTableType,
} from 'types/warehouse.types';

export const getAllWarehouseRequestAsync = async (): Promise<WarehouseTableType[]> => {
  const resp = await getAllWarehouseAPI();
  const filteredResp: WarehouseTableType[] = resp.data.data.map(
    (warehouse: AllWarehouseType): WarehouseTableType => ({
      warehouseCode: warehouse.warehouse_code,
      warehouseId: warehouse.warehouse_id,
      warehouseName: warehouse.warehouse_name,
      locationName: warehouse.location,
      uid: warehouse.uid,
    }),
  );
  return filteredResp;
};

export const createWarehouseRequestAsync = async (payload: CreateWarehouseType) => {
  const resp = await createWarehouseAPI(payload);
  return resp;
};

export const getSingleWarehouseRequestAsync = async (
  id: number,
): Promise<GetSingleWarehouseType> => {
  const resp = await getSingleWarehouseAPI(id);
  return resp.data.data;
};

export const updateWarehouseRequestAsync = async (id: number, payload: EditWarehouseType) => {
  const resp = await updateWarehouseAPI(id, payload);
  return resp;
};

export const deleteSingleWarehouseRequestAsync = async (id: number) => {
  const resp = await deleteWarehouseAPI(id);
  return resp;
};
