import React, { useContext, useEffect, useState } from 'react';
import {
  Flex,
  IconButton,
  MenuItem,
  Avatar,
  Box,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  VStack,
  Spacer,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';

import { deleteAllCookies, getCookie } from 'utils/common';
import { IS_ADMIN_NAME } from 'config/constant';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'config/eunm';
import { Wrapper } from 'theme/components/Wrappers/custom.Wrapper';
import LogoText from 'components/common/LogoText';
import { IconMenu2, IconChevronDown, IconUser, IconLogout } from '@tabler/icons-react';
import { sideBarConfig } from './constant';
import ProfileDialog from 'components/common/ProfileDialog';
import { UserDetails } from 'App';

interface IProps {
  onToggle: () => void;
  isSideBarOpen: boolean;
}

const Header = ({ onToggle, isSideBarOpen }: IProps) => {
  const [showShadow, setShowShadow] = useState(false);
  /* const [user, setUser] = useState<UserProfileType>(); */
  const [isAdmin, setIsAdmin] = useState(false);
  const profileDisclosure = useDisclosure();
  const navigate = useNavigate();

  const { user, setUser } = useContext(UserDetails);

  const handleLogout = async () => {
    /* deleteCookie(ACCESS_TOKEN_NAME);
    deleteCookie(IS_VIEW_NAME);
    deleteCookie(IS_ADMIN_NAME);
    deleteCookie(USER_ID);

    console.log('Access Token Deleted:', getCookie(ACCESS_TOKEN_NAME));
    console.log('Is View Deleted:', getCookie(IS_VIEW_NAME));
    console.log('Is Admin Deleted:', getCookie(IS_ADMIN_NAME));
    console.log('User ID Deleted:', getCookie(USER_ID)); */

    deleteAllCookies();
    setUser(undefined);

    setTimeout(() => {
      navigate(APP_ROUTES.LOGIN, { replace: true });
      navigate(0);
      // Resolve the promise after the specified timeout
    }, 1000);
  };

  /* const getUserDetails = async (userId: number) => {
    const resp = await getUserProfileRequestAsync(userId);

    setUser(resp);
  }; */

  useEffect(() => {
    /* const currentUserId = getCookie(USER_ID); */

    const isCurrentAdmin = getCookie(IS_ADMIN_NAME);
    /*  if (currentUserId) {
      getUserDetails(currentUserId);
    } */
    setIsAdmin(isCurrentAdmin);
  }, []);

  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 10 ? setShowShadow(true) : setShowShadow(false);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [showShadow]);

  //const userName = 'Logged User Name'.substring(0, 16);
  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    { ssr: false },
  );
  const desktopShadow = isSideBarOpen ? sideBarConfig.openWidth : sideBarConfig.closeWidth;
  const sideBarShadow = isMobile ? '-6px' : `calc(${desktopShadow} - 8px)`;
  return (
    <Wrapper
      as={'nav'}
      variant="header"
      boxShadow={showShadow ? `rgba(0, 0, 0, 0.45) ${sideBarShadow} 15px 10px -15px` : 'none'}
    >
      <IconButton
        variant={'unstyled'}
        icon={<IconMenu2 size={24} />}
        aria-label="Toggle Sidebar"
        onClick={onToggle}
        size={'sm'}
        mr={2}
      />
      {isMobile && <Spacer />}
      <Box display={isMobile ? (isSideBarOpen ? 'none' : 'block') : 'block'}>
        <LogoText />
      </Box>
      <Spacer />
      <Flex alignItems={'center'}>
        <Menu>
          <MenuButton transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
            <HStack>
              <Avatar size={'sm'} name={`${user?.firstName} ${user?.lastName || ''}`} />
              <VStack
                display={{ base: 'none', md: 'flex' }}
                alignItems="flex-start"
                spacing="1px"
                ml="2"
              >
                <Text fontSize="sm" lineHeight={1}>
                  {user?.firstName} {user?.lastName || ''}
                </Text>
                {isAdmin && (
                  <Text fontSize="12px" opacity={0.5}>
                    Admin
                  </Text>
                )}
              </VStack>
              <Box display={{ base: 'none', md: 'flex' }}>
                <IconChevronDown />
              </Box>
            </HStack>
          </MenuButton>
          <MenuList background={'gray'} p={2} border={'none'}>
            <MenuItem
              background={'gray'}
              textColor={'text.secondary'}
              icon={<IconUser />}
              _hover={{ background: 'gray.400' }}
              borderRadius={10}
              onClick={profileDisclosure.onOpen}
            >
              Profile
            </MenuItem>
            <MenuDivider borderColor={'white'} />
            <MenuItem
              onClick={handleLogout}
              background={'gray'}
              textColor={'text.secondary'}
              icon={<IconLogout />}
              _hover={{ background: 'gray.400' }}
              borderRadius={10}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
        <ProfileDialog
          isOpen={profileDisclosure.isOpen}
          onClose={profileDisclosure.onClose}
          data={user}
        />
      </Flex>
    </Wrapper>
  );
};
export default Header;
