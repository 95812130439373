import {
  createBrokerAPI,
  deleteBrokerAPI,
  getAllBrokerAPI,
  getSingleBrokerAPI,
  updateBrokerAPI,
} from 'services/broker';
import {
  AllBrokerType,
  BrokerTableType,
  CreateBrokerType,
  EditBrokerType,
  GetSingleBrokerType,
} from 'types/broker.types';

export const getAllBrokerRequestAsync = async (): Promise<BrokerTableType[]> => {
  const resp = await getAllBrokerAPI();
  const filteredResp: BrokerTableType[] = resp.data.data.map(
    (broker: AllBrokerType): BrokerTableType => ({
      brokerId: broker.broker_id,
      brokerName: broker.broker_name,
      mobile: broker.broker_phone,
      cityName: broker.broker_city,
    }),
  );
  return filteredResp;
};

export const getSingleBrokerRequestAsync = async (id: number): Promise<GetSingleBrokerType> => {
  const resp = await getSingleBrokerAPI(id);
  return resp.data.data;
};

export const createBrokerRequestAsync = async (payload: CreateBrokerType) => {
  const resp = await createBrokerAPI(payload);
  return resp;
};

export const updateBrokerRequestAsync = async (id: number, payload: EditBrokerType) => {
  const resp = await updateBrokerAPI(id, payload);
  return resp;
};

export const deleteSingleBrokerRequestAsync = async (id: number) => {
  const resp = await deleteBrokerAPI(id);
  return resp;
};
