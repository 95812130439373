import {
  createStandardAPI,
  deleteStandardAPI,
  getAllStandardAPI,
  getSingleStandardAPI,
  updateStandardAPI,
} from 'services/standard';
import {
  AllStandardType,
  CreateStandardType,
  EditStandardType,
  GetSingleStandardType,
  StandardTableType,
} from 'types/standard.types';

export const getAllStandardRequestAsync = async (): Promise<StandardTableType[]> => {
  const resp = await getAllStandardAPI();
  const filteredResp: StandardTableType[] = resp.data.data.map(
    (standard: AllStandardType): StandardTableType => ({
      standardID: standard.standardID,
      standardName: standard.standardName,
      standardDesc: standard.standardDesc,
    }),
  );
  return filteredResp;
};

export const createStandardRequestAsync = async (payload: CreateStandardType) => {
  const resp = await createStandardAPI(payload);
  return resp;
};

export const getSingleStandardRequestAsync = async (id: number): Promise<GetSingleStandardType> => {
  const resp = await getSingleStandardAPI(id);
  return resp.data;
};

export const updateStandardRequestAsync = async (id: number, payload: EditStandardType) => {
  const resp = await updateStandardAPI(id, payload);
  return resp;
};

export const deleteSingleStandardRequestAsync = async (id: number) => {
  const resp = await deleteStandardAPI(id);
  return resp;
};
