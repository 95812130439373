import config from 'config/appConfig';
import { CreateCountryType, EditCountryType } from 'types/country.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllCountryAPI = () => {
  const url = `${API_ENDPOINT}/country`;
  return API.get(url);
};

export const createCountryAPI = (body: CreateCountryType) => {
  const url = `${API_ENDPOINT}/country`;
  return API.post(url, body);
};

export const getSingleCountryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/country/${id}`;
  return API.get(url);
};

export const updateCountryAPI = (id: number, payload: EditCountryType) => {
  const url = `${API_ENDPOINT}/country/${id}`;
  return API.put(url, payload);
};

export const deleteCountryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/country/${id}`;
  return API.deleteResource(url);
};
