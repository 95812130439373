import {
  createInquirySourceAPI,
  deleteInquirySourceAPI,
  getAllInquirySourceAPI,
  getSingleInquirySourceAPI,
  updateInquirySourceAPI,
} from 'services/inquirySource';
import {
  AllInquirySourceType,
  CreateInquirySourceType,
  EditInquirySourceType,
  GetSingleInquirySourceType,
  InquirySourceTableType,
} from 'types/inquirySource.types';

export const getAllInquirySourceRequestAsync = async (): Promise<InquirySourceTableType[]> => {
  const resp = await getAllInquirySourceAPI();
  const filteredResp: InquirySourceTableType[] = resp.data.data.map(
    (source: AllInquirySourceType): InquirySourceTableType => ({
      sourceId: source.id,
      sourceName: source.name,
      sourceStatus: source.state,
    }),
  );
  return filteredResp;
};

export const createInquirySourceRequestAsync = async (payload: CreateInquirySourceType) => {
  const resp = await createInquirySourceAPI(payload);
  return resp;
};

export const getSingleInquirySourceRequestAsync = async (
  id: number,
): Promise<GetSingleInquirySourceType> => {
  const resp = await getSingleInquirySourceAPI(id);
  return resp.data.data;
};

export const updateInquirySourceRequestAsync = async (
  id: number,
  payload: EditInquirySourceType,
) => {
  const resp = await updateInquirySourceAPI(id, payload);
  return resp;
};

export const deleteSingleInquirySourceRequestAsync = async (id: number) => {
  const resp = await deleteInquirySourceAPI(id);
  return resp;
};
