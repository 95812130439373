import {
  Avatar,
  AvatarBadge,
  Box,
  Center,
  Divider,
  EditableInput,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { IconPencil } from '@tabler/icons-react';
import { getStatusTypeColorCode } from 'helpers/colorCode.helper';
import React from 'react';
import { SingleUserDetailsType } from 'types/user.types';

interface ProfileDialogProps {
  isOpen: boolean;
  onClose: () => void;
  data: SingleUserDetailsType | undefined;
}

const ProfileDialog = ({ isOpen, onClose, data }: ProfileDialogProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg={'blackAlpha.500'} />
      <ModalContent
        maxW={'400px'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'lg'}
        overflow={'hidden'}
      >
        <ModalHeader textAlign={'center'}>
          <Text textDecoration={'underline'} textUnderlineOffset={'8px'} fontSize={'2xl'}>
            Profile Details
          </Text>
        </ModalHeader>
        <ModalCloseButton top={5} />
        <ModalBody>
          <Center py={6} display={'flex'} flexDirection={'column'} gap={4}>
            <Avatar
              size="2xl"
              name={`${data?.firstName} ${data?.lastName}`}
              showBorder={true}
              sx={{ border: '4px solid', borderColor: 'blue.500' }}
            ></Avatar>
            <Heading fontSize={'2xl'} fontFamily={'body'} textAlign={'center'}>
              {data?.firstName} {data?.lastName}
            </Heading>
            <Box bg={'gray.200'} px={6} py={4} width={'100%'} rounded={'xl'}>
              <Flex gap="2" width={'100%'} direction={'column'}>
                <Flex align={'center'} gap={1}>
                  <Text as="b">Username :</Text>
                  <Text>{data?.username}</Text>
                </Flex>
                <Divider borderColor={'gray.400'} />
                <Flex align={'center'} gap={1}>
                  <Text as="b">Mobile No. :</Text>
                  <Text>{data?.mobile || '-'}</Text>
                </Flex>
                <Divider borderColor={'gray.400'} />
                <Flex align={'center'} gap={1}>
                  <Text as="b">Email :</Text>
                  <Text>{data?.email || '-'}</Text>
                </Flex>
                <Divider borderColor={'gray.400'} />
                <Flex align={'center'} gap={1}>
                  <Text as="b">Status :</Text>
                  <Tag
                    backgroundColor={getStatusTypeColorCode(data?.isActive ? 'Active' : 'InActive')}
                  >
                    {data?.isActive ? 'Active' : 'InActive'}
                  </Tag>
                </Flex>
              </Flex>
            </Box>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProfileDialog;
