import {
  createMakeAPI,
  deleteMakeAPI,
  getAllMakeAPI,
  getSingleMakeAPI,
  updateMakeAPI,
} from 'services/make';
import {
  AllMakeType,
  CreateMakeType,
  EditMakeType,
  GetSingleMakeType,
  MakeTableType,
} from 'types/make.types';

export const getAllMakeRequestAsync = async (): Promise<MakeTableType[]> => {
  const resp = await getAllMakeAPI();
  const filteredResp: MakeTableType[] = resp.data.data.map(
    (make: AllMakeType): MakeTableType => ({
      makeId: make.makeId,
      makeName: make.makeName,
    }),
  );
  return filteredResp;
};

export const createMakeRequestAsync = async (payload: CreateMakeType) => {
  const resp = await createMakeAPI(payload);
  return resp;
};

export const getSingleMakeRequestAsync = async (id: number): Promise<GetSingleMakeType> => {
  const resp = await getSingleMakeAPI(id);
  return resp.data.data;
};

export const updateMakeRequestAsync = async (id: number, payload: EditMakeType) => {
  const resp = await updateMakeAPI(id, payload);
  return resp;
};

export const deleteSingleMakeRequestAsync = async (id: number) => {
  const resp = await deleteMakeAPI(id);
  return resp;
};
