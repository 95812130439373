import {
  createCustomerAPI,
  deleteCustomerAPI,
  getAllCustomerAPI,
  getSingleCustomerAPI,
  updateCustomerAPI,
} from 'services/customer';
import { PaginatedResponseType } from 'types/common.types';
import { CreateCustomerType, EditCustomerType, GetSingleCustomerType } from 'types/customer.types';

export const getAllCustomerRequestAsync = async (
  params: URLSearchParams,
): Promise<PaginatedResponseType> => {
  const resp = await getAllCustomerAPI(params);

  return resp.data;
};

export const getSingleCustomerRequestAsync = async (id: number): Promise<GetSingleCustomerType> => {
  const resp = await getSingleCustomerAPI(id);
  return resp.data;
};

export const createCustomerRequestAsync = async (payload: CreateCustomerType) => {
  const resp = await createCustomerAPI(payload);
  return resp;
};

export const updateCustomerRequestAsync = async (id: number, payload: EditCustomerType) => {
  const resp = await updateCustomerAPI(id, payload);
  return resp;
};

export const deleteSingleCustomerRequestAsync = async (id: number) => {
  const resp = await deleteCustomerAPI(id);
  return resp;
};
