import { Heading, Table, TableCaption, Tbody, Td, Th, Tr } from '@chakra-ui/react';
import React from 'react';

type TableData = {
  dataTitle: string;
  dataValue: string | number | React.ReactNode | undefined;
};

type DetailsTableProps = {
  tableCaption?: string;
  tableData: TableData[];
  variant?: string;
};

const DetailsTable: React.FC<DetailsTableProps> = ({
  tableCaption,
  tableData,
  variant = 'customStriped',
}) => {
  return (
    <Table size={'lg'} variant={variant}>
      {tableCaption && (
        <TableCaption placement="top" borderBottom={'4px solid'} borderColor={'gray.200'}>
          <Heading size={'md'}>{tableCaption} </Heading>
        </TableCaption>
      )}
      <Tbody>
        {tableData.map((i, idx) => (
          <Tr key={i.dataTitle + '_th_' + idx}>
            <Td borderRight={'2px solid'} borderColor={'gray.200'} width={'50%'}>
              <Heading size="sm">{i.dataTitle}</Heading>
            </Td>
            <Td width={'50%'}> {i.dataValue || '-'}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default DetailsTable;
