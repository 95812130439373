export interface MenuAccessItem {
  createUpdate: boolean;
  delete: boolean;
  id: number;
  name: string;
  view: boolean;
}

export const viewAccessCheck = (moduleName: string, menuAccess: MenuAccessItem[]) => {
  const menuItem = menuAccess?.find((item) => item.name === moduleName);
  return menuItem?.view ? true : false;
};

export const createAccessCheck = (moduleName: string, menuAccess: MenuAccessItem[]) => {
  const menuItem = menuAccess?.find((item) => item.name === moduleName);
  return menuItem?.createUpdate ? true : false;
};

export const deleteAccessCheck = (moduleName: string, menuAccess: MenuAccessItem[]) => {
  const menuItem = menuAccess?.find((item) => item.name === moduleName);
  return menuItem?.delete ? true : false;
};
