import React, { useContext } from 'react';
import { IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { IconEye, IconEdit, IconTrash } from '@tabler/icons-react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import DeleteDialog from 'components/common/DeleteDialog';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'config/eunm';
import { UserDetails } from 'App';
import {
  MenuAccessItem,
  createAccessCheck,
  deleteAccessCheck,
  viewAccessCheck,
} from 'helpers/accessCheck.helper';

const TestingTableButton = ({ testingId }: { testingId: number }) => {
  const navigate = useNavigate();
  const deleteDisclosure = useDisclosure();
  const { user, setUser } = useContext(UserDetails);
  return (
    <>
      <Menu
        modifiers={[
          {
            name: 'flip',
            options: {
              padding: {
                top: 60,
              },
            },
          },
        ]}
      >
        <MenuButton
          as={IconButton}
          aria-label="Action-button"
          icon={<ChevronDownIcon />}
          mr={2}
          colorScheme="green"
          size={'sm'}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<IconEye stroke={1} />}
            isDisabled={!viewAccessCheck('Master', user?.menuAccess as MenuAccessItem[])}
            onClick={() => navigate(APP_ROUTES.CONFIG_VIEW_TESTING, { state: { id: testingId } })}
          >
            View
          </MenuItem>
          <MenuItem
            icon={<IconEdit stroke={1} />}
            isDisabled={!createAccessCheck('Master', user?.menuAccess as MenuAccessItem[])}
            onClick={() => navigate(APP_ROUTES.CONFIG_EDIT_TESTING, { state: { id: testingId } })}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<IconTrash stroke={1} />}
            isDisabled={!deleteAccessCheck('Master', user?.menuAccess as MenuAccessItem[])}
            onClick={deleteDisclosure.onOpen}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
      <DeleteDialog
        isOpen={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
        dialogName="Testing"
        dialogFor="labTesting"
        dataId={testingId}
      />
    </>
  );
};

export default TestingTableButton;
