import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  field: {
    fontSize: '14px',
    pl: '10px',
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
    _placeholder: { color: 'gray.300', fontSize: '14px' },
    width: '100%',
  },
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
});
