import config from 'config/appConfig';

import { CreateMakeType, EditMakeType } from 'types/make.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllMakeAPI = () => {
  const url = `${API_ENDPOINT}/make`;
  return API.get(url);
};

export const createMakeAPI = (body: CreateMakeType) => {
  const url = `${API_ENDPOINT}/make`;
  return API.post(url, body);
};

export const getSingleMakeAPI = (id: number) => {
  const url = `${API_ENDPOINT}/make/${id}`;
  return API.get(url);
};

export const updateMakeAPI = (id: number, payload: EditMakeType) => {
  const url = `${API_ENDPOINT}/make/${id}`;
  return API.put(url, payload);
};

export const deleteMakeAPI = (id: number) => {
  const url = `${API_ENDPOINT}/make/${id}`;
  return API.deleteResource(url);
};
