import {
  createProcessAPI,
  deleteProcessAPI,
  getAllProcessAPI,
  getSingleProcessAPI,
  updateProcessAPI,
} from 'services/process';
import {
  AllProcessType,
  CreateProcessType,
  EditProcessType,
  GetSingleProcessType,
  ProcessTableType,
} from 'types/process.types';

export const getAllProcessRequestAsync = async (): Promise<ProcessTableType[]> => {
  const resp = await getAllProcessAPI();
  const filteredResp: ProcessTableType[] = resp.data.data.map(
    (process: AllProcessType): ProcessTableType => ({
      processId: process.processId,
      processName: process.processName,
    }),
  );
  return filteredResp;
};

export const createProcessRequestAsync = async (payload: CreateProcessType) => {
  const resp = await createProcessAPI(payload);
  return resp;
};

export const getSingleProcessRequestAsync = async (id: number): Promise<GetSingleProcessType> => {
  const resp = await getSingleProcessAPI(id);
  return resp.data.data;
};

export const updateProcessRequestAsync = async (id: number, payload: EditProcessType) => {
  const resp = await updateProcessAPI(id, payload);
  return resp;
};

export const deleteSingleProcessRequestAsync = async (id: number) => {
  const resp = await deleteProcessAPI(id);
  return resp;
};
