import config from 'config/appConfig';
import { CreateInquirySourceType, EditInquirySourceType } from 'types/inquirySource.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllInquirySourceAPI = () => {
  const url = `${API_ENDPOINT}/inquirypage`;
  return API.get(url);
};

export const createInquirySourceAPI = (body: CreateInquirySourceType) => {
  const url = `${API_ENDPOINT}/inquirypage`;
  return API.post(url, body);
};

export const getSingleInquirySourceAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inquirypage/${id}`;
  return API.get(url);
};

export const updateInquirySourceAPI = (id: number, payload: EditInquirySourceType) => {
  const url = `${API_ENDPOINT}/inquirypage/${id}`;
  return API.put(url, payload);
};

export const deleteInquirySourceAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inquirypage/${id}`;
  return API.deleteResource(url);
};
