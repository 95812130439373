import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
} from '@chakra-ui/react';
import { SectionHeading } from 'components/common/Headings';
import { ReactSelect } from 'components/common/ReactSelect';
import useCustomToast from 'hooks/toast';
import { useFormContext, Controller } from 'react-hook-form';
import { LaboratoryHookFormType } from 'types/laboratory.types';
import { cityFilterRequestAsync } from 'controller/filter.controller';
import { CityFilterType } from 'types/filter.types';
const BasicDetails = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<LaboratoryHookFormType>();
  const [cityFilter, setCityFilter] = useState<CityFilterType[]>();
  const showToast = useCustomToast();

  const fetchFilterData = async () => {
    try {
      const cities = await cityFilterRequestAsync();

      setCityFilter(cities);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  useEffect(() => {
    fetchFilterData();
  }, []);

  return (
    <Box width={'100%'}>
      <Card variant={'content'} borderTop={'4px solid'} borderColor={'componentBorderColor.basic'}>
        <CardHeader mb={4} px={6} py={2} display={'flex'} alignItems={'center'}>
          <SectionHeading props={{ fontWeight: 'semibold', fontSize: '2xl' }}>
            Basic Details
          </SectionHeading>
        </CardHeader>
        <CardBody>
          <SimpleGrid columns={[1, null, 2]} spacing={10}>
            <Flex direction={'column'} width={'100%'} gap={4}>
              <FormControl isInvalid={!!errors.laboratoryName}>
                <FormLabel>
                  Laboratory Name : <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Box w={'100%'} background={'white'} rounded={'lg'}>
                  <Input
                    placeholder="Laboratory Name ..."
                    width={'100%'}
                    {...register('laboratoryName')}
                  />
                </Box>
                <FormErrorMessage>{errors.laboratoryName?.message} </FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex direction={'column'} width={'100%'} gap={4}>
              <FormControl isInvalid={!!errors.email}>
                <FormLabel>
                  Email : <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Box w={'100%'} background={'white'} rounded={'lg'}>
                  <Input placeholder=" Email Id..." width={'100%'} {...register('email')} />
                </Box>
                <FormErrorMessage>{errors.email?.message} </FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex direction={'column'} width={'100%'} gap={4}>
              <FormControl isInvalid={!!errors.officeAddress1}>
                <FormLabel>Office Name :</FormLabel>
                <Box w={'100%'} background={'white'} rounded={'lg'}>
                  <Input
                    placeholder="Office Name..."
                    width={'100%'}
                    {...register('officeAddress1')}
                  />
                </Box>
                <FormErrorMessage>{errors.officeAddress1?.message} </FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex direction={'column'} width={'100%'} gap={4}>
              <FormControl isInvalid={!!errors.officeAddress2}>
                <FormLabel>Building :</FormLabel>
                <Box w={'100%'} background={'white'} rounded={'lg'}>
                  <Input placeholder="Building..." width={'100%'} {...register('officeAddress2')} />
                </Box>
                <FormErrorMessage>{errors.officeAddress2?.message} </FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex direction={'column'} width={'100%'} gap={4}>
              <FormControl isInvalid={!!errors.officeAddress3}>
                <FormLabel> Area :</FormLabel>
                <Box w={'100%'} background={'white'} rounded={'lg'}>
                  <Input placeholder=" Area..." width={'100%'} {...register('officeAddress3')} />
                </Box>
                <FormErrorMessage>{errors.officeAddress3?.message} </FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex direction={'column'} width={'100%'} gap={4}>
              <Controller
                name="officeCity"
                control={control}
                render={({ field }) => (
                  <FormControl isInvalid={!!errors.officeCity}>
                    <FormLabel>
                      City : <span style={{ color: 'red' }}>*</span>
                    </FormLabel>
                    <Box w={'100%'} background={'white'} rounded={'lg'}>
                      <ReactSelect
                        isMulti={false}
                        options={cityFilter}
                        isSearchable={true}
                        isClearable={false}
                        placeholder={'Select City...'}
                        getOptionLabel={(option) => option.cityName}
                        getOptionValue={(option) => option.cityName}
                        fields={field}
                      />
                    </Box>
                    <FormErrorMessage>
                      {errors.officeCity?.message || errors.officeCity?.cityId?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </Flex>
            <Flex direction={'column'} width={'100%'} gap={4}>
              <FormControl isInvalid={!!errors.officePin}>
                <FormLabel> Pincode :</FormLabel>
                <Box w={'100%'} background={'white'} rounded={'lg'}>
                  <Input placeholder=" Pincode..." width={'100%'} {...register('officePin')} />
                </Box>
                <FormErrorMessage>{errors.officePin?.message} </FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex direction={'column'} width={'100%'} gap={4}>
              <FormControl isInvalid={!!errors.mobile}>
                <FormLabel>
                  Office Phone : <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Box w={'100%'} background={'white'} rounded={'lg'}>
                  <Input placeholder="Mobile..." width={'100%'} {...register('mobile')} />
                </Box>
                <FormErrorMessage>{errors.mobile?.message} </FormErrorMessage>
              </FormControl>
            </Flex>
          </SimpleGrid>
        </CardBody>
      </Card>
    </Box>
  );
};

export default BasicDetails;
