import config from 'config/appConfig';
import { CreateGradeType, EditGradeType } from 'types/grade.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getSingleGradeAPI = (id: number) => {
  const url = `${API_ENDPOINT}/grade/${id}`;
  return API.get(url);
};

export const getAllGradeAPI = () => {
  const url = `${API_ENDPOINT}/grade`;
  return API.get(url);
};

export const createGradeAPI = (body: CreateGradeType) => {
  const url = `${API_ENDPOINT}/grade`;
  return API.post(url, body);
};

export const updateGradeAPI = (id: number, payload: EditGradeType) => {
  const url = `${API_ENDPOINT}/grade/${id}`;
  return API.put(url, payload);
};

export const deleteGradeAPI = (id: number) => {
  const url = `${API_ENDPOINT}/grade/${id}`;
  return API.deleteResource(url);
};
