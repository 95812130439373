import { Box, Card, Flex, Image, Link, Text, VStack, chakra } from '@chakra-ui/react';
import React from 'react';
import MatrysysLogo from 'assets/Logos/Matrysys/Matrysys-Logo.png';

const Footer = () => {
  return (
    <chakra.footer paddingBottom={2} mt={'auto'}>
      <Card variant={'content'}>
        <VStack>
          <Flex align={'center'} gap={2}>
            <Text fontSize="10px" fontWeight={'semibold'}>
              Version
            </Text>
            <Text fontSize="10px">2.0.0</Text>
          </Flex>
          <Flex align={'center'} gap={2} flexDirection={{ base: 'column', md: 'row' }}>
            <Text
              fontSize="10px"
              fontWeight={'semibold'}
              borderRightWidth={{ base: '0px', md: '1px' }}
              borderRightColor={'gray.300'}
              paddingRight={{ base: '0px', md: '7px' }}
            >
              Copyright &copy; 2023-2025 CHAMPION STEELS
            </Text>

            <Flex align={'center'}>
              <Text fontSize="10px" fontWeight={'semibold'}>
                Built By
              </Text>
              <Link href="https://matrysys.com/" isExternal>
                <Image
                  height={'20px'}
                  width={'40px'}
                  mx={1}
                  objectPosition={'center'}
                  objectFit={'fill'}
                  src={MatrysysLogo}
                  alt="Champion Steel Logo"
                />
              </Link>
              <Text fontSize="10px"> . All rights reserved.</Text>
            </Flex>
          </Flex>
        </VStack>
      </Card>
    </chakra.footer>
  );
};

export default Footer;
