import {
  createCategoryAPI,
  deleteCategoryAPI,
  getAllCategoryAPI,
  getSingleCategoryAPI,
  updateCategoryAPI,
} from 'services/category';
import {
  AllCategoryType,
  CategoryTableType,
  CreateCategoryType,
  EditCategoryType,
  GetSingleCategoryType,
} from 'types/category.types';

export const getAllCategoryRequestAsync = async (): Promise<CategoryTableType[]> => {
  const resp = await getAllCategoryAPI();
  const filteredResp: CategoryTableType[] = resp.data.data.map(
    (category: AllCategoryType): CategoryTableType => ({
      categoryId: category.categoryId,
      categoryName: category.categoryName,
    }),
  );
  return filteredResp;
};

export const createCategoryRequestAsync = async (payload: CreateCategoryType) => {
  const resp = await createCategoryAPI(payload);
  return resp;
};

export const getSingleCategoryRequestAsync = async (id: number): Promise<GetSingleCategoryType> => {
  const resp = await getSingleCategoryAPI(id);
  return resp.data.data;
};

export const updateCategoryRequestAsync = async (id: number, payload: EditCategoryType) => {
  const resp = await updateCategoryAPI(id, payload);
  return resp;
};

export const deleteSingleCategoryRequestAsync = async (id: number) => {
  const resp = await deleteCategoryAPI(id);
  return resp;
};
