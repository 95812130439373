import {
  createLaboratoryAPI,
  deleteLaboratoryAPI,
  getAllLaboratoryAPI,
  getSingleLaboratoryAPI,
  updateLaboratoryAPI,
} from 'services/laboratory';
import {
  AllLaboratoryType,
  CreateLaboratoryType,
  EditLaboratoryType,
  GetSingleLaboratoryType,
  LaboratoryTableType,
} from 'types/laboratory.types';

export const getAllLaboratoryRequestAsync = async (): Promise<LaboratoryTableType[]> => {
  const resp = await getAllLaboratoryAPI();
  const filteredResp: LaboratoryTableType[] = resp.data.data.map(
    (laboratory: AllLaboratoryType): LaboratoryTableType => ({
      laboratoryId: laboratory.laboratoryId,
      laboratoryName: laboratory.laboratoryName,
      mobile: laboratory.mobile,
      cityName: laboratory.laboratoryNamewithCity,
      email: laboratory.email,
    }),
  );
  return filteredResp;
};

export const getSingleLaboratoryRequestAsync = async (
  id: number,
): Promise<GetSingleLaboratoryType> => {
  const resp = await getSingleLaboratoryAPI(id);
  return resp.data;
};

export const createLaboratoryRequestAsync = async (payload: CreateLaboratoryType) => {
  const resp = await createLaboratoryAPI(payload);
  return resp;
};

export const updateLaboratoryRequestAsync = async (id: number, payload: EditLaboratoryType) => {
  const resp = await updateLaboratoryAPI(id, payload);
  return resp;
};

export const deleteSingleLaboratoryRequestAsync = async (id: number) => {
  const resp = await deleteLaboratoryAPI(id);
  return resp;
};
