import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'config/eunm';

const PageNotFoundComp = () => {
  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate(APP_ROUTES.HOME_PAGE);
  };

  return (
    <Box textAlign="center" mt={48} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        fontSize={'9rem'}
        lineHeight={1}
        color={'mainColors.primary'}
      >
        404
      </Heading>
      <Text fontSize={'3.5rem'} fontWeight={100} mt={3} mb={2}>
        Page Not Found
      </Text>
      <Text opacity={'.5'} mb={6}>
        The page you&apos;re looking for does not seem to exist
      </Text>
      <Button variant="primary" colorScheme={'primary'} onClick={redirectToHome}>
        Go to Home
      </Button>
    </Box>
  );
};

export default PageNotFoundComp;
