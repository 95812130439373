import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  accordionAnatomy.keys,
);

const sideBarVariant = definePartsStyle({
  root: {
    display: 'flex',
    flexDirection: 'column',

    gap: 2,
  },
  container: {
    border: 'none !important',
  },
  button: {
    padding: 0,
    borderRadius: '10px',
    display: 'flex',

    color: 'white',

    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: 'gray.700',
      },
    },
    _focus: {
      boxShadow: 'none !important',
      background: 'inhert',
    },
  },
  panel: {
    pl: 4,
    pb: 0,
    pt: 2,
    gap: 4,
  },
  icon: {},
});

export const accordionTheme = defineMultiStyleConfig({
  variants: { sideBar: sideBarVariant },
});
