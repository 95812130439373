import {
  createSingleSellerPurchaseOrderAPI,
  deleteSellerPurchaseOrderAPI,
  downloadSellerPurchaseOrderAPI,
  getAllSellerPurchaseOrderAPI,
  getSingleSellerPurchaseOrderAPI,
  setSellerPurchaseOrderRevisedAPI,
  updateSellerPurchaseOrderAPI,
  updateSellerPurchaseOrderStatusAPI,
} from 'services/SPO';
import { PaginatedResponseType } from 'types/common.types';
import { CreateSPOType, EditSPOType } from 'types/SPO.types';
import { saveAs } from 'file-saver';
import { FormatDate } from 'helpers/date.helper';

export const getAllSellerPurchaseOrderRequestAsync = async (
  params: URLSearchParams,
): Promise<PaginatedResponseType> => {
  const resp = await getAllSellerPurchaseOrderAPI(params);
  return resp.data;
};

export const getSingleSellerPurchaseOrderRequestAsync = async (id: number): Promise<any> => {
  const resp = await getSingleSellerPurchaseOrderAPI(id);
  return resp.data;
};

export const updateSellerPurchaseOrderRequestAsync = async (payload: EditSPOType, id: number) => {
  const resp = await updateSellerPurchaseOrderAPI(payload, id);
  return resp;
};

export const createSellerPurchaseOrderRequestAsync = async (payload: CreateSPOType) => {
  const resp = await createSingleSellerPurchaseOrderAPI(payload);
  return resp;
};

export const deleteSellerPurchaseOrderRequestAsync = async (id: number) => {
  const resp = await deleteSellerPurchaseOrderAPI(id);
  return resp;
};

export const updateSellerPurchaseOrderStatusRequestAsync = async (
  id: number,
  params: URLSearchParams,
) => {
  const resp = await updateSellerPurchaseOrderStatusAPI(id, params);
  return resp;
};

export const setSellerPurchaseOrderRevisedRequestAsync = async (id: number) => {
  const resp = await setSellerPurchaseOrderRevisedAPI(id);
  return resp;
};

export const downloadSPORequestAsync = async ({
  cId,
  spoId,
  isRevised,
  customerName,
}: {
  cId: number;
  spoId: number;
  isRevised: boolean;
  customerName?: string;
}) => {
  const resp = await downloadSellerPurchaseOrderAPI(cId, spoId);
  const file = new Blob([resp.data], { type: 'application/pdf' });
  saveAs(
    file,
    `${
      isRevised === true ? 'Rev -' : ''
    }Supplier_Purchaser_Order(CS_SPO-${spoId})_${customerName}_${FormatDate(new Date())}.pdf`,
  );
};
