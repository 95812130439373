import {
  createCityAPI,
  deleteCityAPI,
  getAllCityAPI,
  getSingleCityAPI,
  updateCityAPI,
} from 'services/city';
import {
  AllCityType,
  CityTableType,
  CreateCityType,
  EditCityType,
  GetSingleCityType,
} from 'types/city.types';

export const getAllCityRequestAsync = async (): Promise<CityTableType[]> => {
  const resp = await getAllCityAPI();
  const filteredResp: CityTableType[] = resp.data.data.map(
    (city: AllCityType): CityTableType => ({
      cityCode: city.cityCode,
      cityId: city.cityId,
      cityName: city.cityName,
      stateName: city.stateName,
    }),
  );
  return filteredResp;
};

export const createCityRequestAsync = async (payload: CreateCityType) => {
  const resp = await createCityAPI(payload);
  return resp;
};

export const getSingleCityRequestAsync = async (id: number): Promise<GetSingleCityType> => {
  const resp = await getSingleCityAPI(id);
  return resp.data.data;
};

export const updateCityRequestAsync = async (id: number, payload: EditCityType) => {
  const resp = await updateCityAPI(id, payload);
  return resp;
};

export const deleteSingleCityRequestAsync = async (id: number) => {
  const resp = await deleteCityAPI(id);
  return resp;
};
