import {
  createPostAPI,
  deletePostAPI,
  getAllPostAPI,
  getSinglePostAPI,
  updatePostAPI,
} from 'services/post';
import {
  AllPostType,
  CreatePostType,
  EditPostType,
  GetSinglePostType,
  PostTableType,
} from 'types/post.types';

export const getAllPostRequestAsync = async (): Promise<PostTableType[]> => {
  const resp = await getAllPostAPI();
  const filteredResp: PostTableType[] = resp.data.data.map(
    (post: AllPostType): PostTableType => ({
      postId: post.id,
      postName: post.name,
      postState: post.state,
    }),
  );
  return filteredResp;
};

export const createPostRequestAsync = async (payload: CreatePostType) => {
  const resp = await createPostAPI(payload);
  return resp;
};

export const getSinglePostRequestAsync = async (id: number): Promise<GetSinglePostType> => {
  const resp = await getSinglePostAPI(id);
  return resp.data.data;
};

export const updatePostRequestAsync = async (id: number, payload: EditPostType) => {
  const resp = await updatePostAPI(id, payload);
  return resp;
};

export const deleteSinglePostRequestAsync = async (id: number) => {
  const resp = await deletePostAPI(id);
  return resp;
};
