import React from 'react';
import { Center, Spinner, Text } from '@chakra-ui/react';
import { Wrapper } from 'theme/components/Wrappers/custom.Wrapper';

type Props = {
  error?: any;
  timedOut?: any;
  retry?: any;
};

const InitPageLoader = (props: Props) => {
  return (
    <Wrapper variant="pageLoader">
      <Center flexDirection={'column'} gap={2}>
        <Spinner size={'xl'} />
        <Text size={'xl'}>Loading....</Text>
      </Center>
    </Wrapper>
  );
};

export default InitPageLoader;
