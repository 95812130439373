import * as API from 'utils/api-helper';
import config from 'config/appConfig';
import { CreateQuotationType, EditQuotationType } from 'types/quotation.types';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllQuotationAPI = (params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/inquiryquotation/paginated?${params.toString()}`;
  return API.get(url);
};

export const getSingleQuotationAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inquiryquotation/${id}`;
  return API.get(url);
};

export const createSingleQuotationAPI = (body: CreateQuotationType) => {
  const url = `${API_ENDPOINT}/inquiryquotation`;
  return API.post(url, body);
};

export const updateQuotationAPI = (body: EditQuotationType) => {
  const url = `${API_ENDPOINT}/inquiryquotation`;
  return API.put(url, body);
};

export const deleteQuotationAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inquiryquotation/${id}`;
  return API.deleteResource(url);
};

export const transferToInquiryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inquiryquotation/reverse/${id}`;
  return API.post(url);
};

export const transferToPurchaseOrderAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inquiryquotation/reversePO/${id}`;
  return API.post(url);
};

export const updateQuotationStatusAPI = (id: number, params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/inquiryquotation/${id}/updatestatus?${params.toString()}`;
  return API.put(url);
};

export const setQuotationRevisedAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inquiryquotation/setrevised/${id}`;
  return API.post(url);
};

export const downloadQuotationAPI = (cId: number, inqId: number) => {
  const url = `${API_ENDPOINT}/inquiryquotation/pdf?companyId=${cId}&inqId=${inqId}`;
  const apiConfig = {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    transformResponse: [
      function (data: any) {
        // Do whatever you want to transform the data

        return data;
      },
    ],
  };
  return API.get(url, undefined, apiConfig);
};
