import config from 'config/appConfig';
import { CreateCategoryType, EditCategoryType } from 'types/category.types';
import { CreateGradeType, EditGradeType } from 'types/grade.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getSingleCategoryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/category/${id}`;
  return API.get(url);
};

export const getAllCategoryAPI = () => {
  const url = `${API_ENDPOINT}/category`;
  return API.get(url);
};

export const createCategoryAPI = (body: CreateCategoryType) => {
  const url = `${API_ENDPOINT}/category`;
  return API.post(url, body);
};

export const updateCategoryAPI = (id: number, payload: EditCategoryType) => {
  const url = `${API_ENDPOINT}/category/${id}`;
  return API.put(url, payload);
};

export const deleteCategoryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/category/${id}`;
  return API.deleteResource(url);
};
