import moment from 'moment';

export const FormatDate = (date: string | Date, format: string = 'DD-MM-YYYY') => {
  const formatedDate = moment(date).format(format);
  return formatedDate;
};

export const FormatDateForIST = (date: string | Date, format: string = 'DD-MM-YYYY') => {
  const formatedDate = moment(date, 'DD-MM-YYYY').format(format);
  return formatedDate;
};

export const shortDate = new Intl.DateTimeFormat('en-in', {
  dateStyle: 'short',
});

export const mediumTime = new Intl.DateTimeFormat('en-in', {
  timeStyle: 'short',
  dateStyle: 'short',
});

export const UtcToLocal = (date: string, format: string = 'DD-MM-YYYY') => {
  const parsedDateString = date.replace('[UTC]', '');
  const originalDate = moment(parsedDateString, moment.ISO_8601);
  console.log(originalDate);
  const localDate = originalDate.local();
  console.log('Local', originalDate.local());
  const formattedLocalDate = localDate.format(format);
  return formattedLocalDate;
};

export const formatNumber = (number: any) => {
  // Ensure the number is a valid integer
  const intValue = parseInt(number, 10);

  // Check if the number is less than 10 and add leading '0' if needed
  const formattedNumber = intValue < 10 ? '0' + intValue : intValue.toString();

  return formattedNumber;
};
