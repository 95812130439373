import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const stripedStyleVariant = defineStyle({
  table: {
    marginBottom: '15px',
    tableLayout: 'auto',
    width: '100%',
    border: '1px solid',
    borderColor: 'gray.200',
  },

  th: {
    px: '10px',
    py: '8px',
    fontWeight: 'semibold',
    color: 'white',
    backgroundColor: 'gray',
    whiteSpace: 'normal',
    borderBottom: '4px solid',
    borderRight: '1px solid',
    borderColor: 'gray.200',

    fontSize: 'sm',
    '&:last-child': {
      borderRight: '0px ',
    },
  },
  td: {
    px: '10px',
    py: '8px',
    fontSize: 'sm',
    whiteSpace: 'normal',
    borderBottom: '2px solid',
    borderRight: '2px solid',
    borderColor: 'gray.200',

    '&:last-child': {
      borderRight: '0px ',
    },
  },
  tbody: {
    tr: {
      _hover: {
        background: 'gray.300',
      },
      '&:nth-of-type(even)': {
        background: 'gray.50',
        _hover: {
          background: 'gray.300',
        },
      },
    },
  },
});

export const tableTheme = defineMultiStyleConfig({
  variants: {
    customStriped: stripedStyleVariant,
  },
});
