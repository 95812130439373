import React from 'react';
import { Box, Button, IconButton, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { DOTS, usePagination } from 'helpers/pagination.helper';

interface PaginationProps {
  totalCount: number;
  currentPage: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  siblingCount: number;
}

const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  currentPage,
  pageSize,
  onPageChange,
  siblingCount = 1,
}) => {
  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <Box display="flex" justifyContent="center" alignItems={'center'} gap={{ base: 0.5, lg: 2 }}>
      <IconButton
        icon={<ChevronLeftIcon />}
        onClick={onPrevious}
        isDisabled={currentPage === 1}
        aria-label="Previous Page"
        variant="ghost"
        size={{ base: 'sm', md: 'md', xl: 'lg' }}
      />
      {paginationRange.map((pageNumber: any, index: any) => {
        if (pageNumber === DOTS) {
          return (
            <Text mx={1} key={`${pageNumber}-${index}`}>
              ...
            </Text>
          );
        }
        return (
          <Button
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            fontWeight={'semibold'}
            color={currentPage === pageNumber ? 'buttonColor.secondary.main' : 'gray'}
            variant={currentPage === pageNumber ? 'solid' : 'ghost'}
            size={{ base: 'sm', md: 'md', xl: 'lg' }}
          >
            {pageNumber}
          </Button>
        );
      })}
      <IconButton
        icon={<ChevronRightIcon />}
        onClick={onNext}
        isDisabled={currentPage === lastPage}
        aria-label="Next Page"
        variant="ghost"
        size={{ base: 'sm', md: 'md', xl: 'lg' }}
      />
    </Box>
  );

  /* const handlePageChange = (page: number) => {
    onPageChange(page);
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageButtons = () => {
    const buttons = [];
    const maxButtonsToShow = Math.min(maxButtons, totalPages);
    if (totalPages <= maxButtonsToShow) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <Button
            key={i}
            onClick={() => handlePageChange(i)}
            colorScheme={currentPage === i ? 'blue' : 'gray'}
            variant={currentPage === i ? 'solid' : 'outline'}
          >
            {i}
          </Button>,
        );
      }
    } else {
      const halfButtons = Math.floor(maxButtonsToShow / 2); // 5
      const leftButtons = Math.min(currentPage, halfButtons); // 1, 2,3,4 , 5
      const rightButtons = Math.min(totalPages - currentPage, halfButtons); // 9,8
      for (let i = 1; i <= leftButtons; i++) {
        buttons.push(
          <Button
            key={i}
            onClick={() => handlePageChange(i)}
            colorScheme={currentPage === i ? 'blue' : 'gray'}
            variant={currentPage === i ? 'solid' : 'outline'}
          >
            {i}
          </Button>,
        );
      }
      if (leftButtons < currentPage - halfButtons) {
        buttons.push(
          <Button key="leftEllipsis" isDisabled variant="ghost">
            ...
          </Button>,
        );
      }

      for (let i = totalPages - rightButtons + 1; i <= totalPages; i++) {
        buttons.push(
          <Button
            key={i}
            onClick={() => handlePageChange(i)}
            colorScheme={currentPage === i ? 'blue' : 'gray'}
            variant={currentPage === i ? 'solid' : 'outline'}
          >
            {i}
          </Button>,
        );
      }

      if (rightButtons < totalPages - currentPage + halfButtons) {
        buttons.push(
          <Button key="rightEllipsis" isDisabled variant="ghost">
            ...
          </Button>,
        );
      }
    }
    return buttons;
  };

  return (
    <Box display="flex" justifyContent="center" mt={4}>
      <IconButton
        icon={<ChevronLeftIcon />}
        onClick={() => handlePageChange(currentPage - 1)}
        isDisabled={currentPage === 1}
        aria-label="Previous Page"
        variant="ghost"
      />
      <ButtonGroup>{renderPageButtons()}</ButtonGroup>
      <IconButton
        icon={<ChevronRightIcon />}
        onClick={() => handlePageChange(currentPage + 1)}
        isDisabled={currentPage === totalPages}
        aria-label="Next Page"
        variant="ghost"
      />
    </Box>
  ); */
};

export default Pagination;
