import {
  createCountryAPI,
  deleteCountryAPI,
  getAllCountryAPI,
  getSingleCountryAPI,
  updateCountryAPI,
} from 'services/country';
import {
  AllCountryType,
  CountryTableType,
  CreateCountryType,
  EditCountryType,
  GetSingleCountryType,
} from 'types/country.types';

export const getAllCountryRequestAsync = async (): Promise<CountryTableType[]> => {
  const resp = await getAllCountryAPI();
  const filteredResp: CountryTableType[] = resp.data.data.map(
    (country: AllCountryType): CountryTableType => ({
      countryId: country.countryId,
      countryName: country.countryName,
      countryCode: country.countryCode,
    }),
  );
  return filteredResp;
};

export const createCountryRequestAsync = async (payload: CreateCountryType) => {
  const resp = await createCountryAPI(payload);
  return resp;
};

export const getSingleCountryRequestAsync = async (id: number): Promise<GetSingleCountryType> => {
  const resp = await getSingleCountryAPI(id);
  return resp.data.data;
};

export const updateCountryRequestAsync = async (id: number, payload: EditCountryType) => {
  const resp = await updateCountryAPI(id, payload);
  return resp;
};

export const deleteSingleCountryRequestAsync = async (id: number) => {
  const resp = await deleteCountryAPI(id);
  return resp;
};
