import { Heading } from '@chakra-ui/react';
import React from 'react';

interface HeadingType {
  children: string;
  props?: any;
}

export const PageHeading = ({ children, props }: HeadingType) => {
  return (
    <Heading as={'h1'} size={'lg'} fontWeight={'500'} {...props}>
      {children}
    </Heading>
  );
};

export const SectionHeading = ({ children, props }: HeadingType) => {
  return (
    <Heading
      as={'h3'}
      size={'md'}
      fontWeight={'300'}
      borderBottom={'1px solid'}
      color={'gray.500'}
      borderColor={'gray.500'}
      {...props}
    >
      {children}
    </Heading>
  );
};
