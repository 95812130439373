import config from 'config/appConfig';
import { CreateVendorType, EditVendorType } from 'types/vendor.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllVendorAPI = () => {
  const url = `${API_ENDPOINT}/vendor`;
  return API.get(url);
};

export const createVendorAPI = (body: CreateVendorType) => {
  const url = `${API_ENDPOINT}/vendor`;
  return API.post(url, body);
};

export const getSingleVendorAPI = (id: number) => {
  const url = `${API_ENDPOINT}/vendor/${id}`;
  return API.get(url);
};

export const updateVendorAPI = (id: number, payload: EditVendorType) => {
  const url = `${API_ENDPOINT}/vendor/${id}`;
  return API.put(url, payload);
};

export const deleteVendorAPI = (id: number) => {
  const url = `${API_ENDPOINT}/vendor/${id}`;
  return API.deleteResource(url);
};
