import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Card, Flex, Skeleton, Spacer, useDisclosure } from '@chakra-ui/react';
import { PageHeading } from 'components/common/Headings';
import BreadCrumbLayout from 'components/common/BreadCrumb';
import { APP_ROUTES } from 'config/eunm';
import { useLocation, useNavigate } from 'react-router-dom';
import useCustomToast from 'hooks/toast';

import DetailsTable from 'components/Table/DetailsTable';
import DeleteDialog from 'components/common/DeleteDialog';
import { GetSingleTestingType } from 'types/testing.types';
import { getSingleLabTestRequestAsync } from '../controller';
import {
  MenuAccessItem,
  createAccessCheck,
  deleteAccessCheck,
  viewAccessCheck,
} from 'helpers/accessCheck.helper';
import { UserDetails } from 'App';
import AccessDeniedPage from 'components/AccessDeniedPage';
import { AxiosError } from 'axios';

const ViewTesting = () => {
  const [singleTesting, setSingleTesting] = useState<GetSingleTestingType>();
  const [viewTestingLoader, setViewTestingLoader] = useState<boolean>(true);
  const navigate = useNavigate();
  const { state } = useLocation();
  const deleteDisclosure = useDisclosure();
  const showToast = useCustomToast();
  const [viewAccessDenied, setViewAccessDenied] = useState<boolean>(false);

  const { user, setUser } = useContext(UserDetails);

  useEffect(() => {
    const access = viewAccessCheck('Master', user?.menuAccess as MenuAccessItem[]);
    if (!access) {
      setViewAccessDenied(true);
    }
    if (access) {
      setViewAccessDenied(false);
    }
  }, [user]);

  useEffect(() => {
    if (!state) {
      navigate(-1);
    }
  }, []);

  const fetchSingleTesting = async () => {
    try {
      const testingDetails = await getSingleLabTestRequestAsync(state.id);
      setSingleTesting(testingDetails);
      setViewTestingLoader(false);
    } catch (error: any) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setViewAccessDenied(true);
      } else
        showToast({
          title: err.message,
          description: 'Something went wrong!!',
          status: 'error',
        });
    }
  };
  useEffect(() => {
    fetchSingleTesting();
  }, []);

  return (
    <Box width={'100%'} display={'flex'} gap={2} flexDirection={'column'}>
      <Box width={'100%'}>
        <Card
          variant={'headerBg'}
          display={'flex'}
          flexDirection={{ md: 'row', base: 'column' }}
          gap={5}
          alignItems={{ md: 'center', base: 'start' }}
        >
          <Box>
            <PageHeading props={{ pb: 2 }}>View Test</PageHeading>
            <BreadCrumbLayout
              options={[
                { name: 'Dashboard', link: `${APP_ROUTES.HOME_PAGE}` },
                { name: 'Others' },
                {
                  name: 'Testing',
                  link: `${APP_ROUTES.ROOT_CONFIG}/${APP_ROUTES.CONFIG_TESTING_LIST}`,
                },
                { name: 'View Test', isActivePage: true },
              ]}
            />
          </Box>
          <Spacer />
          <Flex gap={4}>
            <Button
              onClick={() =>
                navigate(
                  `${APP_ROUTES.ROOT_CONFIG}/${APP_ROUTES.CONFIG_TESTING_LIST}/${APP_ROUTES.CONFIG_EDIT_TESTING}`,
                  { state: { id: singleTesting?.testingTypeId } },
                )
              }
              variant="primary"
              colorScheme="create"
              size={'sm'}
              isDisabled={!createAccessCheck('Master', user?.menuAccess as MenuAccessItem[])}
            >
              Update
            </Button>
            <Button
              variant="primary"
              size={'sm'}
              colorScheme="secondary"
              onClick={deleteDisclosure.onOpen}
              isDisabled={!deleteAccessCheck('Master', user?.menuAccess as MenuAccessItem[])}
            >
              Delete
            </Button>
          </Flex>
        </Card>
      </Box>
      {viewAccessDenied ? (
        <AccessDeniedPage />
      ) : viewTestingLoader ? (
        <Skeleton height="400px" />
      ) : (
        <>
          <Flex width={'100%'}>
            <Card
              variant={'content'}
              borderTop={'4px solid'}
              borderColor={'componentBorderColor.basic'}
              width={'100%'}
            >
              <DetailsTable
                tableCaption="Test Details"
                tableData={[
                  {
                    dataTitle: 'Test Name',
                    dataValue: singleTesting?.testingTypeName,
                  },
                  {
                    dataTitle: 'Test UID',
                    dataValue: singleTesting?.testingTypeUId,
                  },
                ]}
              />
            </Card>
          </Flex>
        </>
      )}
      {state && (
        <DeleteDialog
          isOpen={deleteDisclosure.isOpen}
          onClose={deleteDisclosure.onClose}
          dialogFor="labTesting"
          dataId={state.id}
          dialogName="Test"
        />
      )}
    </Box>
  );
};

export default ViewTesting;
