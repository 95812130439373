import {
  createUserAPI,
  deleteUserAPI,
  getAllUserAPI,
  getSingleUserAPI,
  updateUserAPI,
} from 'services/user';
import {
  AllUserType,
  CreateUserType,
  EditUserType,
  SingleUserDetailsType,
  UserTableType,
} from 'types/user.types';

export const getAllUserRequestAsync = async (): Promise<UserTableType[]> => {
  const resp = await getAllUserAPI();
  const filteredResp: UserTableType[] = resp.data.data.map(
    (user: AllUserType): UserTableType => ({
      userId: user.userId,
      username: user.username,
      mobile: user.mobile,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    }),
  );
  return filteredResp;
};

export const getSingleUserRequestAsync = async (id: number): Promise<SingleUserDetailsType> => {
  const resp = await getSingleUserAPI(id);
  return resp.data.data;
};

export const createUserRequestAsync = async (payload: CreateUserType) => {
  const resp = await createUserAPI(payload);
  return resp;
};

export const updateUserRequestAsync = async (id: number, payload: EditUserType) => {
  const resp = await updateUserAPI(id, payload);
  return resp;
};

export const deleteSingleUserRequestAsync = async (id: number) => {
  const resp = await deleteUserAPI(id);
  return resp;
};
