import config from 'config/appConfig';
import { CreateBrokerType, EditBrokerType } from 'types/broker.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getSingleBrokerAPI = (id: number) => {
  const url = `${API_ENDPOINT}/broker/${id}`;
  return API.get(url);
};

export const getAllBrokerAPI = () => {
  const url = `${API_ENDPOINT}/broker`;
  return API.get(url);
};

export const createBrokerAPI = (body: CreateBrokerType) => {
  const url = `${API_ENDPOINT}/broker`;
  return API.post(url, body);
};

export const updateBrokerAPI = (id: number, payload: EditBrokerType) => {
  const url = `${API_ENDPOINT}/broker/${id}`;
  return API.put(url, payload);
};

export const deleteBrokerAPI = (id: number) => {
  const url = `${API_ENDPOINT}/broker/${id}`;
  return API.deleteResource(url);
};
