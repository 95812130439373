import {
  createSingleLabReportAPI,
  deleteLabReportAPI,
  downloadLabReportAPI,
  getAllLabReportAPI,
  getSingleLabReportAPI,
  updateLabReportAPI,
  updateLabReportStatusAPI,
} from 'services/labReport';
import { PaginatedResponseType } from 'types/common.types';
import { CreateLabReportType, EditLabReportType, SingleLabReportType } from 'types/labReport.types';
import { saveAs } from 'file-saver';
import { FormatDate } from 'helpers/date.helper';

export const getAllLabReportRequestAsync = async (
  params: URLSearchParams,
): Promise<PaginatedResponseType> => {
  const resp = await getAllLabReportAPI(params);
  return resp.data;
};

export const getSingleLabReportRequestAsync = async (id: number): Promise<SingleLabReportType> => {
  const resp = await getSingleLabReportAPI(id);
  return resp.data.data;
};

export const createLabReportRequestAsync = async (payload: CreateLabReportType) => {
  const resp = await createSingleLabReportAPI(payload);
  return resp;
};

export const updateLabReportRequestAsync = async (id: number, payload: EditLabReportType) => {
  const resp = await updateLabReportAPI(id, payload);
  return resp;
};

export const deleteSingleLabReportRequestAsync = async (id: number) => {
  const resp = await deleteLabReportAPI(id);
  return resp;
};

export const downloadLabReportRequestAsync = async (cId: number, labRepId: number) => {
  const resp = await downloadLabReportAPI(cId, labRepId);
  const file = new Blob([resp.data], { type: 'application/pdf' });
  saveAs(file, `Lab Report-${labRepId}_${FormatDate(new Date())}.pdf`);
  /* const fileURL = URL.createObjectURL(file);
  window.open(fileURL, `Seller Purchase Order-SPO${spoId}.pdf`); */
};

export const updateLabReportStatusRequestAsync = async (id: number, params: URLSearchParams) => {
  const resp = await updateLabReportStatusAPI(id, params);
  return resp;
};
