import config from 'config/appConfig';
import { CreateConditionType, EditConditionType } from 'types/condition.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllConditionAPI = () => {
  const url = `${API_ENDPOINT}/condition`;
  return API.get(url);
};

export const createConditionAPI = (body: CreateConditionType) => {
  const url = `${API_ENDPOINT}/condition`;
  return API.post(url, body);
};

export const getSingleConditionAPI = (id: number) => {
  const url = `${API_ENDPOINT}/condition/${id}`;
  return API.get(url);
};

export const updateConditionAPI = (id: number, payload: EditConditionType) => {
  const url = `${API_ENDPOINT}/condition/${id}`;
  return API.put(url, payload);
};

export const deleteConditionAPI = (id: number) => {
  const url = `${API_ENDPOINT}/condition/${id}`;
  return API.deleteResource(url);
};
