import { Box, Button, Card, Spacer } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import BreadCrumbLayout from 'components/common/BreadCrumb';

import { PageHeading } from 'components/common/Headings';
import { APP_ROUTES } from 'config/eunm';
import { useNavigate } from 'react-router-dom';
import useCustomToast from 'hooks/toast';
import { ColumnDef } from '@tanstack/react-table';
import { LaboratoryTableType } from 'types/laboratory.types';
import LaboratoryTableButton from './LaboratoryTableButton';
import MasterTable from 'components/Table/MasterTable';
import { getAllLaboratoryRequestAsync } from './controller';
import { UserDetails } from 'App';
import { MenuAccessItem, createAccessCheck, viewAccessCheck } from 'helpers/accessCheck.helper';
import { AxiosError } from 'axios';
import AccessDeniedPage from 'components/AccessDeniedPage';

const MasterLaboratoryListPage = () => {
  const navigate = useNavigate();
  const showToast = useCustomToast();

  const [laboratoryList, setLaboratoryList] = useState<LaboratoryTableType[]>([]);
  const [laboratoryLoader, setLaboratoryLoader] = useState<boolean>(true);

  const [viewAccessDenied, setViewAccessDenied] = useState<boolean>(false);
  const { user, setUser } = useContext(UserDetails);

  useEffect(() => {
    const access = viewAccessCheck('Master', user?.menuAccess as MenuAccessItem[]);
    if (!access) {
      setViewAccessDenied(true);
    }
    if (access) {
      setViewAccessDenied(false);
    }
  }, [user]);

  const fetchAllLaboratory = async () => {
    try {
      const allLaboratory: LaboratoryTableType[] = await getAllLaboratoryRequestAsync();
      setLaboratoryList(allLaboratory);
      setLaboratoryLoader(false);
    } catch (error: any) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setViewAccessDenied(true);
      } else
        showToast({
          title: err.message,
          description: 'Something went wrong!!',
          status: 'error',
        });
    }
  };
  useEffect(() => {
    fetchAllLaboratory();
  }, []);

  const defaultColumns = React.useMemo<ColumnDef<LaboratoryTableType>[]>(
    () => [
      {
        id: 'actionButton',
        cell: (props) => <LaboratoryTableButton laboratoryId={props.row.original.laboratoryId} />,
      },
      {
        header: 'Name',
        accessorKey: 'laboratoryName',
        cell: (info) => info.getValue() || '-',
      },
      {
        header: 'Email',
        accessorKey: 'email',
        cell: (info) => info.getValue() || '-',
      },
      {
        header: 'Contact No.',
        accessorKey: 'mobile',
        cell: (info) => info.getValue() || '-',
      },
      {
        header: 'Office City',
        accessorKey: 'cityName',
        cell: (info) => info.getValue() || '-',
      },
    ],
    [],
  );

  const disabledColumns = {};
  return (
    <Box width={'100%'} display={'flex'} gap={2} flexDirection={'column'}>
      <Box width={'100%'}>
        <Card
          variant={'headerBg'}
          display={'flex'}
          flexDirection={{ md: 'row', base: 'column' }}
          gap={5}
          alignItems={{ md: 'center', base: 'start' }}
        >
          <Box>
            <PageHeading props={{ pb: 2 }}>Laboratory Master</PageHeading>
            <BreadCrumbLayout
              options={[
                { name: 'Dashboard', link: `${APP_ROUTES.HOME_PAGE}` },
                { name: 'Master' },
                { name: 'Laboratory Master', isActivePage: true },
              ]}
            />
          </Box>
          <Spacer />
          <Box display="flex" gap={4}>
            <Button
              onClick={() => navigate(APP_ROUTES.MASTER_NEW_LABORATORY)}
              variant="primary"
              colorScheme="create"
              isDisabled={!createAccessCheck('Master', user?.menuAccess as MenuAccessItem[])}
            >
              Create New Laboratory
            </Button>
          </Box>
        </Card>
      </Box>
      {viewAccessDenied ? (
        <AccessDeniedPage />
      ) : (
        <Box width={'100%'}>
          <MasterTable
            actualData={laboratoryList}
            columnDefs={defaultColumns}
            tableLoader={laboratoryLoader}
            disabledColumn={disabledColumns}
          />
        </Box>
      )}
    </Box>
  );
};

export default MasterLaboratoryListPage;
