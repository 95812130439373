import { deleteSingleCFTReportAPI, getAllCuttingForgingReportAPI } from 'services/cftReport';
import { PaginatedResponseType } from 'types/common.types';

export const getAllCuttingForgingReportRequestAsync = async (
  params: URLSearchParams,
): Promise<PaginatedResponseType> => {
  const resp = await getAllCuttingForgingReportAPI(params);
  return resp.data;
};

export const deleteSingleCFTReportRequestAsync = async (id: number) => {
  const resp = await deleteSingleCFTReportAPI(id);
  return resp;
};
