import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { sideBarConfig } from '../constant';

import LogoText from 'components/common/LogoText';
import SideMenuList from './SideMenuList';
import MatrysysLogo from 'assets/Logos/Matrysys/Matrysys-Logo.png';

interface MobileMenuProp {
  isOpen: boolean;
  onToggle: () => void;
  onClose: () => void;
}

const MobileMenu = ({ isOpen, onToggle, onClose }: MobileMenuProp) => {
  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent maxWidth={sideBarConfig.openWidth} marginTop="0px">
        <DrawerBody
          p={0}
          background={'gray.400'}
          sx={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Box p={2} height="64px" display={'flex'} alignItems={'center'}>
            <LogoText />
            <DrawerCloseButton position={'relative'} top={0} right={-3} />
          </Box>
          <SideMenuList isOpen={isOpen} />
        </DrawerBody>
        <DrawerFooter background={'gray.400'}>
          <Flex width="100%" justify={'center'} align={'center'} gap={2}>
            <Text fontStyle={'italic'} fontWeight={'semibold'}>
              Built by
            </Text>
            <Link href="https://matrysys.com/" isExternal>
              <Image
                height={'35px'}
                width={'90px'}
                objectPosition={'center'}
                objectFit={'fill'}
                src={MatrysysLogo}
                alt="Champion Steel Logo"
              />
            </Link>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default MobileMenu;
