import {
  createStateAPI,
  deleteStateAPI,
  getAllStateAPI,
  getSingleStateAPI,
  updateStateAPI,
} from 'services/state';
import {
  AllStateType,
  CreateStateType,
  EditStateType,
  GetSingleStateType,
  StateTableType,
} from 'types/state.types';

export const getAllStateRequestAsync = async (): Promise<StateTableType[]> => {
  const resp = await getAllStateAPI();
  const filteredResp: StateTableType[] = resp.data.data.map(
    (state: AllStateType): StateTableType => ({
      stateCode: state.stateCode,
      stateId: state.stateId,
      stateName: state.stateName,
      countryName: state.countryName,
    }),
  );
  return filteredResp;
};

export const createStateRequestAsync = async (payload: CreateStateType) => {
  const resp = await createStateAPI(payload);
  return resp;
};

export const getSingleStateRequestAsync = async (id: number): Promise<GetSingleStateType> => {
  const resp = await getSingleStateAPI(id);
  return resp.data.data;
};

export const updateStateRequestAsync = async (id: number, payload: EditStateType) => {
  const resp = await updateStateAPI(id, payload);
  return resp;
};

export const deleteSingleStateRequestAsync = async (id: number) => {
  const resp = await deleteStateAPI(id);
  return resp;
};
