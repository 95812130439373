import config from 'config/appConfig';
import { CreatePurchaserType, EditPurchaserType } from 'types/purchaser.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllPurchaserAPI = (params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/purchaser/paginated?${params.toString()}`;
  return API.get(url);
};

export const getSinglePurchaserAPI = (id: number) => {
  const url = `${API_ENDPOINT}/purchaser/${id}`;
  return API.get(url);
};

export const createPurchaserAPI = (body: CreatePurchaserType) => {
  const url = `${API_ENDPOINT}/purchaser`;
  return API.post(url, body);
};

export const updatePurchaserAPI = (id: number, payload: EditPurchaserType) => {
  const url = `${API_ENDPOINT}/purchaser/${id}`;
  return API.put(url, payload);
};

export const deletePurchaserAPI = (id: number) => {
  const url = `${API_ENDPOINT}/purchaser/${id}`;
  return API.deleteResource(url);
};
