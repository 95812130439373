import config from 'config/appConfig';
import { CreateInquiryType, EditInquiryType } from 'types/inquiries.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const createInquiryAPI = (body: CreateInquiryType) => {
  const url = `${API_ENDPOINT}/inquiry`;
  return API.post(url, body);
};

export const getAllInquiryAPI = (params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/inquiry/paginated?${params.toString()}`;
  return API.get(url);
};

export const getSingleInquiryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inquiry/${id}`;
  return API.get(url);
};

export const updateInquiryAPI = (body: EditInquiryType) => {
  const url = `${API_ENDPOINT}/inquiry`;
  return API.put(url, body);
};

export const deleteInquiryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inquiry/${id}`;
  return API.deleteResource(url);
};

export const transferInquiryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inquiry/reverse/${id}`;
  return API.post(url);
};
