import config from 'config/appConfig';
import { CreateProcessType, EditProcessType } from 'types/process.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllProcessAPI = () => {
  const url = `${API_ENDPOINT}/process`;
  return API.get(url);
};

export const createProcessAPI = (body: CreateProcessType) => {
  const url = `${API_ENDPOINT}/process`;
  return API.post(url, body);
};

export const getSingleProcessAPI = (id: number) => {
  const url = `${API_ENDPOINT}/process/${id}`;
  return API.get(url);
};

export const updateProcessAPI = (id: number, payload: EditProcessType) => {
  const url = `${API_ENDPOINT}/process/${id}`;
  return API.put(url, payload);
};

export const deleteProcessAPI = (id: number) => {
  const url = `${API_ENDPOINT}/process/${id}`;
  return API.deleteResource(url);
};
