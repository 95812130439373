import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { SectionHeading } from 'components/common/Headings';
import { ReactSelect } from 'components/common/ReactSelect';
import useCustomToast from 'hooks/toast';
import { priorityList, qapSheetOption } from 'constants/filter';

import BriefClientDetails from 'components/common/BriefClientDetails';
import { useFormContext, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'config/eunm';
import { LabReportHookFormType } from 'types/labReport.types';
import { CustomerBasicDetailsType } from 'types/customer.types';
import {
  ContactPersonFilterType,
  CustomerFilterType,
  LaboratoryFilterType,
  UserFilterType,
} from 'types/filter.types';
import {
  customerFilterRequestAsync,
  laboratoryContactFilterRequestAsync,
  laboratoryFilterRequestAsync,
  userFilterRequestAsync,
} from 'controller/filter.controller';
import {
  getCustomerDetailsRequestAsync,
  getLaboratoryDetailsRequestAsync,
} from 'controller/clientDetails.controller';
import { LaboratoryBasicDetailsType } from 'types/laboratory.types';

const BasicDetails = () => {
  const navigate = useNavigate();
  const showToast = useCustomToast();

  const {
    register,
    control,
    watch,
    resetField,
    formState: { errors, isSubmitSuccessful },
  } = useFormContext<LabReportHookFormType>();

  //details
  const [customerDetails, setCustomerDetails] = useState<CustomerBasicDetailsType>();
  const [laboratoryDetails, setLaboratoryDetails] = useState<LaboratoryBasicDetailsType>();

  const [customerFilter, setCustomerFilter] = useState<CustomerFilterType[]>();
  const [laboratoryFilter, setLaboratoryFilter] = useState<LaboratoryFilterType[]>();
  const [kindAttendanceFilter, setKindAttendanceFilter] = useState<ContactPersonFilterType[]>();
  const [userFilter, setUserFilter] = useState<UserFilterType[]>();

  const fetchFilterData = async () => {
    try {
      const [customer, user, laboratory] = await Promise.all([
        customerFilterRequestAsync(),
        userFilterRequestAsync(),
        laboratoryFilterRequestAsync(),
      ]);
      setCustomerFilter(customer);
      setUserFilter(user);
      setLaboratoryFilter(laboratory);
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  useEffect(() => {
    fetchFilterData();
  }, []);

  const watchCustomer = watch('customer');
  const watchLaboratory = watch('laboratory');

  const fetchCustomerData = async () => {
    try {
      if (watchCustomer) {
        const customerData = await getCustomerDetailsRequestAsync(watchCustomer.customerId);
        setCustomerDetails(customerData);
      }
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  const fetchLaboratoryData = async () => {
    try {
      if (watchLaboratory) {
        const laboratoryData = await getLaboratoryDetailsRequestAsync(watchLaboratory.labId);
        const contactData = await laboratoryContactFilterRequestAsync(laboratoryData.laboratoryId);
        setLaboratoryDetails(laboratoryData);
        setKindAttendanceFilter(contactData);
      }
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  useEffect(() => {
    if (watchCustomer) {
      fetchCustomerData();
    }
    if (watchLaboratory) {
      fetchLaboratoryData();
    }
  }, [watchCustomer, watchLaboratory]);

  useEffect(() => {
    if (watchCustomer === null) {
      setCustomerDetails(undefined);
      setKindAttendanceFilter(undefined);
      resetField('contact');
    }
    if (watchLaboratory === null) {
      setLaboratoryDetails(undefined);
    }
  }, [watchCustomer, watchLaboratory]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      setLaboratoryDetails(undefined);
      setCustomerDetails(undefined);
    }
  }, [isSubmitSuccessful]);

  return (
    <Box width={'100%'}>
      <Card variant={'content'} borderTop={'4px solid'} borderColor={'componentBorderColor.basic'}>
        <CardHeader mb={4} px={6} py={2} display={'flex'} alignItems={'center'}>
          <SectionHeading props={{ fontWeight: 'semibold', fontSize: '2xl' }}>
            Basic Details
          </SectionHeading>
        </CardHeader>
        <CardBody>
          <SimpleGrid width={'100%'} columns={[1, 1, 1, 2]} spacing={10}>
            <Flex width="100%" display={'flex'} flexDirection={'column'} gap={3}>
              <Flex width={'100%'}>
                <Controller
                  name="laboratory"
                  control={control}
                  render={({ field }) => (
                    <FormControl isInvalid={!!errors.laboratory}>
                      <FormLabel>
                        Laboratory : <span style={{ color: 'red' }}>*</span>
                      </FormLabel>
                      <Flex gap={4} width={'100%'} mb={4}>
                        <Box width={'100%'}>
                          <ReactSelect
                            isMulti={false}
                            isSearchable={true}
                            isClearable={true}
                            options={laboratoryFilter}
                            getOptionLabel={(option) => option.labName}
                            getOptionValue={(option) => option.labName}
                            placeholder={'Select Laboratory...'}
                            fields={field}
                            showCrossIcon={true}
                            menuListHeigth="140px"
                          />
                        </Box>
                        <Button
                          width={'100%'}
                          colorScheme="create"
                          variant={'primary'}
                          onClick={() =>
                            navigate(
                              `${APP_ROUTES.ROOT_MASTER}/${APP_ROUTES.MASTER_LABORATORY_LIST}/${APP_ROUTES.MASTER_NEW_LABORATORY}`,
                            )
                          }
                        >
                          Create New Laboratory
                        </Button>
                      </Flex>
                      <FormErrorMessage>
                        {errors.laboratory?.message || errors.laboratory?.labId?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Flex>
              <Flex width={'100%'}>
                <Controller
                  name="customer"
                  control={control}
                  render={({ field }) => (
                    <FormControl isInvalid={!!errors.customer}>
                      <FormLabel>
                        Client : <span style={{ color: 'red' }}>*</span>
                      </FormLabel>
                      <Flex gap={4} width={'100%'} mb={4}>
                        <Box width={'100%'}>
                          <ReactSelect
                            isMulti={false}
                            isSearchable={true}
                            isClearable={true}
                            options={customerFilter}
                            getOptionLabel={(option) => option.customerName}
                            getOptionValue={(option) => option.customerName}
                            placeholder={'Select Client...'}
                            fields={field}
                            showCrossIcon={true}
                            menuListHeigth="240px"
                          />
                        </Box>
                        <Button
                          width={'100%'}
                          colorScheme="create"
                          variant={'primary'}
                          onClick={() =>
                            navigate(
                              `${APP_ROUTES.ROOT_CLIENT_MASTER}/${APP_ROUTES.CM_CUSTOMER_LIST}/${APP_ROUTES.CM_NEW_CUSTOMER}`,
                            )
                          }
                        >
                          Create New Client
                        </Button>
                      </Flex>
                      <FormErrorMessage>
                        {errors.customer?.message || errors.customer?.customerId?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Flex>
              {(customerDetails || laboratoryDetails) && (
                <Card variant={'filled'} width={'100%'}>
                  <CardBody display={'flex'}>
                    <SimpleGrid width={'100%'} columns={[1, 2]} spacing={8}>
                      <Box display={'flex'}>
                        {laboratoryDetails ? (
                          <Stack divider={<StackDivider />} spacing="4" width={'100%'}>
                            <Box>
                              <Heading size="xs" textTransform="uppercase">
                                Lab Name :
                              </Heading>
                              <Text fontSize="lg" pt="2">
                                {laboratoryDetails.laboratoryName}
                              </Text>
                            </Box>
                            <Box>
                              <Heading size="xs" textTransform="uppercase" mb={2}>
                                Lab Email :
                              </Heading>
                              <Text fontSize="lg" pt="1">
                                {laboratoryDetails.email}
                              </Text>
                            </Box>
                            <Box>
                              <Heading size="xs" textTransform="uppercase">
                                Location/Branch
                              </Heading>
                              <Text pt="2" fontSize="sm">
                                {laboratoryDetails.laboratoryNamewithCity || '-'}
                              </Text>
                            </Box>
                            <Box>
                              <Heading size="xs" textTransform="uppercase">
                                Contact:
                              </Heading>
                              <Text pt="2" fontSize="sm">
                                {laboratoryDetails.mobile || '-'}
                              </Text>
                            </Box>
                          </Stack>
                        ) : (
                          'Select a Laboratory'
                        )}
                      </Box>
                      <Divider
                        display={{ sm: 'none', base: 'flex' }}
                        variant={'dashed'}
                        size={'100px'}
                      />
                      <Box display={'flex'}>
                        {customerDetails ? (
                          <BriefClientDetails
                            customerDetails={customerDetails}
                            clientType={'Customer'}
                          />
                        ) : (
                          'Select a Client'
                        )}
                      </Box>
                    </SimpleGrid>
                  </CardBody>
                </Card>
              )}
            </Flex>
            <Flex width="100%">
              <Card variant={'filled'} width={'100%'} p={4} height={'100%'}>
                <SimpleGrid columns={[1, 1, 2]} width={'100%'} spacing={12}>
                  <Flex width="100%">
                    <Flex direction={'column'} width={'100%'} gap={4}>
                      <FormControl isInvalid={!!errors.labNO}>
                        <FormLabel>Lab No. :</FormLabel>
                        <Box w={'100%'} background={'white'} rounded={'lg'}>
                          <Input
                            placeholder="Enter Lab No. ..."
                            width={'100%'}
                            {...register('labNO')}
                            variant={'ghost'}
                          />
                        </Box>
                        <FormErrorMessage>{errors.labNO?.message}</FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Flex>
                  <Flex width="100%">
                    <Flex direction={'column'} width={'100%'} gap={4}>
                      <FormControl isInvalid={!!errors.labReportDate}>
                        <FormLabel>
                          Lab Report Date : <span style={{ color: 'red' }}>*</span>
                        </FormLabel>
                        <Box w={'100%'} background={'white'} rounded={'lg'}>
                          <Input type="date" width={'100%'} {...register('labReportDate')} />
                        </Box>
                        <FormErrorMessage>{errors.labReportDate?.message}</FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Flex>
                  <Flex width="100%">
                    <Flex direction={'column'} width={'100%'} gap={4}>
                      <Controller
                        name="priority"
                        control={control}
                        render={({ field }) => (
                          <FormControl isInvalid={!!errors.priority}>
                            <FormLabel>
                              Priority : <span style={{ color: 'red' }}>*</span>
                            </FormLabel>
                            <Box w={'100%'} background={'white'} rounded={'lg'}>
                              <ReactSelect
                                isMulti={false}
                                isSearchable={true}
                                isClearable={true}
                                options={priorityList}
                                getOptionLabel={(option) => option.priorityName}
                                getOptionValue={(option) => option.priorityName}
                                placeholder={'Select Priority...'}
                                variant="ghost"
                                fields={field}
                                showCrossIcon={true}
                              />
                            </Box>
                            <FormErrorMessage>{errors.priority?.message}</FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </Flex>
                  </Flex>
                  <Flex width="100%">
                    <Flex direction={'column'} width={'100%'} gap={4}>
                      <FormControl isInvalid={!!errors.deliveryDate}>
                        <FormLabel>Delivery Date :</FormLabel>
                        <Box w={'100%'} background={'white'} rounded={'lg'}>
                          <Input type="date" width={'100%'} {...register('deliveryDate')} />
                        </Box>
                        <FormErrorMessage>{errors.deliveryDate?.message}</FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Flex>
                  <Flex width="100%">
                    <Flex direction={'column'} width={'100%'} gap={4}>
                      <FormControl isInvalid={!!errors.stampedBy}>
                        <FormLabel>
                          Stamped By : <span style={{ color: 'red' }}>*</span>
                        </FormLabel>
                        <Box w={'100%'} background={'white'} rounded={'lg'}>
                          <Input
                            {...register('stampedBy')}
                            background={'text.placeholder'}
                            isDisabled={true}
                            _disabled={{
                              textColor: 'black',
                              cursor: 'not-allowed',
                            }}
                            width={'100%'}
                            fontSize="14px"
                          />
                        </Box>
                        <FormErrorMessage>{errors.stampedBy?.message}</FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Flex>
                  <Flex width="100%">
                    <Flex direction={'column'} width={'100%'} gap={4}>
                      <Controller
                        name="witnessBy"
                        control={control}
                        render={({ field }) => (
                          <FormControl isInvalid={!!errors.witnessBy}>
                            <FormLabel> Witness By :</FormLabel>
                            <Box w={'100%'} background={'white'} rounded={'lg'}>
                              <ReactSelect
                                isMulti={false}
                                options={userFilter}
                                isSearchable={true}
                                isClearable={true}
                                placeholder={'Select Employee...'}
                                getOptionLabel={(option) => option.userName}
                                getOptionValue={(option) => option.userName}
                                fields={field}
                                variant="ghost"
                                showCrossIcon={true}
                                menuListHeigth="200px"
                              />
                            </Box>
                            <FormErrorMessage>
                              {errors.witnessBy?.message || errors.witnessBy?.userId?.message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </Flex>
                  </Flex>
                  <Flex width="100%">
                    <Flex direction={'column'} width={'100%'} gap={4}>
                      <Controller
                        name="contact"
                        control={control}
                        render={({ field }) => (
                          <FormControl isInvalid={!!errors.contact}>
                            <FormLabel> Kind Attendance :</FormLabel>
                            <Box w={'100%'} background={'white'} rounded={'lg'}>
                              <ReactSelect
                                isMulti={true}
                                isSearchable={true}
                                options={kindAttendanceFilter}
                                getOptionLabel={(option) => option.contactName}
                                getOptionValue={(option) => option.contactName}
                                placeholder={'Select ...'}
                                fields={field}
                                variant="ghost"
                              />
                            </Box>
                            <FormErrorMessage>{errors.contact?.message}</FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </Flex>
                  </Flex>
                  <Flex width="100%">
                    <Flex direction={'column'} width={'100%'} gap={4}>
                      <Controller
                        name="qapSheet"
                        control={control}
                        render={({ field }) => (
                          <FormControl isInvalid={!!errors.qapSheet}>
                            <FormLabel> QAP Sheet :</FormLabel>
                            <Box w={'100%'} background={'white'} rounded={'lg'}>
                              <ReactSelect
                                isMulti={false}
                                isSearchable={true}
                                isClearable={true}
                                options={qapSheetOption}
                                getOptionLabel={(option) => option.qapSheetValue}
                                getOptionValue={(option) => option.qapSheetValue}
                                placeholder={'Select ...'}
                                fields={field}
                                variant="ghost"
                              />
                            </Box>
                            <FormErrorMessage>{errors.qapSheet?.message}</FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </Flex>
                  </Flex>
                </SimpleGrid>
              </Card>
            </Flex>
          </SimpleGrid>
        </CardBody>
      </Card>
    </Box>
  );
};

export default BasicDetails;
