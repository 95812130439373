import {
  createInInventoryAPI,
  createOutExtInventoryAPI,
  createOutIntInventoryAPI,
  deleteCurrentInventoryAPI,
  deleteInInventoryAPI,
  deleteOutInventoryAPI,
  getAllCurrentInventoryAPI,
  getAllInInventoryAPI,
  getAllOutInventoryAPI,
  getSingleCurrentInventoryAPI,
  getSingleInInventoryAPI,
  getSingleOutInventoryAPI,
  updateInInventoryAPI,
  updateOutInventoryAPI,
} from 'services/inventory';
import {
  CreateInInventoryType,
  CreateOutExtInventoryType,
  CreateOutIntInventoryType,
  EditInInventoryType,
  EditOutInventoryType,
  SingleCurrentInventoryType,
  SingleInInventoryType,
  SingleOutInventoryType,
} from 'types/inventory.types';

export const getAllCurrentInventoryRequestAsync = async (params: URLSearchParams) => {
  const resp = await getAllCurrentInventoryAPI(params);
  return resp.data;
};

export const getAllInInventoryRequestAsync = async (params: URLSearchParams) => {
  const resp = await getAllInInventoryAPI(params);
  return resp.data;
};

export const getAllOutInventoryRequestAsync = async (params: URLSearchParams) => {
  const resp = await getAllOutInventoryAPI(params);
  return resp.data;
};

export const getSingleCurrentInventoryRequestAsync = async (
  id: number,
): Promise<SingleCurrentInventoryType> => {
  const resp = await getSingleCurrentInventoryAPI(id);
  return resp.data.data;
};

export const getSingleInInventoryRequestAsync = async (
  id: number,
): Promise<SingleInInventoryType> => {
  const resp = await getSingleInInventoryAPI(id);
  return resp.data.data;
};

export const getSingleOutInventoryRequestAsync = async (
  id: number,
): Promise<SingleOutInventoryType> => {
  const resp = await getSingleOutInventoryAPI(id);
  return resp.data.data;
};

export const createInInventoryRequestAsync = async (payload: CreateInInventoryType) => {
  const resp = await createInInventoryAPI(payload);
  return resp;
};

export const createOutIntInventoryRequestAsync = async (payload: CreateOutIntInventoryType) => {
  const resp = await createOutIntInventoryAPI(payload);
  return resp;
};

export const createOutExtInventoryRequestAsync = async (payload: CreateOutExtInventoryType) => {
  const resp = await createOutExtInventoryAPI(payload);
  return resp;
};

export const updateInInventoryRequestAsync = async (id: number, payload: EditInInventoryType) => {
  const resp = await updateInInventoryAPI(id, payload);
  return resp;
};

export const updateOutInventoryRequestAsync = async (id: number, payload: EditOutInventoryType) => {
  const resp = await updateOutInventoryAPI(id, payload);
  return resp;
};

export const deleteSingleInInventoryRequestAsync = async (id: number) => {
  const resp = await deleteInInventoryAPI(id);
  return resp;
};

export const deleteSingleCurrentInventoryRequestAsync = async (id: number) => {
  const resp = await deleteCurrentInventoryAPI(id);
  return resp;
};

export const deleteSingleOutInventoryRequestAsync = async (id: number) => {
  const resp = await deleteOutInventoryAPI(id);
  return resp;
};
