import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  GridItem,
  Heading,
  Skeleton,
  Spacer,
  Table,
  TableCaption,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import BreadCrumbLayout from 'components/common/BreadCrumb';
import { PageHeading } from 'components/common/Headings';
import React, { useContext, useEffect, useState } from 'react';

import DetailsTable from 'components/Table/DetailsTable';
import { useLocation, useNavigate } from 'react-router-dom';
import useCustomToast from 'hooks/toast';
import { APP_ROUTES } from 'config/eunm';
import DeleteDialog from 'components/common/DeleteDialog';
import { FormatDate } from 'helpers/date.helper';
import { SingleLabReportType } from 'types/labReport.types';
import { getSingleLabReportRequestAsync } from 'container/LabReport/controller';
import {
  MenuAccessItem,
  createAccessCheck,
  deleteAccessCheck,
  viewAccessCheck,
} from 'helpers/accessCheck.helper';
import { UserDetails } from 'App';
import AccessDeniedPage from 'components/AccessDeniedPage';
import { AxiosError } from 'axios';

const ViewLabReport = () => {
  const [singleLabReport, setSingleLabReport] = useState<SingleLabReportType>();
  const [viewLabReportLoader, setViewLabReportLoader] = useState<boolean>(true);
  const { state } = useLocation();
  const navigate = useNavigate();
  const showToast = useCustomToast();
  const deleteDisclosure = useDisclosure();

  const [viewAccessDenied, setViewAccessDenied] = useState<boolean>(false);

  const { user, setUser } = useContext(UserDetails);

  useEffect(() => {
    const access = viewAccessCheck('LabReport', user?.menuAccess as MenuAccessItem[]);
    if (!access) {
      setViewAccessDenied(true);
    }
    if (access) {
      setViewAccessDenied(false);
    }
  }, [user]);

  useEffect(() => {
    if (!state) {
      navigate(-1);
    }
  }, []);

  const fetchSingleLabReport = async () => {
    try {
      if (state) {
        const labReport = await getSingleLabReportRequestAsync(state.id);
        setSingleLabReport(labReport);
        setViewLabReportLoader(false);
      }
    } catch (error: any) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setViewAccessDenied(true);
      } else
        showToast({
          title: err.message,
          description: 'Something went wrong!!',
          status: 'error',
        });
    }
  };

  useEffect(() => {
    fetchSingleLabReport();
  }, []);
  return (
    <Box width={'100%'} display={'flex'} gap={2} flexDirection={'column'}>
      <Box width={'100%'}>
        <Card
          variant={'headerBg'}
          display={'flex'}
          flexDirection={{ md: 'row', base: 'column' }}
          gap={5}
          alignItems={{ md: 'center', base: 'start' }}
        >
          <Box>
            <PageHeading props={{ pb: 2 }}>View Lab Report</PageHeading>
            <BreadCrumbLayout
              options={[
                { name: 'Dashboard', link: `${APP_ROUTES.HOME_PAGE}` },
                { name: 'Lab Reports', link: `${APP_ROUTES.LAB_REPORT}` },
                { name: 'View Lab Report', isActivePage: true },
              ]}
            />
          </Box>
          <Spacer />

          <Flex gap={4}>
            <Button
              colorScheme="blue"
              size={'sm'}
              isDisabled={!createAccessCheck('LabReport', user?.menuAccess as MenuAccessItem[])}
              onClick={() =>
                navigate(`${APP_ROUTES.LAB_REPORT}/${APP_ROUTES.EDIT_LAB_REPORT}`, {
                  state: { id: singleLabReport?.labReportId },
                })
              }
            >
              Update
            </Button>
            <Button
              colorScheme="red"
              size={'sm'}
              onClick={deleteDisclosure.onOpen}
              isDisabled={!deleteAccessCheck('LabReport', user?.menuAccess as MenuAccessItem[])}
            >
              Delete
            </Button>
          </Flex>
        </Card>
      </Box>
      {viewAccessDenied ? (
        <AccessDeniedPage />
      ) : viewLabReportLoader ? (
        <Skeleton height="400px" />
      ) : (
        <Grid
          templateRows={['auto', '1fr']}
          templateColumns={{ base: '100%', lg: 'repeat(3, 1fr)' }}
          gap={6}
        >
          <GridItem>
            <Card
              variant={'content'}
              borderTop={'4px solid'}
              borderColor={'componentBorderColor.basic'}
            >
              <DetailsTable
                tableCaption="Basic Details"
                tableData={[
                  {
                    dataTitle: 'Lab Report ID',
                    dataValue: singleLabReport?.labReportCode,
                  },
                  {
                    dataTitle: 'Laboratory',
                    dataValue: singleLabReport?.labName,
                  },
                  {
                    dataTitle: 'Client',
                    dataValue: singleLabReport?.customername,
                  },
                  {
                    dataTitle: 'Lab No.',
                    dataValue: singleLabReport?.labNO,
                  },
                  {
                    dataTitle: 'Lab Report Date',
                    dataValue: singleLabReport?.labReportDate
                      ? FormatDate(singleLabReport.labReportDate)
                      : '-',
                  },
                ]}
              />
            </Card>
          </GridItem>
          <GridItem colSpan={{ base: 1, lg: 2 }}>
            <Card
              variant={'content'}
              borderTop={'4px solid'}
              borderColor={'componentBorderColor.specification'}
            >
              <TableContainer>
                <Table size={'sm'} variant={'customStriped'}>
                  <TableCaption placement="top" borderBottom={'1px solid'}>
                    <Heading size={'md'}>Items Information </Heading>
                  </TableCaption>
                  <Thead>
                    <Tr key={'item-info-header'}>
                      <Th sx={{ textTransform: 'capitalize' }}>Standard</Th>
                      <Th sx={{ textTransform: 'capitalize' }}>Grade</Th>
                      <Th sx={{ textTransform: 'capitalize' }}>MOC</Th>
                      <Th sx={{ textTransform: 'capitalize' }}>Size</Th>
                      <Th sx={{ textTransform: 'capitalize' }}>Id No.</Th>
                      <Th sx={{ textTransform: 'capitalize' }}>Heat No.</Th>
                      <Th sx={{ textTransform: 'capitalize' }}>Testing Required</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {singleLabReport?.items.map((i, idx) => (
                      <Tr key={'item-info-row_' + idx}>
                        <Td>{i.standardName || '-'}</Td>
                        <Td>{i.gradeName || '-'}</Td>
                        <Td>{i.moc || '-'}</Td>
                        <Td>{i.size || '-'}</Td>
                        <Td>{i.idNO || '-'}</Td>
                        <Td>{i.heatNO || '-'}</Td>
                        <Td>
                          {i.testingResp.map((test) => (
                            <Tag variant="solid" margin={1}>
                              {test.testingTypeName}
                            </Tag>
                          )) || '-'}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          </GridItem>
          <GridItem>
            <Card
              variant={'content'}
              borderTop={'4px solid'}
              borderColor={'componentBorderColor.other'}
            >
              <DetailsTable
                tableCaption="Other Details"
                tableData={[
                  {
                    dataTitle: 'Stamped By',
                    dataValue: singleLabReport?.stampedBy,
                  },
                  {
                    dataTitle: 'Witness By',
                    dataValue: singleLabReport?.witnessName,
                  },
                  {
                    dataTitle: 'QAP Sheet',
                    dataValue: singleLabReport?.qapSheet,
                  },
                  {
                    dataTitle: 'Priority',
                    dataValue: singleLabReport?.priorityName,
                  },
                  {
                    dataTitle: 'Delivery Date',
                    dataValue: singleLabReport?.deliveryDate
                      ? FormatDate(singleLabReport.deliveryDate)
                      : '-',
                  },
                ]}
              />
            </Card>
          </GridItem>
        </Grid>
      )}
      {state && (
        <DeleteDialog
          isOpen={deleteDisclosure.isOpen}
          onClose={deleteDisclosure.onClose}
          dialogFor="labReport"
          dataId={state.id}
          dialogName="Lab Report"
        />
      )}
    </Box>
  );
};

export default ViewLabReport;
