import React, { createContext, Suspense, useEffect, useState } from 'react';
import Navigator from 'navigator/Navigator';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import customTheme from 'theme';
import InitPageLoader from 'components/InitPageLoader';
import AxiosInterceptor from 'components/common/AxiosInterceptor';
import { SingleUserDetailsType } from 'types/user.types';
import { getUserProfileRequestAsync } from 'controller/userProfile.controller';
import { getCookie } from 'utils/common';
import { USER_ID } from 'config/constant';

export interface UserDetailsContextType {
  user: SingleUserDetailsType | undefined;
  setUser: React.Dispatch<React.SetStateAction<SingleUserDetailsType | undefined>>;
}
export const UserDetails = createContext<UserDetailsContextType>({
  user: undefined,
  setUser: () => {},
});

function App() {
  const [user, setUser] = useState<SingleUserDetailsType>();
  const getUserDetails = async (userId: number) => {
    const resp = await getUserProfileRequestAsync(userId);

    setUser(resp);
  };

  useEffect(() => {
    const currentUserId = getCookie(USER_ID);
    if (currentUserId) {
      getUserDetails(currentUserId);
    }
  }, []);

  return (
    <ChakraProvider theme={customTheme}>
      <CSSReset />
      <Suspense fallback={<InitPageLoader />}>
        <BrowserRouter>
          <AxiosInterceptor>
            <UserDetails.Provider value={{ user, setUser }}>
              <Navigator />
            </UserDetails.Provider>
          </AxiosInterceptor>
        </BrowserRouter>
      </Suspense>
    </ChakraProvider>
  );
}

export default App;
