import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Skeleton,
  Spacer,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import DetailsTable from 'components/Table/DetailsTable';
import BreadCrumbLayout from 'components/common/BreadCrumb';
import { PageHeading } from 'components/common/Headings';
import { APP_ROUTES } from 'config/eunm';
import useCustomToast from 'hooks/toast';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteDialog from 'components/common/DeleteDialog';
import { GetSingleLaboratoryType } from 'types/laboratory.types';
import { getSingleLaboratoryRequestAsync } from '../../controller';
import {
  MenuAccessItem,
  createAccessCheck,
  deleteAccessCheck,
  viewAccessCheck,
} from 'helpers/accessCheck.helper';
import { UserDetails } from 'App';
import AccessDeniedPage from 'components/AccessDeniedPage';
import { AxiosError } from 'axios';

const ViewLaboratory = () => {
  const [singleLaboratory, setSingleLaboratory] = useState<GetSingleLaboratoryType>();
  const [viewLaboratoryLoader, setViewLaboratoryLoader] = useState<boolean>(true);
  const { state } = useLocation();
  const navigate = useNavigate();
  const showToast = useCustomToast();
  const deleteDisclosure = useDisclosure();
  const [viewAccessDenied, setViewAccessDenied] = useState<boolean>(false);

  const { user, setUser } = useContext(UserDetails);

  useEffect(() => {
    const access = viewAccessCheck('Master', user?.menuAccess as MenuAccessItem[]);
    if (!access) {
      setViewAccessDenied(true);
    }
    if (access) {
      setViewAccessDenied(false);
    }
  }, [user]);

  useEffect(() => {
    if (!state) {
      navigate(-1);
    }
  }, []);

  const fetchsingleLaboratory = async () => {
    try {
      const laboratory = await getSingleLaboratoryRequestAsync(state.id);
      setSingleLaboratory(laboratory);
      setViewLaboratoryLoader(false);
    } catch (error: any) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setViewAccessDenied(true);
      } else
        showToast({
          title: err.message,
          description: 'Something went wrong!!',
          status: 'error',
        });
    }
  };
  useEffect(() => {
    fetchsingleLaboratory();
  }, []);

  return (
    <Box width={'100%'} display={'flex'} gap={2} flexDirection={'column'}>
      <Box width={'100%'}>
        <Card
          variant={'headerBg'}
          display={'flex'}
          flexDirection={{ md: 'row', base: 'column' }}
          gap={5}
          alignItems={{ md: 'center', base: 'start' }}
        >
          <Box>
            <PageHeading props={{ pb: 2 }}>View Laboratory</PageHeading>
            <BreadCrumbLayout
              options={[
                { name: 'Dashboard', link: `${APP_ROUTES.HOME_PAGE}` },
                { name: 'Master' },
                {
                  name: 'Laboratory',
                  link: `${APP_ROUTES.ROOT_MASTER}/${APP_ROUTES.MASTER_LABORATORY_LIST}`,
                },
                { name: 'View Laboratory', isActivePage: true },
              ]}
            />
          </Box>
          <Spacer />
          <Flex gap={4}>
            <Button
              onClick={() =>
                navigate(
                  `${APP_ROUTES.ROOT_MASTER}/${APP_ROUTES.MASTER_LABORATORY_LIST}/${APP_ROUTES.MASTER_EDIT_LABORATORY}`,
                  { state: { id: singleLaboratory?.laboratoryId } },
                )
              }
              variant="primary"
              colorScheme="create"
              size={'sm'}
              isDisabled={!createAccessCheck('Master', user?.menuAccess as MenuAccessItem[])}
            >
              Update
            </Button>
            <Button
              variant="primary"
              size={'sm'}
              colorScheme="secondary"
              onClick={deleteDisclosure.onOpen}
              isDisabled={!deleteAccessCheck('Master', user?.menuAccess as MenuAccessItem[])}
            >
              Delete
            </Button>
          </Flex>
        </Card>
      </Box>
      {viewAccessDenied ? (
        <AccessDeniedPage />
      ) : viewLaboratoryLoader ? (
        <Skeleton height="400px" />
      ) : (
        <>
          <Flex width={'100%'}>
            <Card
              variant={'content'}
              borderTop={'4px solid'}
              borderColor={'componentBorderColor.basic'}
              width={'100%'}
            >
              <DetailsTable
                tableCaption="Basic Details"
                tableData={[
                  {
                    dataTitle: 'Laboratory Name',
                    dataValue: singleLaboratory?.laboratoryName,
                  },
                  {
                    dataTitle: 'Email',
                    dataValue: singleLaboratory?.email || '-',
                  },
                  {
                    dataTitle: 'Mobile',
                    dataValue: singleLaboratory?.mobile,
                  },
                ]}
              />
            </Card>
          </Flex>
          <Flex width={'100%'}>
            <Card
              variant={'content'}
              borderTop={'4px solid'}
              borderColor={'componentBorderColor.address'}
              width={'100%'}
            >
              <DetailsTable
                tableCaption="Office Address"
                tableData={[
                  {
                    dataTitle: ' Name',
                    dataValue: singleLaboratory?.pAddress.officeAddress1,
                  },
                  {
                    dataTitle: 'Building',
                    dataValue: singleLaboratory?.pAddress.officeAddress2,
                  },
                  {
                    dataTitle: 'Area',
                    dataValue: singleLaboratory?.pAddress.officeAddress3,
                  },
                  {
                    dataTitle: 'City',
                    dataValue: singleLaboratory?.pAddress?.officeCity?.cityName,
                  },

                  {
                    dataTitle: 'Pincode',
                    dataValue: singleLaboratory?.pAddress.officePincode,
                  },
                ]}
              />
            </Card>
          </Flex>

          <Flex mt={2} width={'100%'}>
            <Card
              variant={'content'}
              borderTop={'4px solid'}
              borderColor={'componentBorderColor.contact'}
              width={'100%'}
            >
              <Table size={'lg'} variant={'customStriped'}>
                <TableCaption placement="top" borderBottom={'1px solid'}>
                  <Heading size={'md'}>La's Contact Details</Heading>
                </TableCaption>
                <Thead>
                  <Tr key={`contact-info`}>
                    <Th>Contact Person</Th>
                    <Th>Date Of Birth</Th>
                    <Th>Email Id</Th>
                    <Th>Mobile</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {singleLaboratory?.contactList?.map((i, idx) => (
                    <Tr key={`contact-info-${idx}`}>
                      <Td>{i.contactName || '-'}</Td>
                      <Td>{i.dob || '-'}</Td>
                      <Td>{i.email || '-'}</Td>
                      <Td>{i.mobile || '-'}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Card>
          </Flex>
        </>
      )}
      {state && (
        <DeleteDialog
          isOpen={deleteDisclosure.isOpen}
          onClose={deleteDisclosure.onClose}
          dialogFor="laboratory"
          dataId={state.id}
          dialogName="Laboratory"
        />
      )}
    </Box>
  );
};

export default ViewLaboratory;
