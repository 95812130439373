import {
  createLabTestAPI,
  deleteLabTestAPI,
  getAllLabTestAPI,
  getSingleLabTestAPI,
  updateLabTestAPI,
} from 'services/labTest';
import {
  AllTestingType,
  CreateTestingType,
  EditTestingType,
  GetSingleTestingType,
  TestingTableType,
} from 'types/testing.types';

export const getAllLabTestsRequestAsync = async (): Promise<TestingTableType[]> => {
  const resp = await getAllLabTestAPI();
  const filteredResp: TestingTableType[] = resp.data.data.map(
    (test: AllTestingType): TestingTableType => ({
      testingId: test.testingTypeId,
      testingName: test.testingTypeName,
      testingUID: test.testingTypeUId,
    }),
  );
  return filteredResp;
};

export const createLabTestRequestAsync = async (payload: CreateTestingType) => {
  const resp = await createLabTestAPI(payload);
  return resp;
};

export const getSingleLabTestRequestAsync = async (id: number): Promise<GetSingleTestingType> => {
  const resp = await getSingleLabTestAPI(id);
  return resp.data.data;
};

export const updateLabTestRequestAsync = async (id: number, payload: EditTestingType) => {
  const resp = await updateLabTestAPI(id, payload);
  return resp;
};

export const deleteSingleLabTestRequestAsync = async (id: number) => {
  const resp = await deleteLabTestAPI(id);
  return resp;
};
