import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PageNotFoundComp from 'components/PageNotFound';
import PageLayout from 'components/Layout';
import { APP_ROUTES } from 'config/eunm';
import LabReportForm from 'container/LabReport/LabReportForm';
import ViewLabReport from 'container/LabReport/Actions/ViewLabReport';
import EditLabReport from 'container/LabReport/Actions/EditLabReport';
import MasterLaboratoryListPage from 'container/Master/LaboratoryList';
import LaboratoryForm from 'container/Master/LaboratoryList/LaboratoryForm';
import ViewLaboratory from 'container/Master/LaboratoryList/Actions/ViewLaboratory';
import EditLaboratory from 'container/Master/LaboratoryList/Actions/EditLaboratory';
import MasterTestingListPage from 'container/Configuration/TestingList';
import TestingForm from 'container/Configuration/TestingList/TestingForm';
import ViewTesting from 'container/Configuration/TestingList/Actions/ViewTesting';
import EditTesting from 'container/Configuration/TestingList/Actions/EditTesting';
const EditQuotation = lazy(() => import('container/Quotations/Actions/EditQuotation'));
const ViewInquiry = lazy(() => import('container/Inquiries/Actions/ViewInquiry'));
const EditInquiry = lazy(() => import('container/Inquiries/Actions/EditInquiry'));
const CFTForm = lazy(() => import('container/Transactions/CuttingForging/CFTForm'));
const EditCFTForm = lazy(() => import('container/Transactions/CuttingForging/Actions/EditCFTForm'));
const CustomerForm = lazy(() => import('container/Master/CustomerList/CustomerForm'));
const ViewCustomer = lazy(() => import('container/Master/CustomerList/Actions/ViewCustomer'));
const EditCustomer = lazy(() => import('container/Master/CustomerList/Actions/EditCustomer'));
const BrokerForm = lazy(() => import('container/Master/BrokerList/BrokerForm'));
const ViewBroker = lazy(() => import('container/Master/BrokerList/Actions/ViewBroker'));
const EditBroker = lazy(() => import('container/Master/BrokerList/Actions/EditBroker'));
const MasterVendorListPage = lazy(() => import('container/Master/VendorList'));
const VendorForm = lazy(() => import('container/Master/VendorList/VendorForm'));
const ViewVendor = lazy(() => import('container/Master/VendorList/Actions/ViewVendor'));
const EditVendor = lazy(() => import('container/Master/VendorList/Actions/EditVendor'));
const MasterGradeListPage = lazy(() => import('container/Master/GradeList'));
const GradeForm = lazy(() => import('container/Master/GradeList/GradeForm'));
const ViewGrade = lazy(() => import('container/Master/GradeList/Actions/ViewGrade'));
const EditGrade = lazy(() => import('container/Master/GradeList/Actions/EditGrade'));
const MasterCategoryListPage = lazy(() => import('container/Master/CategoryList'));
const CategoryForm = lazy(() => import('container/Master/CategoryList/CategoryForm'));
const ViewCategory = lazy(() => import('container/Master/CategoryList/Actions/ViewCategory'));
const EditCategory = lazy(() => import('container/Master/CategoryList/Actions/EditCategory'));
const MasterLocationListPage = lazy(() => import('container/Master/LocationList'));
const LocationForm = lazy(() => import('container/Master/LocationList/LocationForm'));
const ViewLocation = lazy(() => import('container/Master/LocationList/Actions/ViewLocation'));
const EditLocation = lazy(() => import('container/Master/LocationList/Actions/EditLocation'));
const CompanyForm = lazy(() => import('container/Configuration/Company/CompanyForm'));
const ViewCompany = lazy(() => import('container/Configuration/Company/Actions/ViewCompany'));
const EditCompany = lazy(() => import('container/Configuration/Company/Actions/EditCompany'));
const ConfigDesignationPage = lazy(() => import('container/Configuration/Designation'));
const DesignationForm = lazy(() => import('container/Configuration/Designation/DesignationForm'));
const ViewDesignation = lazy(
  () => import('container/Configuration/Designation/Actions/ViewDesignation'),
);
const EditDesignation = lazy(
  () => import('container/Configuration/Designation/Actions/EditDesignation'),
);
const ConfigConditionPage = lazy(() => import('container/Configuration/Condition'));
const ConditionForm = lazy(() => import('container/Configuration/Condition/ConditionForm.tsx'));
const ViewCondition = lazy(() => import('container/Configuration/Condition/Actions/ViewCondition'));
const EditCondition = lazy(() => import('container/Configuration/Condition/Actions/EditCondition'));
const ConfigInquirySourcePage = lazy(() => import('container/Configuration/InquirySource'));
const InquirySourceForm = lazy(
  () => import('container/Configuration/InquirySource/InquirySourceForm'),
);
const ViewInquirySource = lazy(
  () => import('container/Configuration/InquirySource/Actions/ViewInquirySource'),
);
const EditInquirySource = lazy(
  () => import('container/Configuration/InquirySource/Actions/EditInquirySource'),
);
const ConfigMakePage = lazy(() => import('container/Configuration/Make'));
const MakeForm = lazy(() => import('container/Configuration/Make/MakeForm'));
const ViewMake = lazy(() => import('container/Configuration/Make/Actions/ViewMake'));
const EditMake = lazy(() => import('container/Configuration/Make/Actions/EditMake'));
const ConfigProcessPage = lazy(() => import('container/Configuration/Process'));
const ProcessForm = lazy(() => import('container/Configuration/Process/ProcessForm'));
const ViewProcess = lazy(() => import('container/Configuration/Process/Actions/ViewProcess'));
const EditProcess = lazy(() => import('container/Configuration/Process/Actions/EditProcess'));
const CFTReport = lazy(() => import('container/ManagerView/CFT Report'));
const MasterCityListPage = lazy(() => import('container/Master/CityList'));
const CityForm = lazy(() => import('container/Master/CityList/CityForm'));
const ViewCity = lazy(() => import('container/Master/CityList/Actions/ViewCity'));
const EditCity = lazy(() => import('container/Master/CityList/Actions/EditCity'));
const MasterStateListPage = lazy(() => import('container/Master/StateList'));
const StateForm = lazy(() => import('container/Master/StateList/StateForm'));
const ViewState = lazy(() => import('container/Master/StateList/Actions/ViewState'));
const EditState = lazy(() => import('container/Master/StateList/Actions/EditState'));
const MasterWarehouseListPage = lazy(() => import('container/Master/WarehouseList'));
const WarehouseForm = lazy(() => import('container/Master/WarehouseList/WarehouseForm'));
const ViewWarehouse = lazy(() => import('container/Master/WarehouseList/Actions/ViewWarehouse'));
const EditWarehouse = lazy(() => import('container/Master/WarehouseList/Actions/EditWarehouse'));
const CountryForm = lazy(() => import('container/Configuration/Country/CountryForm'));
const ViewCountry = lazy(() => import('container/Configuration/Country/Actions/ViewCountry'));
const EditCountry = lazy(() => import('container/Configuration/Country/Actions/EditCountry'));
const ConfigStandardPage = lazy(() => import('container/Configuration/Standard'));
const StandardForm = lazy(() => import('container/Configuration/Standard/StandardForm'));
const ViewStandard = lazy(() => import('container/Configuration/Standard/Actions/ViewStandard'));
const EditStandard = lazy(() => import('container/Configuration/Standard/Actions/EditStandard'));
const MasterUserListPage = lazy(() => import('container/Master/UserList'));
const UsersForm = lazy(() => import('container/Master/UserList/UsersForm'));
const ViewUsers = lazy(() => import('container/Master/UserList/Actions/ViewUsers'));
const EditUsers = lazy(() => import('container/Master/UserList/Actions/EditUsers'));
const ViewCFT = lazy(() => import('container/ManagerView/CFT Report/Actions/ViewCFT'));
const InventoryCurrentStockList = lazy(() => import('container/Inventory/CurrentStock'));
const InventoryInStockList = lazy(() => import('container/Inventory/InStock'));
const InventoryOutStockList = lazy(() => import('container/Inventory/OutStock'));
const MasterPurchaserListPage = lazy(() => import('container/Master/PurchaserList'));
const PurchaserForm = lazy(() => import('container/Master/PurchaserList/PurchaserForm'));
const ViewPurchaser = lazy(() => import('container/Master/PurchaserList/Actions/ViewPurchaser'));
const EditPurchaser = lazy(() => import('container/Master/PurchaserList/Actions/EditPurchaser'));
const InStockForm = lazy(() => import('container/Inventory/InStock/InstockForm'));
const ViewInStock = lazy(() => import('container/Inventory/InStock/Action/ViewInStock'));
const ViewCurrentStock = lazy(
  () => import('container/Inventory/CurrentStock/Action/ViewCurrentStock'),
);
const OutStockForm = lazy(() => import('container/Inventory/OutStock/OutStockForm'));
const EditOutStock = lazy(() => import('container/Inventory/OutStock/Action/EditOutStock'));
const ViewOutStock = lazy(() => import('container/Inventory/OutStock/Action/ViewOutStock'));
const EditInStock = lazy(() => import('container/Inventory/InStock/Action/EditInStock'));
const CPOForm = lazy(() => import('container/PurchaseOrder/CPO/CPOForm'));
const QuotationForm = lazy(() => import('container/Quotations/QuotationForm'));
const SPOPage = lazy(() => import('container/PurchaseOrder/SPO'));
const ViewSPO = lazy(() => import('container/PurchaseOrder/SPO/Actions/ViewSPO'));
const EditSPO = lazy(() => import('container/PurchaseOrder/SPO/Actions/EditSPO'));
const SPOForm = lazy(() => import('container/PurchaseOrder/SPO/SPOForm'));
const CPOPage = lazy(() => import('container/PurchaseOrder/CPO'));
const EditCPO = lazy(() => import('container/PurchaseOrder/CPO/Actions/EditCPO'));
const ViewCPO = lazy(() => import('container/PurchaseOrder/CPO/Actions/ViewCPO'));
const LabReportList = lazy(() => import('container/LabReport'));

const LoginPage = React.lazy(() => import('container/Login'));
const HomePage = React.lazy(() => import('container/HomePage'));
const MasterCustomerListPage = React.lazy(() => import('container/Master/CustomerList'));
const MasterBrokerListPage = React.lazy(() => import('container/Master/BrokerList'));
const TranscationCuttingForgingPage = React.lazy(
  () => import('container/Transactions/CuttingForging'),
);
const InquiriesPage = React.lazy(() => import('container/Inquiries'));
const CreateInquiryPage = React.lazy(() => import('container/Inquiries/InquiryForm'));
const QuotationsPage = React.lazy(() => import('container/Quotations'));
const ViewQuotation = React.lazy(() => import('container/Quotations/Actions/ViewQuotation'));

const ConfigCountryMasterPage = React.lazy(() => import('container/Configuration/Country'));
const ConfigCompanyPage = React.lazy(() => import('container/Configuration/Company'));

const Navigator = () => {
  return (
    <Routes>
      <Route path={APP_ROUTES.LOGIN} element={<LoginPage />} />

      <Route
        path={APP_ROUTES.HOME_PAGE}
        element={
          <PageLayout>
            <HomePage />
          </PageLayout>
        }
      />
      {/*Operation entries */}
      <Route path={APP_ROUTES.ROOT_OPERATIONS}>
        <Route path={APP_ROUTES.OPERATIONS_CUTTING_FORGING}>
          <Route
            index
            element={
              <PageLayout>
                <TranscationCuttingForgingPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.NEW_CUTTING_FORGING}
            element={
              <PageLayout>
                <CFTForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.EDIT_CUTTING_FORGING}
            element={
              <PageLayout>
                <EditCFTForm />
              </PageLayout>
            }
          />
        </Route>
      </Route>

      {/* Inquiries entries */}
      <Route path={APP_ROUTES.INQUIRY_LEADS}>
        <Route
          index
          element={
            <PageLayout>
              <InquiriesPage />
            </PageLayout>
          }
        />
        <Route
          path={APP_ROUTES.NEW_INQUIRIES}
          element={
            <PageLayout>
              <CreateInquiryPage />
            </PageLayout>
          }
        />
        <Route
          path={APP_ROUTES.VIEW_INQUIRIES}
          element={
            <PageLayout>
              <ViewInquiry />
            </PageLayout>
          }
        />
        <Route
          path={APP_ROUTES.EDIT_INQUIRIES}
          element={
            <PageLayout>
              <EditInquiry />
            </PageLayout>
          }
        />
      </Route>
      {/*Quotations */}
      <Route path={APP_ROUTES.QUOTATIONS}>
        <Route
          index
          element={
            <PageLayout>
              <QuotationsPage />
            </PageLayout>
          }
        />
        <Route
          path={APP_ROUTES.NEW_QUOTATION}
          element={
            <PageLayout>
              <QuotationForm />
            </PageLayout>
          }
        />
        <Route
          path={APP_ROUTES.VIEW_QUOTATION}
          element={
            <PageLayout>
              <ViewQuotation />
            </PageLayout>
          }
        />
        <Route
          path={APP_ROUTES.EDIT_QUOTATION}
          element={
            <PageLayout>
              <EditQuotation />
            </PageLayout>
          }
        />
      </Route>
      {/*Purchase Order */}
      <Route path={APP_ROUTES.ROOT_PURCHASE_ORDER}>
        <Route path={APP_ROUTES.SUPPLIER_PURCHASE_ORDERS}>
          <Route
            index
            element={
              <PageLayout>
                <SPOPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.VIEW_SPO}
            element={
              <PageLayout>
                <ViewSPO />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.EDIT_SPO}
            element={
              <PageLayout>
                <EditSPO />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.NEW_SPO}
            element={
              <PageLayout>
                <SPOForm />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.CUSTOMER_PURCHASE_ORDERS}>
          <Route
            index
            element={
              <PageLayout>
                <CPOPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.EDIT_CPO}
            element={
              <PageLayout>
                <EditCPO />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.VIEW_CPO}
            element={
              <PageLayout>
                <ViewCPO />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.NEW_CPO}
            element={
              <PageLayout>
                <CPOForm />
              </PageLayout>
            }
          />
        </Route>
      </Route>
      {/*Lab Report */}
      <Route path={APP_ROUTES.LAB_REPORT}>
        <Route
          index
          element={
            <PageLayout>
              <LabReportList />
            </PageLayout>
          }
        />
        <Route
          path={APP_ROUTES.NEW_LAB_REPORT}
          element={
            <PageLayout>
              <LabReportForm />
            </PageLayout>
          }
        />
        <Route
          path={APP_ROUTES.VIEW_LAB_REPORT}
          element={
            <PageLayout>
              <ViewLabReport />
            </PageLayout>
          }
        />
        <Route
          path={APP_ROUTES.EDIT_LAB_REPORT}
          element={
            <PageLayout>
              <EditLabReport />
            </PageLayout>
          }
        />
      </Route>

      {/*Inventory */}

      <Route path={APP_ROUTES.ROOT_INVENTORY}>
        <Route path={APP_ROUTES.INVENTORY_CURRENT_STOCK_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <InventoryCurrentStockList />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.VIEW_CURRENT_STOCK}
            element={
              <PageLayout>
                <ViewCurrentStock />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.INVENTORY_IN_STOCK_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <InventoryInStockList />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.NEW_IN_STOCK}
            element={
              <PageLayout>
                <InStockForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.VIEW_IN_STOCK}
            element={
              <PageLayout>
                <ViewInStock />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.EDIT_IN_STOCK}
            element={
              <PageLayout>
                <EditInStock />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.INVENTORY_OUT_STOCK_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <InventoryOutStockList />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.NEW_OUT_STOCK}
            element={
              <PageLayout>
                <OutStockForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.EDIT_OUT_STOCK}
            element={
              <PageLayout>
                <EditOutStock />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.VIEW_OUT_STOCK}
            element={
              <PageLayout>
                <ViewOutStock />
              </PageLayout>
            }
          />
        </Route>
      </Route>

      {/* Manager View entries */}
      <Route path={APP_ROUTES.ROOT_MANAGER_VIEW}>
        <Route path={APP_ROUTES.MANAGER_VIEW_CFT}>
          <Route
            index
            element={
              <PageLayout>
                <CFTReport />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.NEW_CUTTING_FORGING}
            element={
              <PageLayout>
                <CFTForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.EDIT_CUTTING_FORGING}
            element={
              <PageLayout>
                <EditCFTForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.VIEW_CUTTING_FORGING}
            element={
              <PageLayout>
                <ViewCFT />
              </PageLayout>
            }
          />
        </Route>
      </Route>
      {/*Client Master */}
      <Route path={APP_ROUTES.ROOT_CLIENT_MASTER}>
        <Route path={APP_ROUTES.CM_CUSTOMER_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <MasterCustomerListPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CM_NEW_CUSTOMER}
            element={
              <PageLayout>
                <CustomerForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CM_VIEW_CUSTOMER}
            element={
              <PageLayout>
                <ViewCustomer />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CM_EDIT_CUSTOMER}
            element={
              <PageLayout>
                <EditCustomer />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.CM_BROKER_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <MasterBrokerListPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CM_NEW_BROKER}
            element={
              <PageLayout>
                <BrokerForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CM_VIEW_BROKER}
            element={
              <PageLayout>
                <ViewBroker />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CM_EDIT_BROKER}
            element={
              <PageLayout>
                <EditBroker />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.CM_VENDOR_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <MasterVendorListPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CM_NEW_VENDOR}
            element={
              <PageLayout>
                <VendorForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CM_VIEW_VENDOR}
            element={
              <PageLayout>
                <ViewVendor />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CM_EDIT_VENDOR}
            element={
              <PageLayout>
                <EditVendor />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.CM_PURCHASER_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <MasterPurchaserListPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CM_NEW_PURCHASER}
            element={
              <PageLayout>
                <PurchaserForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CM_VIEW_PURCHASER}
            element={
              <PageLayout>
                <ViewPurchaser />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CM_EDIT_PURCHASER}
            element={
              <PageLayout>
                <EditPurchaser />
              </PageLayout>
            }
          />
        </Route>
      </Route>

      {/* Master entries */}
      <Route path={APP_ROUTES.ROOT_MASTER}>
        <Route path={APP_ROUTES.MASTER_WAREHOUSE_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <MasterWarehouseListPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_NEW_WAREHOUSE}
            element={
              <PageLayout>
                <WarehouseForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_VIEW_WAREHOUSE}
            element={
              <PageLayout>
                <ViewWarehouse />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_EDIT_WAREHOUSE}
            element={
              <PageLayout>
                <EditWarehouse />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.MASTER_LABORATORY_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <MasterLaboratoryListPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_NEW_LABORATORY}
            element={
              <PageLayout>
                <LaboratoryForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_VIEW_LABORATORY}
            element={
              <PageLayout>
                <ViewLaboratory />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_EDIT_LABORATORY}
            element={
              <PageLayout>
                <EditLaboratory />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.MASTER_GRADE_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <MasterGradeListPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_NEW_GRADE}
            element={
              <PageLayout>
                <GradeForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_VIEW_GRADE}
            element={
              <PageLayout>
                <ViewGrade />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_EDIT_GRADE}
            element={
              <PageLayout>
                <EditGrade />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.MASTER_CATEGORY_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <MasterCategoryListPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_NEW_CATEGORY}
            element={
              <PageLayout>
                <CategoryForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_VIEW_CATEGORY}
            element={
              <PageLayout>
                <ViewCategory />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_EDIT_CATEGORY}
            element={
              <PageLayout>
                <EditCategory />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.MASTER_LOCATION_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <MasterLocationListPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_NEW_LOCATION}
            element={
              <PageLayout>
                <LocationForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_VIEW_LOCATION}
            element={
              <PageLayout>
                <ViewLocation />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_EDIT_LOCATION}
            element={
              <PageLayout>
                <EditLocation />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.MASTER_CITY_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <MasterCityListPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_NEW_CITY}
            element={
              <PageLayout>
                <CityForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_VIEW_CITY}
            element={
              <PageLayout>
                <ViewCity />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_EDIT_CITY}
            element={
              <PageLayout>
                <EditCity />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.MASTER_STATE_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <MasterStateListPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_NEW_STATE}
            element={
              <PageLayout>
                <StateForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_VIEW_STATE}
            element={
              <PageLayout>
                <ViewState />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_EDIT_STATE}
            element={
              <PageLayout>
                <EditState />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.MASTER_USER_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <MasterUserListPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_NEW_USER}
            element={
              <PageLayout>
                <UsersForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_VIEW_USER}
            element={
              <PageLayout>
                <ViewUsers />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.MASTER_EDIT_USER}
            element={
              <PageLayout>
                <EditUsers />
              </PageLayout>
            }
          />
        </Route>
      </Route>

      {/* Config entries */}
      <Route path={APP_ROUTES.ROOT_CONFIG}>
        <Route path={APP_ROUTES.CONFIG_COUNTRY_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <ConfigCountryMasterPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_NEW_COUNTRY}
            element={
              <PageLayout>
                <CountryForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_VIEW_COUNTRY}
            element={
              <PageLayout>
                <ViewCountry />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_EDIT_COUNTRY}
            element={
              <PageLayout>
                <EditCountry />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.CONFIG_DESIGNATION_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <ConfigDesignationPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_NEW_DESIGNATION}
            element={
              <PageLayout>
                <DesignationForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_VIEW_DESIGNATION}
            element={
              <PageLayout>
                <ViewDesignation />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_EDIT_DESIGNATION}
            element={
              <PageLayout>
                <EditDesignation />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.CONFIG_CONDITION_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <ConfigConditionPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_NEW_CONDITION}
            element={
              <PageLayout>
                <ConditionForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_VIEW_CONDITION}
            element={
              <PageLayout>
                <ViewCondition />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_EDIT_CONDITION}
            element={
              <PageLayout>
                <EditCondition />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.CONFIG_STANDARD_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <ConfigStandardPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_NEW_STANDARD}
            element={
              <PageLayout>
                <StandardForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_VIEW_STANDARD}
            element={
              <PageLayout>
                <ViewStandard />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_EDIT_STANDARD}
            element={
              <PageLayout>
                <EditStandard />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.CONFIG_COMPANY_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <ConfigCompanyPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_NEW_COMPANY}
            element={
              <PageLayout>
                <CompanyForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_VIEW_COMPANY}
            element={
              <PageLayout>
                <ViewCompany />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_EDIT_COMPANY}
            element={
              <PageLayout>
                <EditCompany />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.CONFIG_INQUIRY_SOURCE_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <ConfigInquirySourcePage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_NEW_INQUIRY_SOURCE}
            element={
              <PageLayout>
                <InquirySourceForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_VIEW_INQUIRY_SOURCE}
            element={
              <PageLayout>
                <ViewInquirySource />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_EDIT_INQUIRY_SOURCE}
            element={
              <PageLayout>
                <EditInquirySource />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.CONFIG_MAKE_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <ConfigMakePage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_NEW_MAKE}
            element={
              <PageLayout>
                <MakeForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_VIEW_MAKE}
            element={
              <PageLayout>
                <ViewMake />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_EDIT_MAKE}
            element={
              <PageLayout>
                <EditMake />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.CONFIG_PROCESS_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <ConfigProcessPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_NEW_PROCESS}
            element={
              <PageLayout>
                <ProcessForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_VIEW_PROCESS}
            element={
              <PageLayout>
                <ViewProcess />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_EDIT_PROCESS}
            element={
              <PageLayout>
                <EditProcess />
              </PageLayout>
            }
          />
        </Route>
        <Route path={APP_ROUTES.CONFIG_TESTING_LIST}>
          <Route
            index
            element={
              <PageLayout>
                <MasterTestingListPage />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_NEW_TESTING}
            element={
              <PageLayout>
                <TestingForm />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_VIEW_TESTING}
            element={
              <PageLayout>
                <ViewTesting />
              </PageLayout>
            }
          />
          <Route
            path={APP_ROUTES.CONFIG_EDIT_TESTING}
            element={
              <PageLayout>
                <EditTesting />
              </PageLayout>
            }
          />
        </Route>
      </Route>

      <Route path="*" element={<PageNotFoundComp />} />
    </Routes>
  );
};
export default Navigator;
