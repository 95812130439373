import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  Tooltip,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Select,
} from '@chakra-ui/react';
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  flexRender,
  getFacetedRowModel,
  getFacetedUniqueValues,
  ColumnSort,
  SortingState,
} from '@tanstack/react-table';

import { pageEntriesList } from 'constants/filter';

import React, { useEffect, useState } from 'react';

import {
  IconArrowsSort,
  IconFilterFilled,
  IconSettingsFilled,
  IconSortAscending,
  IconSortDescending,
} from '@tabler/icons-react';
import Pagination from 'components/common/Pagination';
import Filter from './FilterCell';

interface MasterTableProps {
  columnDefs: any;
  actualData: any;
  tableLoader: boolean;
  intialSort?: ColumnSort;
  disabledColumn: Record<string, boolean>;
}

const MasterTable = ({
  columnDefs,
  actualData,
  tableLoader,
  disabledColumn,
  intialSort,
}: MasterTableProps) => {
  const [data, setData] = useState(() => [...actualData]);
  const [columns] = useState<typeof columnDefs>(() => [...columnDefs]);
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>(disabledColumn);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [sorting, setSorting] = React.useState<SortingState>(intialSort ? [intialSort] : []);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      columnFilters,
      sorting,
    },
    enableFilters: showFilter,
    sortDescFirst: false,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    setData(() => [...actualData]);
  }, [actualData]);

  useEffect(() => {
    if (showFilter === false) {
      table.resetColumnFilters(true);
    }
  }, [showFilter]);

  const handlePageChange = (page: number) => {
    table.setPageIndex(page - 1);
  };

  return (
    <Card variant={'content'}>
      <CardHeader
        mb={4}
        px={2}
        py={2}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Flex align={'center'} gap={2}>
          Show
          <Select
            width={'auto'}
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {pageEntriesList.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>
          Entries
        </Flex>
        <Flex align={'center'} gap={4}>
          <Tooltip label={'Show/Hide Filter'}>
            <IconButton
              aria-label="Filter-Button"
              icon={<IconFilterFilled stroke={1} size={20} />}
              variant="ghost"
              size={'sm'}
              colorScheme="green"
              onClick={() => setShowFilter(!showFilter)}
            />
          </Tooltip>
          <Menu closeOnSelect={false}>
            <Tooltip label={'Show/Hide Column'}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<IconSettingsFilled stroke={1} size={20} />}
                variant="ghost"
                colorScheme="green"
                size={'sm'}
              />
            </Tooltip>
            <MenuList>
              <MenuItem key={'showAllButton-01'}>
                <Checkbox
                  isChecked={table.getIsAllColumnsVisible()}
                  onChange={table.getToggleAllColumnsVisibilityHandler()}
                >
                  <Text fontWeight={'semibold'}> Show All</Text>
                </Checkbox>
              </MenuItem>
              <MenuDivider />
              {table.getAllLeafColumns().map((column, index) => {
                if (column.columnDef?.id !== 'select' && column.columnDef?.id !== 'actionButton') {
                  return (
                    <MenuItem key={`${column.id}-${index}`}>
                      <Checkbox
                        isChecked={column.getIsVisible()}
                        onChange={column.getToggleVisibilityHandler()}
                      >
                        <Text fontWeight={'semibold'}> {column.columnDef.header?.toString()}</Text>
                      </Checkbox>
                    </MenuItem>
                  );
                }
                return <Box key={`${column.id}-${index}`} />;
              })}
            </MenuList>
          </Menu>
        </Flex>
      </CardHeader>
      <CardBody p={0}>
        {tableLoader ? (
          <Skeleton height="150px" />
        ) : (
          <>
            <TableContainer>
              <Table variant={'customStriped'} size={'md'}>
                <Thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Th
                          key={header.id}
                          width={
                            header.column.columnDef.id === 'actionButton' ||
                            header.column.columnDef.id === 'select'
                              ? '50px'
                              : 'auto'
                          }
                        >
                          <Flex align={'center'} justify={'space-between'} gap={2} width={'100%'}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}

                            {header.column.columnDef.id !== 'actionButton' &&
                            header.column.getCanSort() ? (
                              <IconButton
                                aria-label="sort-table"
                                icon={
                                  {
                                    asc: <IconSortAscending stroke={2} size={'16px'} />,
                                    desc: <IconSortDescending stroke={2} size={'16px'} />,
                                  }[header.column.getIsSorted() as string] ?? (
                                    <IconArrowsSort stroke={2} size={'16px'} />
                                  )
                                }
                                size={'xs'}
                                variant={'unstyled'}
                                color={'white'}
                                onClick={header.column.getToggleSortingHandler()}
                              />
                            ) : (
                              <></>
                            )}
                          </Flex>
                          {header.column.getCanFilter() ? (
                            <Box marginTop={4}>
                              <Filter column={header.column} table={table} />
                            </Box>
                          ) : null}
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody>
                  {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <Td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexDirection={{ md: 'row', base: 'column' }}
              mt={5}
              marginX={3}
              gap={4}
            >
              <Text>
                {table.getPaginationRowModel().rows.length === 0
                  ? 'No Entries'
                  : `Showing
                ${
                  table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1
                } - 
                ${
                  table.getState().pagination.pageIndex * table.getState().pagination.pageSize +
                  table.getPaginationRowModel().rows.length
                }
                of ${table.getPrePaginationRowModel().rows.length} entries`}
              </Text>
              <Pagination
                currentPage={table.getState().pagination.pageIndex + 1}
                totalCount={table.getPrePaginationRowModel().rows.length}
                pageSize={table.getState().pagination.pageSize}
                onPageChange={handlePageChange}
                siblingCount={1}
              />
            </Box>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default MasterTable;
