import { AlertStatus, useToast } from '@chakra-ui/react';

type ToastConfigType = {
  title: string;
  description?: string;
  status: AlertStatus;
};

function useCustomToast() {
  const toast = useToast();

  const showToast = ({ title, description = '', status = 'success' }: ToastConfigType) => {
    toast({
      title,
      description,
      status,
      duration: 5000,
      position: 'top-right',
      isClosable: true,
    });
  };

  return showToast;
}

export default useCustomToast;
