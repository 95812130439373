import {
  brokerFilterAPI,
  categoryFilterAPI,
  cityFilterAPI,
  companyMailFilterAPI,
  conditionFilterAPI,
  contactPersonFilterAPI,
  countryFilterAPI,
  cpoMailFilterAPI,
  customerFilterAPI,
  customerMailFilterAPI,
  gradeFilterAPI,
  invConditionFilterAPI,
  invMaterialFilterAPI,
  labReportMailFilterAPI,
  laboratoryContactFilterAPI,
  laboratoryFilterAPI,
  locationFilterAPI,
  makeFilterAPI,
  menuAccessFilterAPI,
  postFilterAPI,
  processFilterAPI,
  purchaserContactFilterAPI,
  purchaserFilterAPI,
  sourceFilterAPI,
  spoMailFilterAPI,
  standardFilterAPI,
  stateFilterAPI,
  testingFilterAPI,
  unitFilterAPI,
  userFilterAPI,
  vendorFilterAPI,
  warehouseFilterAPI,
} from 'services/filter';
import { FromCompanyMailType, MenuAccessType, ToCustomerMailType } from 'types/common.types';
import {
  BrokerFilterType,
  CategoryFilterType,
  CityFilterType,
  CompanyMailFilterType,
  ConditionFilterType,
  ContactPersonFilterType,
  CountryFilterType,
  CustomerFilterType,
  GradeFilterType,
  InvConditionFilterType,
  InvMaterialFilterType,
  LaboratoryFilterType,
  LocationFilterType,
  MakeFilterType,
  MenuAccessFilterType,
  PostFilterType,
  ProcessFilterType,
  ProcessUnderFilterType,
  PurchaserFilterType,
  SourceFilterType,
  StandardFilterType,
  StateFilterType,
  TestingFilterType,
  UnitFilterType,
  UserFilterType,
  VendorFilterType,
  WarehouseFilterType,
} from 'types/filter.types';

export const customerFilterRequestAsync = async (): Promise<CustomerFilterType[]> => {
  const filterData = await customerFilterAPI();

  const formatedData: CustomerFilterType[] = filterData.data.data.map((item: any) => ({
    customerId: item.customerId,
    customerName: item.customerName,
  }));
  return formatedData;
};

export const testingFilterRequestAsync = async (): Promise<TestingFilterType[]> => {
  const filterData = await testingFilterAPI();
  const formatedData: TestingFilterType[] = filterData.data.data.map((item: any) => ({
    testId: item.testingTypeId,
    testName: item.testingTypeName,
  }));
  return formatedData;
};

export const laboratoryFilterRequestAsync = async (): Promise<LaboratoryFilterType[]> => {
  const filterData = await laboratoryFilterAPI();

  const formatedData: LaboratoryFilterType[] = filterData.data.data.map((item: any) => ({
    labId: item.labId,
    labName: item.labName,
  }));
  return formatedData;
};

export const laboratoryContactFilterRequestAsync = async (
  id: number,
): Promise<ContactPersonFilterType[]> => {
  const resp = await laboratoryContactFilterAPI(id);
  const formatedData: ContactPersonFilterType[] = resp.data.data.map((item: any) => ({
    contactId: item.contactId,
    contactName: item.contactName,
  }));
  return formatedData;
};

export const purchaserFilterRequestAsync = async (): Promise<PurchaserFilterType[]> => {
  const filterData = await purchaserFilterAPI();

  const formatedData: PurchaserFilterType[] = filterData.data.data.map((item: any) => ({
    purchaserId: item.purchaserId,
    purchaserName: item.purchaserName,
  }));
  return formatedData;
};

export const purchaserContactFilterRequestAsync = async (
  id: number,
): Promise<ContactPersonFilterType[]> => {
  const resp = await purchaserContactFilterAPI(id);
  const formatedData: ContactPersonFilterType[] = resp.data.data.map((item: any) => ({
    contactId: item.p_contact_id,
    contactName: item.contact_name,
  }));
  return formatedData;
};

export const brokerFilterRequestAsync = async (): Promise<BrokerFilterType[]> => {
  const filterData = await brokerFilterAPI();

  const formatedData: BrokerFilterType[] = filterData.data.data.map((item: any) => ({
    brokerId: item.broker_id,
    brokerName: item.broker_name,
  }));
  return formatedData;
};

export const sourceFilterRequestAsync = async (): Promise<SourceFilterType[]> => {
  const filterData = await sourceFilterAPI();

  const formatedData: SourceFilterType[] = filterData.data.data.map((item: any) => ({
    sourceId: item.id,
    sourceName: item.name,
  }));

  return formatedData;
};

export const gradeFilterRequestAsync = async (): Promise<GradeFilterType[]> => {
  const filterData = await gradeFilterAPI();

  const formatedData: GradeFilterType[] = filterData.data.data.map((item: any) => ({
    gradeId: item.gradeId,
    gradeName: item.grade_name,
  }));

  return formatedData;
};

export const unitFilterRequestAsync = async (): Promise<UnitFilterType[]> => {
  const filterData = await unitFilterAPI();

  const formatedData: UnitFilterType[] = filterData.data.data.map((item: any) => ({
    unitId: item.unitId,
    unitName: item.unitName,
  }));
  return formatedData;
};

export const categoryFilterRequestAsync = async (): Promise<CategoryFilterType[]> => {
  const filterData = await categoryFilterAPI();
  const formatedData: CategoryFilterType[] = filterData.data.data.map((item: any) => ({
    categoryId: item.categoryId,
    categoryName: item.categoryName,
  }));
  return formatedData;
};

export const cityFilterRequestAsync = async (): Promise<CityFilterType[]> => {
  const filterData = await cityFilterAPI();
  const formatedData: CityFilterType[] = filterData.data.data.map((item: any) => ({
    cityId: item.cityId,
    cityName: item.cityName,
  }));
  return formatedData;
};

export const contactPersonFilterRequestAsync = async (
  id: number,
): Promise<ContactPersonFilterType[]> => {
  const resp = await contactPersonFilterAPI(id);
  const formatedData: ContactPersonFilterType[] = resp.data.data.map((item: any) => ({
    contactId: item.contact_id,
    contactName: item.contact_person,
  }));
  return formatedData;
};

export const userFilterRequestAsync = async (): Promise<UserFilterType[]> => {
  const filterData = await userFilterAPI();
  const formatedData: UserFilterType[] = filterData.data.data.map((item: any) => ({
    userId: item.userId,
    userName: item.username,
  }));
  return formatedData;
};

export const makeFilterRequestAsync = async (): Promise<MakeFilterType[]> => {
  const filterData = await makeFilterAPI();
  const formatedData: MakeFilterType[] = filterData.data.data.map((item: any) => ({
    makeId: item.makeId,
    makeName: item.makeName,
  }));
  return formatedData;
};
export const conditionFilterRequestAsync = async (): Promise<ConditionFilterType[]> => {
  const filterData = await conditionFilterAPI();
  const formatedData: ConditionFilterType[] = filterData.data.data.map((item: any) => ({
    conditionId: item.conditionId,
    conditionName: item.conditionName,
  }));
  return formatedData;
};

export const postFilterRequestAsync = async (): Promise<PostFilterType[]> => {
  const filterData = await postFilterAPI();
  const formatedData: PostFilterType[] = filterData.data.data.map((item: any) => ({
    postId: item.id,
    postName: item.name,
  }));
  return formatedData;
};

export const vendorFilterRequestAsync = async (): Promise<VendorFilterType[]> => {
  const filterData = await vendorFilterAPI();
  const formatedData: VendorFilterType[] = filterData.data.data.map((item: any) => ({
    vendorId: item.vendorId,
    vendorName: item.vendorName,
  }));
  return formatedData;
};

export const locationFilterRequestAsync = async (): Promise<LocationFilterType[]> => {
  const filterData = await locationFilterAPI();
  const formatedData: LocationFilterType[] = filterData.data.data.map((item: any) => ({
    locationId: item.locationID,
    locationName: item.locationName,
    locationPrefix: item.locationPrefix,
  }));
  return formatedData;
};

export const processFilterRequestAsync = async (): Promise<ProcessFilterType[]> => {
  const filterData = await processFilterAPI();
  const formatedData: ProcessFilterType[] = filterData.data.data.map((item: any) => ({
    processId: item.processId,
    processName: item.processName,
  }));
  return formatedData;
};

export const stateFilterRequestAsync = async (): Promise<StateFilterType[]> => {
  const filterData = await stateFilterAPI();
  const formatedData: StateFilterType[] = filterData.data.data.map((item: any) => ({
    stateId: item.stateId,
    stateName: item.stateName,
  }));
  return formatedData;
};

export const countryFilterRequestAsync = async (): Promise<CountryFilterType[]> => {
  const filterData = await countryFilterAPI();
  const formatedData: CountryFilterType[] = filterData.data.data.map((item: any) => ({
    countryId: item.countryId,
    countryName: item.countryName,
  }));
  return formatedData;
};

export const standardFilterRequestAsync = async (): Promise<StandardFilterType[]> => {
  const filterData = await standardFilterAPI();
  const formatedData: StandardFilterType[] = filterData.data.data.map((item: any) => ({
    standardId: item.standardID,
    standardName: item.standardName,
  }));
  return formatedData;
};

export const warehouseFilterRequestAsync = async (): Promise<WarehouseFilterType[]> => {
  const filterData = await warehouseFilterAPI();
  const formatedData: WarehouseFilterType[] = filterData.data.data.map((item: any) => ({
    warehouseId: item.warehouse_id,
    warehouseName: item.warehouse_name,
    warehouseCode: item.warehouse_code,
  }));
  return formatedData;
};

export const menuAccessFilterRequestAsync = async (): Promise<MenuAccessFilterType[]> => {
  const filterData = await menuAccessFilterAPI();
  const formatedData: MenuAccessFilterType[] = filterData.data.data.map((item: MenuAccessType) => ({
    menuAccessId: item.id,
    menuAccessName: item.name,
  }));
  return formatedData;
};

export const companyMailFilterRequestAsync = async (): Promise<CompanyMailFilterType[]> => {
  const filterData = await companyMailFilterAPI();
  const formatedData: CompanyMailFilterType[] = filterData.data.data.map(
    (item: FromCompanyMailType): CompanyMailFilterType => ({
      companyId: item.companyId,
      companyEmail: `${item.companyName} - ${item.company_email_ids}`,
    }),
  );
  return formatedData;
};

export const customerMailFilterRequestAsync = async (id: number): Promise<ToCustomerMailType> => {
  const filterData = await customerMailFilterAPI(id);
  const formatedData: ToCustomerMailType = filterData.data.data;
  return formatedData;
};

export const spoMailFilterRequestAsync = async (id: number): Promise<ToCustomerMailType> => {
  const filterData = await spoMailFilterAPI(id);
  const formatedData: ToCustomerMailType = filterData.data.data;
  return formatedData;
};

export const cpoMailFilterRequestAsync = async (id: number): Promise<ToCustomerMailType> => {
  const filterData = await cpoMailFilterAPI(id);
  const formatedData: ToCustomerMailType = filterData.data.data;
  return formatedData;
};

export const labReportMailFilterRequestAsync = async (id: number): Promise<ToCustomerMailType> => {
  const filterData = await labReportMailFilterAPI(id);
  const formatedData: ToCustomerMailType = filterData.data.data;
  return formatedData;
};

export const invMaterialFilterRequestAsync = async (): Promise<InvMaterialFilterType[]> => {
  const filterData = await invMaterialFilterAPI();
  const formatedData: InvMaterialFilterType[] = filterData.data.data.map(
    (item: any): InvMaterialFilterType => ({
      invMaterialId: item.conditionId,
      invMaterialName: item.conditionName,
    }),
  );
  return formatedData;
};

export const invConditionFilterRequestAsync = async (): Promise<InvConditionFilterType[]> => {
  const filterData = await invConditionFilterAPI();
  const formatedData: InvConditionFilterType[] = filterData.data.data.map(
    (item: any): InvConditionFilterType => ({
      invConditionId: item.csCondnId,
      invConditionName: item.csCondn,
    }),
  );
  return formatedData;
};

export const processUnderFilterRequestAsync = async (): Promise<ProcessUnderFilterType[]> => {
  const formatedData: ProcessUnderFilterType[] = [
    {
      puId: 1,
      puName: 'Hardening & Tempering',
    },
    {
      puId: 2,
      puName: 'Testing',
    },
    {
      puId: 3,
      puName: 'Machining',
    },
    {
      puId: 4,
      puName: 'Procurement',
    },
    {
      puId: 5,
      puName: 'No Action',
    },
  ];
  return formatedData;
};
