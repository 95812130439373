import config from 'config/appConfig';
import {
  CreateInInventoryType,
  CreateOutExtInventoryType,
  CreateOutIntInventoryType,
  EditInInventoryType,
  EditOutInventoryType,
} from 'types/inventory.types';
import { URLSearchParams } from 'url';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

// All Inventory APIs (GET)(For Table)
export const getAllCurrentInventoryAPI = (params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/inventory/paginated/?${params.toString()}`;
  return API.get(url);
};

export const getAllInInventoryAPI = (params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/inventory/paginated/in/?${params.toString()}`;
  return API.get(url);
};

export const getAllOutInventoryAPI = (params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/inventory/paginated/out/?${params.toString()}`;
  return API.get(url);
};

// Single Inventory APIs (GET) (View Data)
export const getSingleInInventoryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inventory/in/${id}`;
  return API.get(url);
};

export const getSingleOutInventoryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inventory/out/${id}`;
  return API.get(url);
};

export const getSingleCurrentInventoryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inventory/${id}`;
  return API.get(url);
};

// In Inventory Create and Update API (POST & PUT)
export const createInInventoryAPI = (body: CreateInInventoryType) => {
  const url = `${API_ENDPOINT}/inventory/new`;
  return API.post(url, body);
};

export const updateInInventoryAPI = (id: number, payload: EditInInventoryType) => {
  const url = `${API_ENDPOINT}/inventory/in/${id}`;
  return API.put(url, payload);
};

// Create Out Inventory in Current Inventory API (POST)

export const createOutExtInventoryAPI = (body: CreateOutExtInventoryType) => {
  const url = `${API_ENDPOINT}/inventory/process`;
  return API.post(url, body);
};

export const createOutIntInventoryAPI = (body: CreateOutIntInventoryType) => {
  const url = `${API_ENDPOINT}/inventory/process`;
  return API.post(url, body);
};

// Update Out inventory (PUT)
export const updateOutInventoryAPI = (id: number, payload: EditOutInventoryType) => {
  const url = `${API_ENDPOINT}/inventory/out/${id}`;
  return API.put(url, payload);
};

export const deleteInInventoryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inventory/in/${id}`;
  return API.deleteResource(url);
};

export const deleteCurrentInventoryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inventory/${id}`;
  return API.deleteResource(url);
};

export const deleteOutInventoryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inventory/out/${id}`;
  return API.deleteResource(url);
};
