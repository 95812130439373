import config from 'config/appConfig';
import { CreatePostType, EditPostType } from 'types/post.types';

import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllPostAPI = () => {
  const url = `${API_ENDPOINT}/post`;
  return API.get(url);
};

export const createPostAPI = (body: CreatePostType) => {
  const url = `${API_ENDPOINT}/post`;
  return API.post(url, body);
};

export const getSinglePostAPI = (id: number) => {
  const url = `${API_ENDPOINT}/post/${id}`;
  return API.get(url);
};

export const updatePostAPI = (id: number, payload: EditPostType) => {
  const url = `${API_ENDPOINT}/post/${id}`;
  return API.put(url, payload);
};

export const deletePostAPI = (id: number) => {
  const url = `${API_ENDPOINT}/post/${id}`;
  return API.deleteResource(url);
};
