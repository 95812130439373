import { Box, Image } from '@chakra-ui/react';
import OnlyLogo from 'assets/Logos/Only-Logo-Champion-Steels.png';
import OnlyLogoText from 'assets/Logos/Only-Text-Champion-Steels.png';
import React from 'react';

const LogoText = () => {
  return (
    <Box fontSize="24px" lineHeight="32px" display={'flex'} gap={1} alignItems={'center'}>
      <Image borderRadius="full" boxSize="35px" src={OnlyLogo} alt="Champion Steel Logo" />
      <Image
        height={'25px'}
        width={{ md: '200px', base: '150px' }}
        objectPosition={'center'}
        objectFit={'contain'}
        src={OnlyLogoText}
        alt="Champion Steel Logo"
      />
    </Box>
  );
};

export default LogoText;
