import React, { useEffect, useState } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import Header from './Header';

import { getCookie } from 'utils/common';
import { ACCESS_TOKEN_NAME } from 'config/constant';
import { Navigate } from 'react-router-dom';
import { APP_ROUTES } from 'config/eunm';
import useWindowSize from 'hooks/windowSizeHook';
import { sideBarConfig } from './constant';
import Footer from './Footer';
import { Wrapper } from 'theme/components/Wrappers/custom.Wrapper';
import SideMenu from './SideMenu';

const PageLayout = ({ children }: { children: React.ReactNode }) => {
  const isAuthenticated = !!getCookie(ACCESS_TOKEN_NAME);
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 1000;
  const { isOpen, onToggle, onClose } = useDisclosure();

  if (!isAuthenticated) {
    return <Navigate to={APP_ROUTES.LOGIN} replace={true} />;
  } else {
    return (
      <>
        <Box display={'flex'} flexDirection={'column'} height={'100%'}>
          <Header isSideBarOpen={isOpen} onToggle={onToggle} />
          <SideMenu isOpen={isOpen} onToggle={onToggle} onClose={onClose} />
          <Wrapper
            variant="main"
            width={{
              base: '100%',
              md: isOpen
                ? `calc(100% - ${sideBarConfig.openWidth})`
                : `calc(100% - ${sideBarConfig.closeWidth})`,
            }}
            marginLeft={{
              md: isOpen ? `${sideBarConfig.openWidth}` : `${sideBarConfig.closeWidth}`,
            }}
            paddingX={{ base: '16px', md: '12px' }}
            transition="all 0.5s ease-in-out "
          >
            {children}
            <Footer />
          </Wrapper>
        </Box>
      </>
    );
  }
};

export default PageLayout;
