import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Card, CardBody, CardHeader, Flex, Spacer } from '@chakra-ui/react';
import BreadCrumbLayout from 'components/common/BreadCrumb';
import { PageHeading, SectionHeading } from 'components/common/Headings';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import useCustomToast from 'hooks/toast';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'config/eunm';
import {
  CreateLaboratoryContactType,
  CreateLaboratoryType,
  LaboratoryHookFormType,
} from 'types/laboratory.types';
import { yupResolver } from '@hookform/resolvers/yup';
import Contact from './Contact';
import BasicDetails from './BasicDetails';
import { createLaboratoryRequestAsync } from '../controller';
import { MenuAccessItem, createAccessCheck } from 'helpers/accessCheck.helper';
import { UserDetails } from 'App';
import AccessDeniedPage from 'components/AccessDeniedPage';
import LaboratoryValidation from 'validations/laboratory.validation';

const LaboratoryForm = () => {
  const showToast = useCustomToast();
  const navigate = useNavigate();
  const [createAccessDenied, setCreateAccessDenied] = useState<boolean>(false);

  const { user, setUser } = useContext(UserDetails);

  useEffect(() => {
    const access = createAccessCheck('Master', user?.menuAccess as MenuAccessItem[]);
    if (!access) {
      setCreateAccessDenied(true);
    }
    if (access) {
      setCreateAccessDenied(false);
    }
  }, [user]);

  const defaultValues: LaboratoryHookFormType = {
    laboratoryName: '',
    mobile: '',
    email: '',
    officeAddress1: '',
    officeAddress2: '',
    officeAddress3: '',
    officeCity: null,
    officePin: '',
    contact: [
      {
        personName: '',
        birthday: '',
        email: '',
        mobile: '',
      },
    ],
  };

  const methods = useForm<LaboratoryHookFormType>({
    defaultValues: defaultValues,
    resolver: yupResolver(LaboratoryValidation),
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<LaboratoryHookFormType> = async (data) => {
    console.log('Submit Data', data);
    const contactPayload: CreateLaboratoryContactType[] = data.contact.map((singleContact) => {
      const formatedContact: CreateLaboratoryContactType = {
        contactName: singleContact.personName,
        dob: singleContact.birthday?.toString(),
        email: singleContact.email,
        mobile: singleContact.mobile,
      };
      return formatedContact;
    });
    const payload: CreateLaboratoryType = {
      labortoryName: data.laboratoryName,
      email: data.email,
      office_address1: data.officeAddress1 || undefined,
      office_address2: data.officeAddress2 || undefined,
      office_address3: data.officeAddress3 || undefined,
      office_city: data.officeCity!.cityId,
      office_pin: data.officePin || undefined,
      mobile: data.mobile,
      contacts: contactPayload,
    };
    try {
      await createLaboratoryRequestAsync(payload);
      navigate(-1);
      showToast({
        title: 'Success  ',
        description: 'Laboratory Successfully Created ',
        status: 'success',
      });
    } catch (error: any) {
      showToast({
        title: error.message,
        description: 'Something went wrong!!',
        status: 'error',
      });
    }
  };

  React.useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      methods.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState.isSubmitSuccessful]);

  const handleCancel = () => {
    methods.reset();
    navigate(-1);
  };

  return (
    <Box width={'100%'} display={'flex'} gap={5} flexDirection={'column'}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Flex direction={'column'} gap={5}>
            <Box width={'100%'}>
              <Card
                variant={'headerBg'}
                display={'flex'}
                flexDirection={{ base: 'column', md: 'row' }}
                gap={5}
                alignItems={{ md: 'center', base: 'start' }}
              >
                <PageHeading props={{ pb: 2 }}>Create New Laboratory</PageHeading>
                <Spacer />
                <BreadCrumbLayout
                  options={[
                    { name: 'Dashboard', link: `${APP_ROUTES.HOME_PAGE}` },
                    { name: 'Master' },
                    {
                      name: 'Laboratory',
                      link: `${APP_ROUTES.ROOT_MASTER}/${APP_ROUTES.MASTER_LABORATORY_LIST}`,
                    },
                    { name: 'Create New Laboratory ', isActivePage: true },
                  ]}
                />
              </Card>
            </Box>
            {createAccessDenied ? (
              <AccessDeniedPage />
            ) : (
              <>
                <BasicDetails />
                <Box width={'100%'}>
                  <Card
                    variant={'content'}
                    borderTop={'4px solid'}
                    borderColor={'componentBorderColor.contact'}
                  >
                    <CardHeader px={6} pt={2} pb={0} display={'flex'} alignItems={'center'}>
                      <SectionHeading props={{ fontWeight: 'semibold', fontSize: '2xl' }}>
                        Contacts
                      </SectionHeading>
                    </CardHeader>
                    <CardBody>
                      <Contact />
                    </CardBody>
                  </Card>
                </Box>
                <Flex alignItems={{ md: 'end', base: 'start' }} direction={'column'}>
                  <Flex gap={3} justify={'space-between'} w={{ base: '100%', md: 'auto' }}>
                    <Button
                      variant={'primary'}
                      colorScheme="create"
                      type="submit"
                      isDisabled={!methods.formState.isDirty}
                    >
                      Create New Laboratory
                    </Button>
                    <Button variant="secondary" colorScheme="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Flex>
                </Flex>
              </>
            )}
          </Flex>
        </form>
      </FormProvider>
    </Box>
  );
};
export default LaboratoryForm;
