import config from 'config/appConfig';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const customerFilterAPI = () => {
  const url = `${API_ENDPOINT}/customer/forsearch`;
  return API.get(url);
};

export const laboratoryFilterAPI = () => {
  const url = `${API_ENDPOINT}/laboratory/forsearch`;
  return API.get(url);
};

export const laboratoryContactFilterAPI = (id: number) => {
  const url = `${API_ENDPOINT}/laboratory/${id}/contacts`;
  return API.get(url);
};

export const purchaserFilterAPI = () => {
  const url = `${API_ENDPOINT}/purchaser/forsearch`;
  return API.get(url);
};
export const purchaserContactFilterAPI = (id: number) => {
  const url = `${API_ENDPOINT}/purchaser/${id}/contacts`;
  return API.get(url);
};

export const brokerFilterAPI = () => {
  const url = `${API_ENDPOINT}/broker/forsearch`;
  return API.get(url);
};

export const vendorFilterAPI = () => {
  const url = `${API_ENDPOINT}/vendor`;
  return API.get(url);
};

export const sourceFilterAPI = () => {
  const url = `${API_ENDPOINT}/inquirypage`;
  return API.get(url);
};

export const gradeFilterAPI = () => {
  const url = `${API_ENDPOINT}/grade`;
  return API.get(url);
};

export const unitFilterAPI = () => {
  const url = `${API_ENDPOINT}/unit`;
  return API.get(url);
};

export const categoryFilterAPI = () => {
  const url = `${API_ENDPOINT}/category`;
  return API.get(url);
};

export const cityFilterAPI = () => {
  const url = `${API_ENDPOINT}/city`;
  return API.get(url);
};

export const contactPersonFilterAPI = (id: number) => {
  const url = `${API_ENDPOINT}/customer/${id}/contacts`;
  return API.get(url);
};

export const userFilterAPI = () => {
  const url = `${API_ENDPOINT}/user`;
  return API.get(url);
};

export const makeFilterAPI = () => {
  const url = `${API_ENDPOINT}/make`;
  return API.get(url);
};

export const conditionFilterAPI = () => {
  const url = `${API_ENDPOINT}/condition`;
  return API.get(url);
};

export const postFilterAPI = () => {
  const url = `${API_ENDPOINT}/post`;
  return API.get(url);
};

export const locationFilterAPI = () => {
  const url = `${API_ENDPOINT}/location/restricted`;
  return API.get(url);
};

export const processFilterAPI = () => {
  const url = `${API_ENDPOINT}/process`;
  return API.get(url);
};

export const stateFilterAPI = () => {
  const url = `${API_ENDPOINT}/state`;
  return API.get(url);
};

export const countryFilterAPI = () => {
  const url = `${API_ENDPOINT}/country`;
  return API.get(url);
};

export const standardFilterAPI = () => {
  const url = `${API_ENDPOINT}/standard`;
  return API.get(url);
};

export const warehouseFilterAPI = () => {
  const url = `${API_ENDPOINT}/warehouse`;
  return API.get(url);
};

export const menuAccessFilterAPI = () => {
  const url = `${API_ENDPOINT}/menu`;
  return API.get(url);
};

export const companyMailFilterAPI = () => {
  const url = `${API_ENDPOINT}/company/`;
  return API.get(url);
};

export const customerMailFilterAPI = (id: number) => {
  const url = `${API_ENDPOINT}/inquiryquotation/${id}/custdetails`;
  return API.get(url);
};

export const spoMailFilterAPI = (id: number) => {
  const url = `${API_ENDPOINT}/purchaseorder/spo/${id}/pdetails`;
  return API.get(url);
};

export const cpoMailFilterAPI = (id: number) => {
  const url = `${API_ENDPOINT}/purchaseorder/cpo/${id}/custdetails`;
  return API.get(url);
};

export const labReportMailFilterAPI = (id: number) => {
  const url = `${API_ENDPOINT}/labreport/${id}/custdetails`;
  return API.get(url);
};

export const invMaterialFilterAPI = () => {
  const url = `${API_ENDPOINT}/condition`;
  return API.get(url);
};

export const invConditionFilterAPI = () => {
  const url = `${API_ENDPOINT}/csCondition`;
  return API.get(url);
};
export const testingFilterAPI = () => {
  const url = `${API_ENDPOINT}/testingtype`;
  return API.get(url);
};
