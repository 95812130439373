import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import BgImg from 'assets/bg.jpg';

const mainVariant = defineStyle({
  height: '100%',
  marginTop: '70px',
  flexDirection: 'column',
  gap: 4,
  display: 'flex',
});

const headerVariant = defineStyle({
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  padding: '2',
  top: 0,
  zIndex: '100',
  height: '60px',
  width: '100%',
  backgroundColor: 'background.secondary',
});

const sideBarVariant = defineStyle({
  display: 'flex',
  backgroundColor: 'background.sidebar',
  minHeight: '100%',
  paddingTop: '70px',
  paddingBottom: 4,
  flexDirection: 'column',
  gap: 8,
});

const sideMenuItemsVariant = defineStyle({
  paddingX: 2,
});

const sideBarTextVariant = defineStyle({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  borderRadius: '10px',
  w: '100%',
  alignItems: 'center',
  gap: 2,
  color: 'white',
  '@media (hover: hover)': {
    '&:hover': {
      backgroundColor: 'gray.700',
    },
  },
  px: 1,
  py: 2,
});

const sideBarTextActiveVariant = defineStyle({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  w: '100%',
  borderRadius: '10px',
  alignItems: 'center',
  fontWeight: 'bold',
  opacity: 1,
  color: 'white',
  bgColor: 'gray.600',
  gap: 2,
  px: 1,
  py: 2,
});

const pageLoaderVariant = defineStyle({
  height: '100vh',
  width: '100vw',
  display: 'grid',
  border: '1px solid black',
});

const loginFormVariant = defineStyle({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundImage: `${BgImg} `,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
});

export const wrapperTheme = defineStyleConfig({
  variants: {
    pageLoader: pageLoaderVariant,
    login: loginFormVariant,
    main: mainVariant,
    header: headerVariant,
    sideBar: sideBarVariant,
    sideMenuItem: sideMenuItemsVariant,
    sidebarTextActive: sideBarTextActiveVariant,
    sidebarText: sideBarTextVariant,
  },
});
