import * as API from 'utils/api-helper';
import config from 'config/appConfig';
import { CreateUserType, EditUserType } from 'types/user.types';
const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getSingleUserAPI = (id: number) => {
  const url = `${API_ENDPOINT}/user/${id}`;
  return API.get(url);
};

export const getAllUserAPI = () => {
  const url = `${API_ENDPOINT}/user`;
  return API.get(url);
};

export const createUserAPI = (body: CreateUserType) => {
  const url = `${API_ENDPOINT}/user`;
  return API.post(url, body);
};

export const updateUserAPI = (id: number, payload: EditUserType) => {
  const url = `${API_ENDPOINT}/user/${id}`;
  return API.put(url, payload);
};

export const deleteUserAPI = (id: number) => {
  const url = `${API_ENDPOINT}/user/${id}`;
  return API.deleteResource(url);
};
