import { Box, Heading, Stack, StackDivider, Tag, Text } from '@chakra-ui/react';
import React from 'react';
import { getCustomerTypeColorCode } from 'helpers/colorCode.helper';
import { CustomerBasicDetailsType } from 'types/customer.types';
import { BrokerBasicDetailsType } from 'types/broker.types';
import { FromClientType } from 'types/common.types';

type BriefClientDetailsProps = {
  customerDetails?: CustomerBasicDetailsType;
  brokerDetails?: BrokerBasicDetailsType;
  clientType: FromClientType;
};

const BriefClientDetails = ({
  customerDetails,
  brokerDetails,
  clientType,
}: BriefClientDetailsProps) => {
  return (
    <Stack divider={<StackDivider />} spacing="4" width={'100%'}>
      <Box>
        <Heading size="xs" textTransform="uppercase">
          {clientType} Name :
        </Heading>
        <Text fontSize="lg" pt="2">
          {clientType === 'Customer' ? customerDetails?.customerName : brokerDetails?.brokerName}
        </Text>
      </Box>
      <Box>
        <Heading size="xs" textTransform="uppercase" mb={2}>
          {clientType} Type :
        </Heading>
        <Tag
          size={'lg'}
          color={'text.secondary'}
          backgroundColor={getCustomerTypeColorCode(
            (customerDetails || brokerDetails) && clientType === 'Customer'
              ? customerDetails?.customerType.typeName || 'New'
              : brokerDetails?.brokerType.typeName || 'New',
          )}
        >
          {clientType === 'Customer'
            ? customerDetails?.customerType.typeName
            : brokerDetails?.brokerType.typeName}
        </Tag>
      </Box>
      <Box>
        <Heading size="xs" textTransform="uppercase">
          Location/Branch
        </Heading>
        <Text pt="2" fontSize="sm">
          {clientType === 'Customer'
            ? customerDetails?.customerNamewithCity
              ? customerDetails.customerNamewithCity
              : '-'
            : brokerDetails?.OfficeCity
            ? brokerDetails.OfficeCity
            : '-'}
        </Text>
      </Box>
      <Box>
        <Heading size="xs" textTransform="uppercase">
          Contact:
        </Heading>
        <Text pt="2" fontSize="sm">
          {clientType === 'Customer'
            ? customerDetails?.mobile
              ? customerDetails.mobile
              : '-'
            : brokerDetails?.mobile
            ? brokerDetails.mobile
            : '-'}
        </Text>
      </Box>
    </Stack>
  );
};

export default BriefClientDetails;
