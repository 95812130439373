import axios, { AxiosError, AxiosResponse } from 'axios';
import { APP_ROUTES } from 'config/eunm';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteAllCookies } from 'utils/common';

const AxiosInterceptor = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const resInterceptor = (response: AxiosResponse) => {
      return response;
    };

    const errInterceptor = (error: AxiosError) => {
      if (error.response!.status === 401) {
        navigate(APP_ROUTES.LOGIN, { replace: true });
        navigate(0);
        deleteAllCookies();
      }

      return Promise.reject(error);
    };

    axios.interceptors.response.use(resInterceptor, errInterceptor);
  }, [navigate]);
  return children;
};

export default AxiosInterceptor;
