import React from 'react';
import { ActionMeta, ChakraStylesConfig, Select } from 'chakra-react-select';
import { SizeProp } from 'chakra-react-select/dist/types/types';
import { useTheme } from '@chakra-ui/react';
import { ControllerRenderProps } from 'react-hook-form';

type Option = unknown;
type OnChange = (option: Option | readonly Option[] | null, actionMeta: ActionMeta<Option>) => void;
interface ReactSelectProp {
  isMulti: boolean;
  isSearchable: boolean;
  isClearable?: boolean;
  options: any;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
  menuWidth?: string;
  menuListHeigth?: string;
  placeholder: string;
  size?: SizeProp;
  variant?: string;
  value?: Option | Option[];
  onChange?: OnChange;
  defaultValue?: Option;
  fields?: ControllerRenderProps<any, any>;
  showCrossIcon?: boolean;
}

export const ReactSelect = ({
  isMulti,
  options,
  getOptionLabel,
  getOptionValue,
  isSearchable,
  isClearable,
  menuWidth,
  menuListHeigth,
  value,
  placeholder,
  size,
  onChange,
  variant = 'outline',
  fields,
  showCrossIcon,
}: ReactSelectProp) => {
  const theme = useTheme();
  const chakraAutoCompleteSelectStyles: ChakraStylesConfig = {
    placeholder: (provided, state) => ({
      ...provided,
      textColor: `${theme.colors.text.placeholder}`,
      fontSize: 'sm',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      margin: '1px',
      width: '20px',
      height: '20px',
      display: showCrossIcon === true ? 'flex' : 'none',
      alignContent: 'center',
    }),
    crossIcon: (provided, state) => ({
      ...provided,
      fontSize: '8px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: '8px',
      paddingRight: '2px',
      fontSize: '14px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      whiteSpace: 'nowrap',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      width: '25px',
      margin: '4px',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      fontSize: '16px',
      margin: '4px',
    }),
    menu: (provided, state) => ({
      ...provided,
      w: `${menuWidth ? menuWidth : '100%'}`,
    }),

    menuList: (provided, state) => ({
      ...provided,
      h: `${menuListHeigth ? menuListHeigth : '100%'}`,
    }),
  };

  /*  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option: any) => field.value.indexOf(option.value) >= 0)
        : options.find((option: any) => option.value === field.value);
    } else {
      return isMulti ? [] : ('' as any);
    }
  }; */
  return (
    <Select
      isMulti={isMulti}
      isSearchable={isSearchable}
      isClearable={isClearable !== undefined ? isClearable : isSearchable}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      useBasicStyles
      chakraStyles={chakraAutoCompleteSelectStyles}
      value={fields ? fields.value : value}
      placeholder={placeholder}
      size={size}
      onChange={fields ? fields.onChange : onChange}
      variant={variant}
      ref={fields?.ref}
      menuShouldScrollIntoView={false}
    />
  );
};
