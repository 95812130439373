const colors = {
  background: {
    primary: '#f4f7fe',
    secondary: 'white',
    sidebar: '#A0AEC0',
  },
  mainColors: {
    primary: '#4682b4', // #319795
    secondary: '#b47846',
  },
  text: {
    primary: 'black',
    secondary: 'white',
    currentbreadcrumb: 'blue',
    placeholder: '#CBD5E0',
  },
  severityColor: {
    high: '#dc0000',
    medium: '#fd8c00',
    low: '#fdc500',
  },
  buttonColor: {
    primary: { main: '#488ac7', hover: '#3768a3' },
    secondary: { main: '#DC0000', hover: 'red' },
    create: { main: '#009C00', hover: '#006D00' },
  },

  dashboardColor: {
    inquiry: '#00c0ef',
    quotation: '#00a65a',
    customer: '#dd4b39',
    transaction: '#f39c12',
    birthday: '#f39c12',
    cuttingforging: '#dd4b39',
  },

  customertypeColor: {
    new: 'blue',
    silver: '#aaa9ad',
    gold: '#c8b273',
    platinum: 'red',
    againstpi: 'green',
  },

  statusColor: {
    active: 'green',
    inactive: 'red',
  },

  componentBorderColor: {
    basic: 'blue',
    specification: 'red',
    payment: 'yellow',
    transport: 'orange',
    other: 'green',
    address: 'purple',
    contact: 'pink',
    record: '#38B2AC',
  },
};
export default colors;
