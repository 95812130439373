import { Box, Collapse, Flex, Icon, Image, Link, Spacer, Text, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { sideBarConfig } from '../constant';
import SideMenuList from './SideMenuList';
import { IconBrandAirbnb } from '@tabler/icons-react';
import { Wrapper } from 'theme/components/Wrappers/custom.Wrapper';
import MatrysysOnlyLogo from 'assets/Logos/Matrysys/Only-Logo-Matrysys.png';
import MatrysysLogo from 'assets/Logos/Matrysys/Matrysys-Logo.png';

const DesktopMenu = ({ isOpen }: { isOpen: boolean }) => {
  const [showShadow, setShowShadow] = useState(false);
  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 10 ? setShowShadow(true) : setShowShadow(false);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [showShadow]);
  return (
    <Box
      maxWidth={isOpen ? sideBarConfig.openWidth : sideBarConfig.closeWidth}
      transition="all 0.5s ease-in-out "
      overflowX="hidden"
      position="fixed"
      top="0px"
      left="0px"
      bottom="0px"
      flexShrink={0}
      overflowY={'scroll'}
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      boxShadow={showShadow ? `rgba(0, 0, 0, 0.45) 15px 0px 10px -18px` : 'none'}
    >
      {isOpen ? (
        <Wrapper variant="sideBar" width={sideBarConfig.openWidth}>
          <SideMenuList isOpen={isOpen} />
          <Spacer />
          <Flex width="100%" alignItems={'center'} gap={2} justifyContent={'center'}>
            <Text fontStyle={'italic'} fontWeight={'semibold'}>
              Built by
            </Text>
            <Link href="https://matrysys.com/" isExternal>
              <Image
                height={'45px'}
                width={'90px'}
                objectPosition={'center'}
                objectFit={'fill'}
                src={MatrysysLogo}
                alt="Champion Steel Logo"
              />
            </Link>
          </Flex>
        </Wrapper>
      ) : (
        <Wrapper
          variant="sideBar"
          width={sideBarConfig.closeWidth}
          transition="all 0.5s ease-in-out "
        >
          <SideMenuList isOpen={isOpen} />
          <Spacer />
          <Flex width="100%" alignItems={'center'} justifyContent={'center'}>
            <Tooltip label="Built By MATRYSYS Solutions">
              <Link href="https://matrysys.com/" isExternal>
                <Image
                  height={'30px'}
                  objectPosition={'center'}
                  objectFit={'contain'}
                  src={MatrysysOnlyLogo}
                  alt="Champion Steel Logo"
                />
              </Link>
            </Tooltip>
          </Flex>
        </Wrapper>
      )}
    </Box>
  );
};

export default DesktopMenu;
