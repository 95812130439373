import * as API from 'utils/api-helper';
import config from 'config/appConfig';
import { CreateCPOType, EditCPOType } from 'types/CPO.types';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllCustomerPurchaseOrderAPI = (params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/purchaseorder/cpopaginated?${params.toString()}`;
  return API.get(url);
};

export const getSingleCustomerPurchaseOrderAPI = (id: number) => {
  const url = `${API_ENDPOINT}/purchaseorder/cpo/${id}`;
  return API.get(url);
};

export const createSingleCustomerPurchaseOrderAPI = (body: CreateCPOType) => {
  const url = `${API_ENDPOINT}/purchaseorder/cpo`;
  return API.post(url, body);
};

export const updateCustomerPurchaseOrderAPI = (body: EditCPOType, id: number) => {
  const url = `${API_ENDPOINT}/purchaseorder/cpo/${id}`;
  return API.put(url, body);
};

export const deleteCustomerPurchaseOrderAPI = (id: number) => {
  const url = `${API_ENDPOINT}/purchaseorder/cpo/${id}`;
  return API.deleteResource(url);
};

export const updateCustomerPurchaseOrderStatusAPI = (id: number, params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/purchaseorder/cpo/${id}/updatestatus?${params.toString()}`;
  return API.put(url);
};

export const setCustomerPurchaseOrderRevisedAPI = (id: number) => {
  const url = `${API_ENDPOINT}/purchaseorder/cpo/setrevised/${id}`;
  return API.put(url);
};

export const downloadCustomerPurchaseOrderAPI = (cId: number, cpoId: number) => {
  const url = `${API_ENDPOINT}/purchaseorder/cpo/pdf?companyId=${cId}&cpoId=${cpoId}`;
  const apiConfig = {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    transformResponse: [
      function (data: any) {
        // Do whatever you want to transform the data

        return data;
      },
    ],
  };
  return API.get(url, undefined, apiConfig);
};
