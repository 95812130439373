import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  options: {
    name: string;
    link?: string;
    isActivePage?: boolean;
  }[];
}

const BreadCrumbLayout = ({ options }: IProps) => {
  // no options for breadcrumb provided
  if (!options.length) {
    return <></>;
  }
  return (
    <Breadcrumb fontWeight="light" fontSize="xs">
      {options.map((i, idx) =>
        i.link ? (
          <BreadcrumbItem key={'breadcrumb-' + i.name + idx} width={'fit-content'}>
            <BreadcrumbLink as={Link} to={i.link}>
              {i.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ) : i.isActivePage ? (
          <BreadcrumbItem
            key={'breadcrumb-' + i.name + idx}
            isCurrentPage
            sx={{ textColor: 'text.currentbreadcrumb' }}
          >
            <BreadcrumbLink>{i.name}</BreadcrumbLink>
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem key={'breadcrumb-' + i.name + idx} width={'fit-content'}>
            <Text>{i.name}</Text>
          </BreadcrumbItem>
        ),
      )}
    </Breadcrumb>
  );
};

export default BreadCrumbLayout;
