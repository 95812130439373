import config from 'config/appConfig';
import { CreateStandardType, EditStandardType } from 'types/standard.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllStandardAPI = () => {
  const url = `${API_ENDPOINT}/standard`;
  return API.get(url);
};

export const createStandardAPI = (body: CreateStandardType) => {
  const url = `${API_ENDPOINT}/standard`;
  return API.post(url, body);
};

export const getSingleStandardAPI = (id: number) => {
  const url = `${API_ENDPOINT}/standard/${id}`;
  return API.get(url);
};

export const updateStandardAPI = (id: number, payload: EditStandardType) => {
  const url = `${API_ENDPOINT}/standard/${id}`;
  return API.put(url, payload);
};

export const deleteStandardAPI = (id: number) => {
  const url = `${API_ENDPOINT}/standard/${id}`;
  return API.deleteResource(url);
};
