import config from 'config/appConfig';
import { CreateCustomerType, EditCustomerType } from 'types/customer.types';

import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllCustomerAPI = (params: URLSearchParams) => {
  const url = `${API_ENDPOINT}/customer/paginated?${params.toString()}`;
  return API.get(url);
};

export const getSingleCustomerAPI = (id: number) => {
  const url = `${API_ENDPOINT}/customer/${id}`;
  return API.get(url);
};

export const createCustomerAPI = (body: CreateCustomerType) => {
  const url = `${API_ENDPOINT}/customer`;
  return API.post(url, body);
};

export const updateCustomerAPI = (id: number, payload: EditCustomerType) => {
  const url = `${API_ENDPOINT}/customer/${id}`;
  return API.put(url, payload);
};

export const deleteCustomerAPI = (id: number) => {
  const url = `${API_ENDPOINT}/customer/${id}`;
  return API.deleteResource(url);
};
