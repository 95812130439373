import config from 'config/appConfig';
import { CreateLocationType, EditLocationType } from 'types/location.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllLocationAPI = () => {
  const url = `${API_ENDPOINT}/location`;
  return API.get(url);
};

export const createLocationAPI = (body: CreateLocationType) => {
  const url = `${API_ENDPOINT}/location`;
  return API.post(url, body);
};

export const getSingleLocationAPI = (id: number) => {
  const url = `${API_ENDPOINT}/location/${id}`;
  return API.get(url);
};

export const updateLocationAPI = (id: number, payload: EditLocationType) => {
  const url = `${API_ENDPOINT}/location/${id}`;
  return API.put(url, payload);
};

export const deleteLocationAPI = (id: number) => {
  const url = `${API_ENDPOINT}/location/${id}`;
  return API.deleteResource(url);
};
