import { Box, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';
import { sideBarConfig } from '../constant';

const SideMenu = ({
  isOpen,
  onToggle,
  onClose,
}: {
  isOpen: boolean;
  onToggle: () => void;
  onClose: () => void;
}) => {
  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    { ssr: false },
  );
  return (
    <Box flexShrink={{ md: 0 }} width={{ base: 'auto', md: sideBarConfig.openWidth }}>
      {isMobile ? (
        <MobileMenu isOpen={isOpen} onClose={onClose} onToggle={onToggle} />
      ) : (
        <DesktopMenu isOpen={isOpen} />
      )}
    </Box>
  );
};

export default SideMenu;
