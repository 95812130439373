import { FormatDate } from 'helpers/date.helper';
import {
  createSingleCustomerPurchaseOrderAPI,
  deleteCustomerPurchaseOrderAPI,
  downloadCustomerPurchaseOrderAPI,
  getAllCustomerPurchaseOrderAPI,
  getSingleCustomerPurchaseOrderAPI,
  setCustomerPurchaseOrderRevisedAPI,
  updateCustomerPurchaseOrderAPI,
  updateCustomerPurchaseOrderStatusAPI,
} from 'services/CPO';
import { CreateCPOType, EditCPOType, SingleCPOType } from 'types/CPO.types';
import { PaginatedResponseType } from 'types/common.types';
import { saveAs } from 'file-saver';

export const getAllCPORequestAsync = async (
  params: URLSearchParams,
): Promise<PaginatedResponseType> => {
  const resp = await getAllCustomerPurchaseOrderAPI(params);
  return resp.data;
};

export const getSingleCPORequestAsync = async (id: number): Promise<SingleCPOType> => {
  const resp = await getSingleCustomerPurchaseOrderAPI(id);
  return resp.data;
};

export const createCPORequestAsync = async (payload: CreateCPOType) => {
  const resp = await createSingleCustomerPurchaseOrderAPI(payload);
  return resp;
};

export const updateCPORequestAsync = async (payload: EditCPOType, id: number) => {
  const resp = await updateCustomerPurchaseOrderAPI(payload, id);
  return resp;
};

export const deleteSingleCPORequestAsync = async (id: number) => {
  const resp = await deleteCustomerPurchaseOrderAPI(id);
  return resp;
};

export const updateCPOStatusRequestAsync = async (id: number, params: URLSearchParams) => {
  const resp = await updateCustomerPurchaseOrderStatusAPI(id, params);
  return resp;
};

export const setCPORevisedRequestAsync = async (id: number) => {
  const resp = await setCustomerPurchaseOrderRevisedAPI(id);
  return resp;
};

export const downloadCPORequestAsync = async ({
  cId,
  cpoId,
  isRevised,
  customerName,
}: {
  cId: number;
  cpoId: number;
  isRevised: boolean;
  customerName?: string;
}) => {
  const resp = await downloadCustomerPurchaseOrderAPI(cId, cpoId);
  const file = new Blob([resp.data], { type: 'application/pdf' });
  saveAs(
    file,
    `${
      isRevised === true ? 'Rev -' : ''
    }Customer_Purchaser_Order(CS_SPO-${cpoId})_${customerName}_${FormatDate(new Date())}.pdf`,
  );
};
