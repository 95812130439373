import config from 'config/appConfig';
import { CreateLaboratoryType, EditLaboratoryType } from 'types/laboratory.types';
import * as API from 'utils/api-helper';

const { isProd } = config;
const API_ENDPOINT = isProd ? config.production.api_endpoint : config.development.api_endpoint;

export const getAllLaboratoryAPI = () => {
  const url = `${API_ENDPOINT}/laboratory/`;
  return API.get(url);
};

export const getSingleLaboratoryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/laboratory/${id}`;
  return API.get(url);
};

export const createLaboratoryAPI = (body: CreateLaboratoryType) => {
  const url = `${API_ENDPOINT}/laboratory`;
  return API.post(url, body);
};

export const updateLaboratoryAPI = (id: number, payload: EditLaboratoryType) => {
  const url = `${API_ENDPOINT}/laboratory/${id}`;
  return API.put(url, payload);
};

export const deleteLaboratoryAPI = (id: number) => {
  const url = `${API_ENDPOINT}/laboratory/${id}`;
  return API.deleteResource(url);
};
